import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { FormGroup, Form as FormStrap, Button, Card, Spinner } from 'react-bootstrap';

import * as Yup from 'yup';

import { useCleanup } from 'hooks';
import { getTokenFromUrl, toastConfig } from 'utils/index';
import { OnResetPassSubmit } from '../types';
import HomeHeader from 'pages/home/HomeHeader';
import renderErrorsElement from 'components/Errors/renderErrorElements';
import { selectError, selectIsLoading, selectSuccess, resetToInitials } from './slice';
import { resetPasswordStart } from './actions';
import { toast, ToastContainer } from 'react-toastify';

export { onResetPasswordStart } from './saga';
export { default as resetPasswordReducer } from './slice';

export interface IResetPasswordProps {}

// FORM INPUT VALIDATION
const validationSchema = Yup.object({
  password: Yup.string()
    .min(6, 'Password has to be longer than 6 characters!')
    .required('Password is required!'),
  password_confirmation: Yup.string().oneOf(
    [Yup.ref('password'), null],
    'Passwords must match',
  ),
});

// eslint-disable-next-line no-unused-vars
export default function ResetPassword(props: IResetPasswordProps) {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectIsLoading);
  const success = useSelector(selectSuccess);
  const error = useSelector(selectError);
  const location = useLocation();
  const reset_password_token = getTokenFromUrl(location);

  const onSubmit: OnResetPassSubmit = (values, { resetForm }) => {
    resetForm();
    dispatch(resetPasswordStart({ ...values, reset_password_token }));
  };

  useCleanup(resetToInitials, error.message, success);

  if (isLoading) return <Spinner animation="border" />;
  if (success) return <div>success</div>;
  if (error.message) {
    toast.error(error.message, toastConfig);
  }
  return (
    <div className="home">
      <HomeHeader />
      <div className="hero">
        <Card className="hero__content auth">
          <Card.Header>Reset Password</Card.Header>
          <Card.Body>
            <Formik
              initialValues={{
                password: '',
                password_confirmation: '',
              }}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({ isSubmitting }) => (
                <Form>
                  <FormGroup>
                    <FormStrap.Label htmlFor="password">
                      Enter New Password
                    </FormStrap.Label>
                    <Field
                      placeholder="Type your new password"
                      type="password"
                      name="password"
                      as={FormStrap.Control}
                    />
                    <ErrorMessage
                      className="auth__error"
                      name="password"
                      component="div"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormStrap.Label htmlFor="password_confirmation">
                      Confirm New Password
                    </FormStrap.Label>
                    <Field
                      placeholder="Re-type your password"
                      type="password"
                      name="password_confirmation"
                      as={FormStrap.Control}
                    />
                    <ErrorMessage
                      className="auth__error"
                      name="password_confirmation"
                      component="div"
                    />
                  </FormGroup>
                  <Button className="auth__btn" type="submit" disabled={isSubmitting}>
                    Reset
                  </Button>
                  {renderErrorsElement(error.message)}
                </Form>
              )}
            </Formik>
          </Card.Body>
        </Card>
        {error.message && <ToastContainer />}
      </div>
    </div>
  );
}
