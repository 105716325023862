import { DepartmentInfo } from '../types';
import { takeLatest, call, put } from 'redux-saga/effects';

import { setDepartmentsInfo } from '../slice';
import { loading, success, failure } from './slice';
import { listDepartmentsStart } from './actions';
import { getDepartments } from './service';
import { history } from 'redux/store';
import { Action } from '@reduxjs/toolkit';

function* listDepartmentsFlow(action: Action<unknown>) {
  if (listDepartmentsStart.match(action)) {
    try {
      yield put(loading());
      const { data } = yield call(getDepartments, action.payload);
      const { data: departmentsInfo }: { data: DepartmentInfo[] } = data;
      yield put(setDepartmentsInfo(departmentsInfo));
      yield put(success());
      yield history.push(history.location);
    } catch (error) {
      const { message }: { message: string } = error?.response?.data || error;
      const statusCode: number = error?.response?.status;
      yield put(failure({ message, statusCode }));
    }
  }
}

export function* onListDepartmentsStart() {
  yield takeLatest(listDepartmentsStart, listDepartmentsFlow);
}
