import { createSlice } from '@reduxjs/toolkit';

import { RootState } from 'redux/root-reducer';

const initialState = {
  isEditorMode: false,
};

const editorSlice = createSlice({
  name: 'EDITOR',
  initialState,
  reducers: {
    toggleEditorOn(state) {
      state.isEditorMode = true;
    },
    toggleEditorOff(state) {
      state.isEditorMode = false;
    },
  },
});

// REDUCER
export default editorSlice.reducer;

// ACTIONS
export const { toggleEditorOn, toggleEditorOff } = editorSlice.actions;

// SELECTORS
export const selectIsEditorMode = (state: RootState) =>
  state.ui.organization.editor.isEditorMode;
