import { all, call } from 'redux-saga/effects';

import { onInviteUserStart } from './invite-user/saga';
import { onShowInvitationStart } from './show-invitation/saga';
import { onListInvitationsStart } from './list-invitations/saga';
import { onAcceptInvitationStart } from './accept-invitation/saga';
import { onRejectInvitationStart } from './reject-invitation/saga';
import { onRevokeInvitationStart } from './revoke-invitation/saga';

export default function* () {
  yield all([
    call(onInviteUserStart),
    call(onShowInvitationStart),
    call(onListInvitationsStart),
    call(onAcceptInvitationStart),
    call(onRejectInvitationStart),
    call(onRevokeInvitationStart),
  ]);
}
