import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RenderingState, ErrorInfo } from 'redux/types';
import { RootState } from 'redux/root-reducer';

const initialState: RenderingState = {
  isLoading: false,
  success: false,
  error: {
    message: '',
    statusCode: null,
  },
};

const updatePipelineSlice = createSlice({
  name: 'UPDATE_PIPELINE',
  initialState,
  reducers: {
    loading: (state) => {
      state.isLoading = true;
    },
    success: (state) => {
      state.isLoading = false;
      state.success = true;
      state.error = initialState.error;
    },
    failure: (state, action: PayloadAction<ErrorInfo>) => {
      state.isLoading = false;
      state.error.message = action.payload.message;
      state.error.statusCode = action.payload.statusCode;
    },
  },
});

// REDUCER
export default updatePipelineSlice.reducer;

// ACTIONS
export const { loading, success, failure } = updatePipelineSlice.actions;

// SELECTORS
export const selectIsLoading = (state: RootState) =>
  state.ui.job.create.workflow.updatePipeline.isLoading;
export const selectSuccess = (state: RootState) =>
  state.ui.job.create.workflow.updatePipeline.success;
export const selectError = (state: RootState) =>
  state.ui.job.create.workflow.updatePipeline.error;
