import React, { Suspense } from 'react';
import { Spinner } from 'react-bootstrap';
import { Route, Switch } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';

const Invitations = React.lazy(() => import('features/invitations'));
const Dashboard = React.lazy(() => import('pages/dashboard'));
const JobsPage = React.lazy(() => import('pages/organization-jobs'));
const CandidatesPage = React.lazy(() => import('pages/candidates'));
const InboxPage = React.lazy(() => import('pages/inbox'));
const TimelinePage = React.lazy(() => import('pages/timeline'));
const SignUp = React.lazy(() => import('features/authentication/sign_up'));
const Login = React.lazy(() => import('features/authentication/login'));
const Home = React.lazy(() => import('pages/home'));
const ResetPassword = React.lazy(() => import('features/authentication/reset-password'));
const FancyError = React.lazy(() => import('components/Errors/FancyError'));
const UpdateProfileInfo = React.lazy(() => import('features/user/update-profile-info'));
const ResetPassEmail = React.lazy(
  () => import('features/authentication/send-reset-password-email'),
);
const ResendConfirmationEmail = React.lazy(
  () => import('features/authentication/resend-confirmation-email'),
);
const CreateOrganization = React.lazy(
  () => import('features/organization/create-organization'),
);
const Settings = React.lazy(() => import('pages/settings'));
const NotFound = React.lazy(() => import('pages/error/NotFound'));

export interface TRoute {
  path: string;
  component: React.LazyExoticComponent<(props: any) => JSX.Element>;
  protected?: boolean;
  public?: boolean;
  routes?: TRoute[];
  exact?: boolean;
}

// ROUTES
const routes: TRoute[] = [
  {
    path: '/',
    component: Home,
    exact: true,
  },
  {
    path: '/login',
    component: Login,
    public: true,
  },
  {
    path: '/sign-up',
    component: SignUp,
    public: true,
  },
  {
    path: '/dashboard',
    component: Dashboard,
    protected: true,
    routes: [
      {
        path: '/dashboard/:organizationSlug/jobs',
        component: JobsPage,
        protected: true,
      },
      {
        path: '/dashboard/:organizationSlug/candidates',
        component: CandidatesPage,
        protected: true,
      },
      {
        path: '/dashboard/:organizationSlug/inbox',
        component: InboxPage,
        protected: true,
      },
      {
        path: '/dashboard/:organizationSlug/timeline',
        component: TimelinePage,
        protected: true,
      },
      {
        path: '/dashboard/:organizationSlug/settings',
        component: Settings,
        protected: true,
      },
      {
        path: '/dashboard/*',
        component: NotFound,
      },
    ],
  },
  {
    path: '/admin/create-organization',
    component: CreateOrganization,
    exact: true,
    protected: true,
  },
  {
    path: '/reset-password',
    component: ResetPassword,
  },
  {
    path: '/send-reset-password-email',
    component: ResetPassEmail,
  },
  {
    path: '/resend-confirmation-email',
    component: ResendConfirmationEmail,
  },
  {
    path: '/update-profile-info',
    component: UpdateProfileInfo,
    protected: true,
  },
  {
    path: '/fancy-error',
    component: FancyError,
  },
  {
    path: '/invitations',
    component: Invitations,
    protected: true,
  },
  {
    path: '*',
    component: NotFound,
  },
];

// BOOTSTRABING ROUTES
export default function Routes() {
  return (
    <Suspense fallback={<Spinner animation="border" />}>
      <Switch>
        {routes.map((route) => {
          const { component: Component, ...rest } = route;
          if (route.protected) {
            return <PrivateRoute key={route.path} component={Component} {...rest} />;
          }
          if (route.public) {
            return <PublicRoute key={route.path} component={Component} {...rest} />;
          }
          return (
            <Route {...rest} key={route.path}>
              <Component routes={route.routes} />
            </Route>
          );
        })}
      </Switch>
    </Suspense>
  );
}
