import * as React from 'react';
import { useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useRouteMatch, Switch, Route, Redirect } from 'react-router-dom';
import { selectJobDetails } from 'features/job/create-job/create-job-stages/job-details/job-details.slice';
import { listStageApplicationsStart } from './actions';
import {
  selectStageApplications,
  resetStageApplications,
} from './stage-applications.slice';
import Application from './components/Application';
import Applicants from './components/Applicants';
import { selectSuccess } from './slice';

import {
  selectSuccess as updateApplicationSuccess,
  selectError,
} from '../update-application/slice';
import CustomToast from 'components/CustomToast';
import { useOrganizationId } from 'hooks';

export { default as stageApplicationsReducer } from './stage-applications.slice';
export { default as listStageApplicationsReducer } from './slice';
export { default as onListStageApplicationsStart } from './saga';

export interface IListStageApplicationsProps {}
export default function ListStageApplications() {
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const { stageId } = useParams<{ stageId: string | undefined }>();
  const jobId = useSelector(selectJobDetails)?.id;
  const stageApplications = useSelector(selectStageApplications).info;
  const stageApplicationsSuccess = useSelector(selectSuccess);
  const stageApplicationId = stageApplications ? stageApplications[0]?.id : null;
  const organizationId = useOrganizationId();
  const applicationUpdated = useSelector(updateApplicationSuccess);
  const error = useSelector(selectError);

  const [show, setShow] = useState(false);

  useEffect(() => {
    if (applicationUpdated || error.message) {
      setShow(true);
    }
  }, [applicationUpdated, error.message]);

  useEffect(() => {
    if (stageId && jobId && organizationId) {
      dispatch(
        listStageApplicationsStart({
          job_id: jobId,
          stage_id: stageId,
          organizationId,
        }),
      );
    }

    return () => {
      dispatch(resetStageApplications());
    };
  }, [stageId, jobId, organizationId, dispatch]);

  return (
    <>
      {stageApplicationsSuccess && stageApplications && stageApplications?.length > 0 && (
        <Row className="job__content">
          <Applicants stageApplications={stageApplications!} />
          <Switch>
            <Route path={`${match.url}/application/:applicationId`}>
              <Application stageApplicationId={stageApplicationId} stageId={stageId!} />
            </Route>
            <Route path={`${match.url}/application`}>
              <Application stageApplicationId={stageApplicationId} stageId={stageId!} />
            </Route>
            <Route
              path={match.url}
              exact
              render={() => <Redirect to={`${match.url}/application`} />}
            />
          </Switch>
        </Row>
      )}
      {applicationUpdated && (
        <CustomToast
          show={show}
          setShow={setShow}
          status="Success"
          message={'Application Updated'}
        />
      )}
      {error.message && (
        <CustomToast
          show={show}
          setShow={setShow}
          status="Failure"
          message="Something wrong happened"
        />
      )}
    </>
  );
}
