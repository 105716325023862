import * as React from 'react';

function SvgComponent(props: any) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={297.18} height={190.152} {...props}>
      <defs>
        <linearGradient
          id="prefix__a"
          x1={0.5}
          y1={1.719}
          x2={0.5}
          y2={0.109}
          gradientUnits="objectBoundingBox"
        >
          <stop offset={0} stopColor="#fff" />
          <stop offset={0.118} stopColor="#fff" stopOpacity={0.824} />
          <stop offset={0.3} stopColor="#fff" stopOpacity={0.576} />
          <stop offset={0.475} stopColor="#fff" stopOpacity={0.373} />
          <stop offset={0.636} stopColor="#fff" stopOpacity={0.212} />
          <stop offset={0.783} stopColor="#fff" stopOpacity={0.094} />
          <stop offset={0.909} stopColor="#fff" stopOpacity={0.024} />
          <stop offset={1} stopColor="#fff" stopOpacity={0} />
        </linearGradient>
        <style>
          {
            '.prefix__c{fill:#d7f0f9}.prefix__d{fill:#0582c1}.prefix__e{fill:#79c9e8}.prefix__f{fill:#fff}.prefix__g{fill:#ffd835}.prefix__h{fill:#00668e}.prefix__j{fill:#f98d3d}.prefix__q{fill:#f2c300}.prefix__r{opacity:.29;mix-blend-mode:multiply;isolation:isolate}.prefix__o{fill:#62db7e}.prefix__p{fill:#31cd55}.prefix__r,.prefix__v{fill:#fbc926}.prefix__s{fill:#f77e2d}.prefix__t{fill:#49b4d6}'
          }
        </style>
      </defs>
      <path
        d="M288.305 190.152a8.756 8.756 0 008.76-8.616c.366-23.838 2.286-59-42.606-68.723-36.328-7.869-14.789-72.43-112.627-68.258-70.231 2.994-51.575 62.19-106.175 67.12C-.679 114.955-.994 160.911.403 181.969a8.759 8.759 0 008.742 8.183z"
        fill="#b5dfea"
      />
      <path
        d="M254.458 112.813c-36.328-7.869-14.789-72.43-112.627-68.258-16.7.712-28.373 4.607-37.2 10.191 2.974-.389 6.131-.664 9.5-.807 97.837-4.172 76.3 60.389 112.627 68.258 44.413 9.621 43.007 44.137 42.618 67.955h18.934a8.756 8.756 0 008.76-8.616c.36-23.841 2.28-58.999-42.612-68.723z"
        opacity={0.55}
        fill="#90cedd"
      />
      <path
        className="prefix__c"
        d="M211.047 41.802L96.179 28.394a8.949 8.949 0 00-9.926 7.851l-8.574 73.452a8.949 8.949 0 007.851 9.926l114.869 13.409a8.949 8.949 0 009.926-7.851l8.573-73.452a8.949 8.949 0 00-7.851-9.927z"
      />
      <path
        className="prefix__d"
        d="M218.05 58.998l.848-7.27a8.949 8.949 0 00-7.851-9.927L96.179 28.394a8.949 8.949 0 00-9.926 7.851l-.849 7.271z"
      />
      <path
        className="prefix__e"
        d="M103.988 87.993l14.561-25.655a4.7 4.7 0 014.636-2.35 4.7 4.7 0 014.127 5.217l-3.029 25.95.129.015a3.26 3.26 0 012.86 3.615 3.26 3.26 0 01-3.615 2.86l-.13-.015-.552 4.726a3.519 3.519 0 01-3.9 3.088 3.519 3.519 0 01-3.088-3.9l.551-4.726-10.483-1.223a3.266 3.266 0 01-2.872-3.626l.278-2.382a4.236 4.236 0 01.527-1.594zm13.352 1.919l1.878-16.094a.101.101 0 00-.192-.063l-8.6 15.18a.431.431 0 00.325.641l6.114.714a.43.43 0 00.476-.378zM168.248 95.494l14.561-25.655a4.7 4.7 0 014.636-2.35 4.7 4.7 0 014.126 5.217l-3.023 25.951.129.015a3.259 3.259 0 012.86 3.615 3.258 3.258 0 01-3.615 2.859l-.129-.015-.552 4.726a3.519 3.519 0 01-3.9 3.088 3.52 3.52 0 01-3.088-3.9l.552-4.726-10.482-1.223a3.265 3.265 0 01-2.864-3.621l.278-2.381a4.222 4.222 0 01.511-1.6zm13.353 1.919l1.878-16.094a.101.101 0 00-.192-.063l-8.6 15.181a.431.431 0 00.325.64l6.115.714a.431.431 0 00.475-.378zM139.361 72.901c.846-7.251 5.149-10.948 12.142-10.132s10.328 5.405 9.481 12.656l-2.75 23.566c-.847 7.251-5.15 10.949-12.142 10.133s-10.328-5.405-9.482-12.657zm4.318 24.85c-.378 3.237.9 4.633 3.168 4.9s3.834-.8 4.212-4.036l2.857-24.473c.378-3.237-.9-4.633-3.169-4.9s-3.834.8-4.212 4.037z"
      />
      <path
        className="prefix__f"
        d="M98.946 38.549a2.4 2.4 0 11-2.11-2.667 2.405 2.405 0 012.11 2.667z"
      />
      <path
        className="prefix__e"
        d="M110.468 39.889a2.4 2.4 0 11-2.11-2.668 2.405 2.405 0 012.11 2.668z"
      />
      <path
        className="prefix__g"
        d="M122.411 41.283a2.405 2.405 0 11-2.11-2.668 2.405 2.405 0 012.11 2.668z"
      />
      <g>
        <path
          className="prefix__h"
          d="M76.202 124.95a61.8 61.8 0 00-1.293 13.912c.231 6.3-.517 47.69-.517 47.69h5.056s7.2-32.419 7.466-34.979 3.89-28.162 3.89-28.162z"
        />
        <path
          className="prefix__d"
          d="M79.564 123.062a108.711 108.711 0 003.142 15.128c1.978 6.284 11.7 48.362 11.7 48.362h5.056s-.931-32.419-1.28-34.979-2.909-28.162-2.909-28.162z"
        />
        <path
          className="prefix__d"
          d="M94.821 186.544v3.528h9.624a.552.552 0 00.3-1.012 15.246 15.246 0 00-5.757-2.516zM74.65 186.544v3.528h9.624a.552.552 0 00.305-1.012 15.249 15.249 0 00-5.758-2.516z"
        />
        <path
          className="prefix__h"
          d="M92.014 81.921s3.31-5.026-.49-5.517c-2.919-.376-4.119.493-4.119.493s-3.36-.37-5.2 1.223c-1.26 1.093-5.885 9.563 1.594 12.628s5.885-4.781 5.885-4.781z"
        />
        <path
          d="M91.524 76.404c-2.919-.376-4.119.493-4.119.493s-3.36-.37-5.2 1.223c-1.26 1.093-5.885 9.563 1.594 12.628 3.515 1.441 5.026.47 5.64-.963-7.1 2-7.865-6.556-6.695-8.989 1.188-2.473 4.042-1.331 4.042-1.331 1.116-2.809 4.752-2.5 6.252-2.286a2.051 2.051 0 00-1.514-.775z"
          fill="#05556d"
        />
        <path
          className="prefix__j"
          d="M87.355 84.373s-.98-.981-1.716 0-.245 3.187.98 3.31c0 0-.367 3.188-2.82 3.065v2.575h5.765v-1.957s3.555-1.471 3.433-4.414a16.249 16.249 0 00-.981-5.026 8.014 8.014 0 01-4.661 2.447z"
        />
        <path
          d="M88.237 92.186c.57.237.471.6.5 1.137h.829v-1.962a4.624 4.624 0 01-3.442-.722 9.052 9.052 0 002.113 1.547z"
          fill="#ed701b"
        />
        <path
          className="prefix__j"
          d="M89.417 79.681s-2.987-1.19-3.336-.3c-.228.583.352 1.087 1.15 1.6l-.7.116a.185.185 0 00-.089.325 3.952 3.952 0 001.867.3z"
        />
        <path d="M71.65 112.072l4 12.9-.071-.651.071.651 1.351-18.779z" fill="none" />
        <path
          className="prefix__g"
          d="M110.743 87.162c-5.7-4.221-21.25-7.595-21.25-7.595l-1.282 2.336s14.121 5.472 13.189 6.287c-.547.482-7.447 3.643-9.783 4.707a3.711 3.711 0 01-1.606.33l-7.15-.041a11.283 11.283 0 00-9.216 4.049c-3.627 4.174-9.794 13.842-9.794 13.842l10.387 15.276 1.15 1.687.01-.02-.01.142s.369.041 1.023.1c3.339.31 14.04 1.155 19.7-.045l.157-.036-.1-9.072-.16-15.987a97.132 97.132 0 0014.9-14.425 1.047 1.047 0 00-.165-1.535zM75.89 121.334l-1.014-2.214-3.228-7.052 5.356-6-.963 13.163z"
        />
        <path
          d="M74.876 119.122l1.013 2.214-.491 6.683-.01.02-1.15-1.687z"
          fill="#efaa3a"
        />
        <path
          d="M93.589 102.221c-.962.7-.718 6.9-.956 10.772-.4 6.489-.785 12.1-.5 15.724a28.871 28.871 0 003.983-.5l.157-.036-.268-25.06a97.132 97.132 0 0014.906-14.425 1.047 1.047 0 00-.167-1.535 127.575 127.575 0 01-17.155 15.06z"
          style={{
            isolation: 'isolate',
            mixBlendMode: 'multiply',
          }}
          opacity={0.29}
          fill="#f2c300"
        />
        <path
          className="prefix__h"
          d="M98.186 151.573c-.252-1.847-1.655-15.69-2.416-23.256l-3.634.4s5.3 43.577 5.649 57.836h1.682s-.934-32.421-1.281-34.98z"
        />
        <path
          className="prefix__d"
          d="M74.886 85.088l-1.095-1.964a7.424 7.424 0 10-1.284 1.748z"
        />
        <g>
          <path
            className="prefix__f"
            d="M63.377 78.985h.288v1.034c0 .316.094.452.317.452.174 0 .3-.087.456-.285v-1.2h.286v1.692h-.237l-.024-.265h-.011a.713.713 0 01-.557.307c-.359 0-.519-.23-.519-.661zM65.928 80.816v.571h-.286v-2.405h.237l.024.195h.01a.877.877 0 01.54-.237c.435 0 .668.338.668.864 0 .575-.345.912-.731.912a.783.783 0 01-.47-.2zm.414-.341c.279 0 .484-.255.484-.672 0-.372-.125-.623-.445-.623a.711.711 0 00-.453.23v.888a.669.669 0 00.414.181zM67.877 80.294a.76.76 0 00.5.2c.223 0 .334-.118.334-.265 0-.173-.2-.25-.387-.32-.24-.087-.5-.2-.5-.487s.216-.481.581-.481a.848.848 0 01.526.191l-.136.181a.63.63 0 00-.387-.149c-.213 0-.31.115-.31.244 0 .157.184.22.376.292.244.091.515.192.515.512 0 .275-.219.505-.62.505a1.017 1.017 0 01-.63-.234zM69.877 80.28a.22.22 0 11-.208.223.211.211 0 01.208-.223zM71.092 80.28a.22.22 0 11-.208.223.211.211 0 01.208-.223z"
          />
        </g>
      </g>
      <g>
        <path
          className="prefix__o"
          d="M46.444 174.286s-1.558-1.456-.513-6.977 3.7-10.823 2.4-16.813-11.508-21.092-11.542-22.1-3.149 17.569 2.13 24.886 5.913 9.855 5.679 13.7.054 6.947.633 7.841z"
        />
        <path
          className="prefix__p"
          d="M45.478 163.181c-.614 2.463-.984 8.638 0 10.116.091.138.224.059.387-.2a12.636 12.636 0 01.07-5.793c1.046-5.521 3.7-10.823 2.4-16.813s-11.508-21.092-11.542-22.1c0 0 1.538 12.582 6.717 21.244a16.536 16.536 0 011.968 13.546z"
        />
        <path
          className="prefix__o"
          d="M48.306 173.904s-3-7.486 2.083-13.968 13.208-14.8 13.37-18.1c0 0 2.089 15.654-4.525 20.085s-11.252 5.138-10.106 11.956z"
        />
        <path
          className="prefix__p"
          d="M55.444 158.771c-7.375 5.368-7.978 11.552-6.833 14.42a.728.728 0 00.48.432c-.989-6.581 3.612-7.329 10.143-11.7 6.614-4.431 4.525-20.085 4.525-20.085s-.937 11.566-8.315 16.933z"
        />
        <path
          className="prefix__o"
          d="M43.374 174.07a26.171 26.171 0 00.45-8.244c-.512-4.28-4.982-6.687-9.159-11.207-2.149-2.325-2.785-14.623-1.452-15.764 0 0-7.375 8.937-6.237 15.481s13.168 10.8 14.281 13.958a17.55 17.55 0 011.022 5.816z"
        />
        <path
          className="prefix__p"
          d="M30.721 142.371c-2.437 6.6.061 14.594 8.158 19.56 5.961 3.655 4.062 11.256 4.062 11.256l.974-3a23.808 23.808 0 00-.09-4.361c-.512-4.28-4.982-6.687-9.159-11.207-2.073-2.243-2.737-13.76-1.587-15.6a39.522 39.522 0 00-2.358 3.352z"
        />
        <path
          className="prefix__p"
          d="M33.079 139.02l.134-.167a.742.742 0 00-.134.167z"
        />
        <g>
          <path
            className="prefix__o"
            d="M49.849 173.575s1.728-6.413 6-7.276 9.183-7.181 9.449-8.419c.003 0-.718 11.913-15.449 15.695z"
          />
          <path
            className="prefix__p"
            d="M55.608 168.419a7.086 7.086 0 00-4.1 4.671c13.116-4.218 13.789-15.209 13.789-15.209a4.745 4.745 0 01-.667 1.253c-1.281 2.252-4.395 6.972-9.022 9.285z"
          />
        </g>
        <path
          className="prefix__g"
          d="M59.099 171.214H36.741l2.655 16.7a2.609 2.609 0 002.576 2.2h11.9a2.608 2.608 0 002.576-2.2z"
        />
        <path className="prefix__q" d="M58.66 173.977l.439-2.763H36.741l.439 2.763z" />
        <path
          className="prefix__q"
          d="M53.683 171.214l-2.655 16.7a2.609 2.609 0 01-2.576 2.2h5.416a2.608 2.608 0 002.576-2.2l2.655-16.7z"
        />
      </g>
      <g transform="translate(112.262 126.475)">
        <path className="prefix__g" d="M0 14.958h101.05v20.546H0z" />
        <path className="prefix__r" d="M0 14.958h101.05v4.987H0z" />
        <path
          className="prefix__s"
          d="M8.787 14.957h9.223l-2.472 4.989H6.31zM29.565 14.957h9.223l-2.472 4.989h-9.223zM50.344 14.957h9.223l-2.473 4.989h-9.223zM71.122 14.957h9.218l-2.472 4.989H68.65zM101.053 14.957v.139l-2.408 4.85h-9.213l2.472-4.989z"
        />
        <path className="prefix__e" d="M12.579 35.505h7.124v28.092h-7.124z" />
        <path className="prefix__t" d="M18.084 35.505h1.618v28.092h-1.618z" />
        <path className="prefix__e" d="M81.901 35.505h7.124v28.092h-7.124z" />
        <path
          className="prefix__t"
          d="M86.919 35.505h2.106v28.092h-2.106zM12.579 35.483h7.124v3.538h-7.124z"
        />
        <path className="prefix__t" d="M81.901 35.483h7.124v3.538h-7.124z" />
        <path className="prefix__e" d="M12.579 11.747h7.124v3.19h-7.124z" />
        <path className="prefix__t" d="M18.084 11.747h1.618v3.19h-1.618z" />
        <path className="prefix__e" d="M81.901 11.747h7.124v3.19h-7.124z" />
        <path
          className="prefix__t"
          d="M86.919 11.747h2.106v3.19h-2.106zM12.579 14.538h7.124v.402h-7.124z"
        />
        <path className="prefix__t" d="M81.901 14.538h7.124v.402h-7.124z" />
        <path
          className="prefix__d"
          d="M18.009 14.958L7.818 35.504h-7.82V32.67l8.786-17.711zM29.565 14.958L19.374 35.504h9.223l10.191-20.546zM59.567 14.958L49.376 35.504h-9.223l10.191-20.546zM80.34 14.958L70.154 35.504h-9.223l10.191-20.546zM91.901 14.958L81.71 35.504h9.218l10.124-20.407z"
        />
        <path
          className="prefix__h"
          d="M18.009 14.958l-2.472 4.989H6.309l2.477-4.989zM38.788 14.958l-2.472 4.989h-9.223l2.472-4.989zM59.567 14.958l-2.473 4.989h-9.223l2.472-4.989zM80.34 14.958l-2.472 4.989H68.65l2.472-4.989zM101.053 15.097l-2.408 4.85h-9.213l2.472-4.989z"
        />
        <path
          transform="translate(2.816 23.846)"
          style={{
            mixBlendMode: 'overlay',
            isolation: 'isolate',
          }}
          fill="url(#prefix__a)"
          opacity={0.55}
          d="M0 0h96.492v10.277H0z"
        />
        <circle
          className="prefix__v"
          cx={6.351}
          cy={6.351}
          r={6.351}
          transform="rotate(-9.25 16.913 -52.46)"
        />
        <path
          className="prefix__d"
          d="M18.833 7.292a2.878 2.878 0 10-2.879 2.878 2.878 2.878 0 002.879-2.878z"
        />
        <circle
          className="prefix__v"
          cx={6.351}
          cy={6.351}
          r={6.351}
          transform="rotate(-9.25 51.526 -481.131)"
        />
        <path
          className="prefix__d"
          d="M88.299 7.292a2.878 2.878 0 10-2.878 2.878 2.878 2.878 0 002.878-2.878z"
        />
      </g>
      <g>
        <path
          d="M251.595 187.894h-32.182l4.922-11.933v-.01l1.98-4.814.545-1.32.889-2.148.182-.438 1.98-4.815.364-.875 3.508-8.519a1.861 1.861 0 013.441 0l3.508 8.519.364.875 1.98 4.815.182.438.888 2.148.546 1.32 1.98 4.814v.007z"
          fill="#ffdc3a"
        />
        <path
          className="prefix__r"
          d="M221.522 182.778l-2.109 5.116h32.184l-2.109-5.116z"
        />
        <path className="prefix__e" d="M216.841 184.835H253.7v5.211h-36.859z" />
        <path className="prefix__t" d="M245.737 184.835h7.964v5.211h-7.964z" />
        <path
          className="prefix__r"
          d="M250.329 184.832h-4.552l-3.657-8.869v-.006l-1.979-4.815-.546-1.32-.889-2.149-.182-.438-1.98-4.815-.364-.875-2.95-7.172.552-1.346a1.861 1.861 0 013.441 0l3.508 8.519.364.875 1.98 4.815.182.438.888 2.149.546 1.32 1.98 4.815v.006z"
        />
        <path
          className="prefix__d"
          d="M243.076 167.233h-15.145l1.98-4.815h11.185zM246.673 175.953v.007h-22.338v-.007l1.98-4.815h18.378z"
        />
        <path
          className="prefix__h"
          d="M243.076 167.233h-4.552l-1.98-4.815h4.552zM246.672 175.953v.007h-4.552v-.007l-1.98-4.815h4.552z"
        />
      </g>
      <g transform="translate(5.614)">
        <path
          className="prefix__c"
          d="M40.839 70.88a4.838 4.838 0 004.82-4.128 9.613 9.613 0 00-9.516-10.911c-.218 0-.433.01-.647.024a10.2 10.2 0 00-20.366.863c0 .25.012.5.03.742A10.2 10.2 0 00.017 65.641c0 .041-.006.081-.008.122a4.862 4.862 0 004.873 5.117zM270.053 78.424c7.595 0 8.016-11.43.43-11.8-.208-.01-.416-.015-.625-.015a12.673 12.673 0 00-3.881.608 13.032 13.032 0 00-25.776.411 12.063 12.063 0 00-8.19-.8c-6.641 1.71-5.247 11.591 1.611 11.591zM178.847 22.107c7.593 0 8.013-11.426.429-11.8-.207-.01-.416-.015-.625-.015a12.666 12.666 0 00-3.88.608 13.027 13.027 0 00-25.768.411 12.062 12.062 0 00-8.188-.8c-6.639 1.71-5.245 11.588 1.61 11.588z"
        />
        <circle
          className="prefix__g"
          cx={18.202}
          cy={18.202}
          r={18.202}
          transform="translate(39.762 6.822)"
        />
        <path
          className="prefix__q"
          d="M58.113 6.825a18.2 18.2 0 01-9.981 33.422h-.149a18.2 18.2 0 1010.13-33.418z"
        />
      </g>
    </svg>
  );
}

export default SvgComponent;
