import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'redux/root-reducer';
import { Sections } from './types';

const initialState: { info: Sections | null } = {
  info: null,
};

const sectionSlice = createSlice({
  name: 'CURRENT_SECTION',
  initialState,
  reducers: {
    setCurrentSectionInfo: (state, action: PayloadAction<Sections>) => {
      state.info = action.payload;
    },
    resetCurrentSectionInfo: (state) => {
      state.info = initialState.info;
    },
  },
});

// REDUCER
export default sectionSlice.reducer;

// ACTIONS
export const { setCurrentSectionInfo, resetCurrentSectionInfo } = sectionSlice.actions;

// SELECTORS
export const selectSectionsInfo = (state: RootState) => state.organization.currentSection;
