import * as React from 'react';
import { useContext, useState } from 'react';
import { Draggable, DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import { TQuestionWithOptions, QuestionOption } from '../../types';
import { Card, Form, ButtonGroup } from 'react-bootstrap';
import { IconDrag } from 'assets/svg/icons';
import { EdittingStateContext } from './QuestionWithOptions';
import SaveQuestion from '../SaveQuestion';
import CancelQuestion from '../CancelQuestion';
import DeleteQuestion from '../DeleteQuestion';
import OptionsEditting from './OptionsEditting';

export interface IQuestionsWithOptionsEdittingProps {
  question: TQuestionWithOptions;
  index: number;
  newQuestion: boolean;
}

const minimumOptions = (options: QuestionOption[]): boolean => {
  const validOptions = options.filter((option) => option.value.trim() !== '');
  return validOptions.length >= 2;
};

const onDragEnd = (
  result: DropResult,
  options: QuestionOption[],
  setOptions: React.Dispatch<React.SetStateAction<QuestionOption[]>>,
) => {
  const { destination, source } = result;
  if (!destination) return;

  if (
    destination.droppableId === source.droppableId &&
    destination.index === source.index
  )
    return;

  const newOptions = [...options];
  const [removed] = newOptions.splice(source.index, 1);
  newOptions.splice(destination.index, 0, removed);
  setOptions(newOptions);
};

export default function QuestionsWithOptionsEditting(
  props: IQuestionsWithOptionsEdittingProps,
) {
  const { question, index, newQuestion } = props;
  const { setIsEditting } = useContext(EdittingStateContext);
  const [questionHeadline, setQuestionHeadline] = useState<string>(question.headline);
  const [options, setOptions] = useState(question.options);

  return (
    <Draggable draggableId={question.id} index={index}>
      {(provided) => (
        <div
          className="card question-container"
          ref={provided.innerRef}
          {...provided.draggableProps}
        >
          <Card.Body className="question__body">
            <Card.Title className="question__header">
              <div className="drag-handle" {...provided.dragHandleProps}>
                <IconDrag></IconDrag>
              </div>
              {`Question (${question.type.split('_').join(' ').toLowerCase()})`}
            </Card.Title>
            <Form.Control
              type="text"
              value={questionHeadline}
              onChange={(e) => setQuestionHeadline(e.target.value)}
              className="question__headline--editting"
            ></Form.Control>
            <p className="question__headline">Answers</p>
            <DragDropContext
              onDragEnd={(result) => onDragEnd(result, options, setOptions)}
            >
              <Droppable droppableId={'droppableOptions'}>
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    <OptionsEditting
                      provided={provided}
                      optionsState={[options, setOptions]}
                    />
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            <hr className="question__divider" />
            <ButtonGroup>
              <CancelQuestion
                setIsEditting={setIsEditting}
                newQuestion={newQuestion}
                questionId={question.id}
              />
              <SaveQuestion
                questionUpdated={{
                  headline: questionHeadline,
                  id: question.id,
                  type: question.type,
                  options: options.filter((option) => !!option.value.trim()),
                }}
                setIsEditting={setIsEditting}
                disabled={!questionHeadline.trim() || !minimumOptions(options)}
              />
            </ButtonGroup>
            <ButtonGroup className="question__btns">
              <DeleteQuestion questionId={question.id} />
            </ButtonGroup>
          </Card.Body>
        </div>
      )}
    </Draggable>
  );
}
