import api from 'services/api';
import { JobFormValues } from './create-job-stages/job-details/types';

export async function createJob({
  jobInfo,
  organizationId,
}: {
  jobInfo: JobFormValues;
  organizationId: string;
}) {
  const response = api.post(`api/dashboard/organizations/${organizationId}/jobs`, {
    job: {
      title: jobInfo.jobTitle,
      department_id: jobInfo.department?.value,
      description: jobInfo.jobDescription,
      requirments: jobInfo.jobRequirements,
      country: jobInfo.location?.country?.value,
      city: jobInfo.location?.city?.value,
      zip_code: jobInfo.location?.zipCode,
      employment_type: jobInfo.employment?.employmentType?.value,
      education: jobInfo.employment?.education?.value,
      experience: jobInfo.employment?.experience?.value,
      keywords: jobInfo.employment?.keywords,
      salary_from: jobInfo.salaryDetails?.from,
      salary_to: jobInfo.salaryDetails?.to,
      salary_currency: jobInfo.salaryDetails?.currency?.value,
    },
  });
  return response;
}
