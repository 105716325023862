import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'redux/root-reducer';
import { InvitationsInfo } from 'redux/types';

const initialState: { info: InvitationsInfo } = {
  info: [],
};

const invitationsSlice = createSlice({
  name: 'INVITATIONS',
  initialState,
  reducers: {
    setInvitations: (state, action: PayloadAction<InvitationsInfo>) => {
      state.info = action.payload;
    },
  },
});

// REDUCER
export default invitationsSlice.reducer;

// ACTIONS
export const { setInvitations } = invitationsSlice.actions;

// SELECTORS
export const selectInvitations = (state: RootState) => state.invitations.info;
