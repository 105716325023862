import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { UploadTokens } from 'redux/types';
import { RootState } from 'redux/root-reducer';

const initialState: UploadTokens = {
  upload_url: '',
  file_url: '',
};

const uploadTokensSlice = createSlice({
  name: 'UPLOAD_TOKENS',
  initialState,
  reducers: {
    setUploadTokens: (state, action: PayloadAction<UploadTokens>) => {
      state.upload_url = action.payload.upload_url;
      state.file_url = action.payload.file_url;
    },
    resetUploadTokens: (state) => {
      state.upload_url = initialState.upload_url;
      state.file_url = initialState.file_url;
    },
  },
});

// REDUCER
export default uploadTokensSlice.reducer;

// ACTIONS
export const { setUploadTokens, resetUploadTokens } = uploadTokensSlice.actions;

// SELECTORS
export const selectTokens = (state: RootState) => state.uploadTokens;
