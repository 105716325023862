import * as React from 'react';

export interface IStageStaticProps {
  stageName: string;
}

export default function StageStatic(props: IStageStaticProps) {
  const { stageName } = props;
  return (
    <div className="card-small card-small--static pr-4">
      <label className="form-label form-label--dark">{stageName}</label>
    </div>
  );
}
