import api from 'services/api';
import { OrganizationInfo } from 'redux/types';

export async function createOrganization(values: OrganizationInfo) {
  const response = await api.post('/api/dashboard/organizations', {
    organization: {
      ...values,
    },
  });

  return response;
}
