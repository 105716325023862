import * as React from 'react';
import { IconDrag } from 'assets/svg/icons';
import { Draggable, DraggingStyle, NotDraggingStyle } from 'react-beautiful-dnd';
import { StageInfo } from '../../types';
import { ButtonGroup } from 'react-bootstrap';
import { removeStage } from '../pipeline.slice';
import { useDispatch } from 'react-redux';
import { DeletedStagesContex } from '../show-pipeline';

export interface IStageSavedProps {
  index: number;
  stage: StageInfo;
  setIsEditting: React.Dispatch<React.SetStateAction<boolean>>;
}

const getItemStyle = (
  isDragging: boolean,
  draggablStyle: DraggingStyle | NotDraggingStyle | undefined,
) => ({
  paddingRight: '16px',
  boxShadow: isDragging ? '0px 3px 6px #0000001A' : 'initial',
  background: 'white',
  ...draggablStyle,
});

export default function StageSaved(props: IStageSavedProps) {
  const { index, stage, setIsEditting } = props;
  const dispatch = useDispatch();
  const { deletedStages, setDeletedStages } = React.useContext(DeletedStagesContex);

  const onEdit = () => {
    setIsEditting(true);
  };

  return (
    <Draggable draggableId={stage.draggableId!} index={index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
          className="card-small pr-4"
        >
          <label className="form-label form-label--dark">
            <div className="drag-handle">
              <IconDrag></IconDrag>
            </div>
            {stage.name}
          </label>
          <ButtonGroup>
            <button onClick={onEdit} className="btn-edit btn-text btn-reset mr-3">
              Edit
            </button>
            <button
              onClick={() => {
                dispatch(removeStage({ id: stage.draggableId! }));
                if (stage.id) {
                  if (deletedStages && setDeletedStages) {
                    setDeletedStages([...deletedStages, stage]);
                  }
                }
              }}
              className="btn-delete btn-text btn-reset ml-4"
            >
              Delete
            </button>
          </ButtonGroup>
        </div>
      )}
    </Draggable>
  );
}
