import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import { createBrowserHistory } from 'history';

import rootReducer from './root-reducer';
import rootSaga from './root-saga';

// DEVELOPMENT
const devMode = process.env.NODE_ENV === 'development';

// MIDDLEWARES
const sagaMiddleWare = createSagaMiddleware();
const middleware = [...getDefaultMiddleware({ thunk: false }), sagaMiddleWare];

if (devMode) middleware.push(logger);

// STORE
const store = configureStore({
  reducer: rootReducer,
  devTools: devMode,
  middleware,
});

sagaMiddleWare.run(rootSaga);

// HOT MODULE REPLACEMENT FOR THE ROOT REDUCER
if (devMode && module.hot) {
  module.hot.accept('./root-reducer', () => {
    const newRootReducer = require('./root-reducer').default;
    store.replaceReducer(newRootReducer);
  });
}

// DISPATCH
export type AppDispatch = typeof store.dispatch;

// HISTORY
export const history = createBrowserHistory();

export default store;
