import api from 'services/api';

export async function updateDepartment({
  departmentName,
  departmentId,
  organizationId,
}: {
  departmentName: string;
  departmentId: string;
  organizationId: string;
}) {
  const response = await api.put(
    `api/dashboard/organizations/${organizationId}/departments/${departmentId}`,
    {
      department: {
        name: departmentName,
      },
    },
  );
  return response;
}
