import * as React from 'react';

function SvgParagraph(props: {}) {
  return (
    <svg width={22.266} height={13.916} {...props}>
      <defs>
        <style>{'.Paragraph_svg__a{fill:#b2b2b2}'}</style>
      </defs>
      <path
        className="Paragraph_svg__a"
        d="M21.57 5.567H.7a.7.7 0 00-.7.7V7.65a.7.7 0 00.7.7h20.87a.7.7 0 00.7-.7V6.262a.7.7 0 00-.7-.695zm0-5.567H.7a.7.7 0 00-.7.7v1.392a.7.7 0 00.7.7h20.87a.7.7 0 00.7-.7V.696a.7.7 0 00-.7-.696zM15.295 11.133H.493c-.271 0-.493.313-.493.7v1.392c0 .383.222.7.493.7h14.8c.271 0 .493-.313.493-.7v-1.396c.002-.383-.22-.696-.491-.696z"
      />
    </svg>
  );
}

export default SvgParagraph;
