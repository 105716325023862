import { createAction } from '@reduxjs/toolkit';
import { withPayloadType } from 'utils';

export interface UpdateProfileValues {
  first_name?: string;
  last_name?: string;
  email?: string;
  password?: string;
  password_confirmation?: string;
}
interface Payload {
  values: UpdateProfileValues;
  avatar?: {
    file: File;
    expires_in: number | undefined;
  };
}

export const updateProfileInfoStart = createAction(
  'UPDATE_PROFILE_INFO_START',
  withPayloadType<Payload>(),
);
