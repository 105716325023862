import { TQuestion, QuestionsAction, TQuestionWithOptions } from './types';

export default function questionsReducer(state: TQuestion[], action: QuestionsAction) {
  switch (action.type) {
    case 'SET_QUESTIONS':
      return [...action.payload];
    case 'ADD_QUESTION':
      return [...state, action.payload];
    case 'REMOVE_QUESTION':
      return state.filter((question) => question.id !== action.payload.id);
    case 'EDIT_QUESTION':
      return state.map((question) =>
        question.id === action.payload.id ? action.payload : question,
      );
    case 'SET_QUESTION_OPTIONS':
      return state.map((question) => {
        if (
          (question as TQuestionWithOptions).options &&
          question.id === action.payload.questionId
        ) {
          return { ...question, options: action.payload.options };
        }
        return question;
      });
    default:
      return [...state];
  }
}
