import { combineReducers } from 'redux';
import { showPipelineReducer } from './pipeline/show-pipeline';
import { updatePipelineReducer } from './pipeline/update-pipeline';
import { createPipelineTemplateReducer } from './pipeline/create-pipeline-template';
import { createPipelineReducer } from './pipeline/create-pipeline';

export default combineReducers({
  showPipeline: showPipelineReducer,
  updatePipeline: updatePipelineReducer,
  createPipeline: createPipelineReducer,
  createPipelineTemplate: createPipelineTemplateReducer,
});
