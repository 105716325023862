import * as React from 'react';
import { resetToInitials as updateResetToInitials } from '../update-department/slice';
import { resetToInitials as createResetToInitials } from '../create-department/slice';

import { resetToInitials as deleteResetToInitials } from '../delete-department/slice';
import { useCleanup, useMyToast } from 'hooks';
import { ToastContainer } from 'react-toastify';
import {
  useCreateDepartmentRequestStates,
  useDeleteDepartmentRequestStates,
  useUpdateDepartmentRequestStates,
} from '../hooks';

export interface IDepartmentsToastersProps {}

export default function DepartmentsToasters(props: IDepartmentsToastersProps) {
  const {
    updateDepartmentLoading,
    updateDepartmentError,
    updateDepartmentSuccess,
  } = useUpdateDepartmentRequestStates();
  const {
    deleteDepartmentLoading,
    deleteDepartmentError,
    deleteDepartmentSuccess,
  } = useDeleteDepartmentRequestStates();

  const {
    createDepartmentLoading,
    createDepartmentError,
    createDepartmentSuccess,
  } = useCreateDepartmentRequestStates();

  // hooks
  useMyToast(updateDepartmentError, updateDepartmentLoading, {
    success: updateDepartmentSuccess,
    message: 'Department updated successfully!',
  });
  useMyToast(createDepartmentError, createDepartmentLoading);
  useMyToast(deleteDepartmentError, deleteDepartmentLoading);

  useCleanup(
    updateResetToInitials,
    updateDepartmentError.message,
    updateDepartmentSuccess,
  );
  useCleanup(
    createResetToInitials,
    createDepartmentError.message,
    createDepartmentSuccess,
  );

  useCleanup(
    deleteResetToInitials,
    deleteDepartmentError.message,
    deleteDepartmentSuccess,
  );

  return (
    <>
      {(updateDepartmentError.message ||
        createDepartmentError.message ||
        deleteDepartmentError.message ||
        updateDepartmentError.statusCode === 500 ||
        deleteDepartmentError.statusCode === 500 ||
        createDepartmentError.statusCode === 500 ||
        updateDepartmentSuccess ||
        deleteDepartmentSuccess ||
        createDepartmentSuccess) && <ToastContainer />}
    </>
  );
}
