import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form, FormControl } from 'react-bootstrap';

export interface ISearchBarProps {
  searchInput?: string;
  setSearchInput?: React.Dispatch<React.SetStateAction<string>>;
}

export default function SearchBar(props: ISearchBarProps) {
  const { searchInput, setSearchInput } = props;
  return (
    <>
      <Form className="search-bar" inline>
        <FormControl
          type="text"
          placeholder="Search..."
          className="search-bar__input mr-sm-2"
          value={searchInput}
          onChange={(e) => (setSearchInput ? setSearchInput(e.target.value) : null)}
        />
        <button className="search-bar__btn">
          <FontAwesomeIcon className="search-bar__icon" icon="search" />
        </button>
      </Form>
    </>
  );
}
