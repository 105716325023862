import { all } from 'redux-saga/effects';
import { onUpdateJobStart } from './create-job/create-job-stages/job-details/update-job-details';
import { onShowJobStart } from './show-job';
import { onCreateJobStart } from './create-job';
import { onShowApplicationFormStart } from './create-job/create-job-stages/application-form/show-application-form';
import { onUpdateApplicationFormStart } from './create-job/create-job-stages/application-form/update-application-form';
import { onShowPipelineStart } from './create-job/create-job-stages/workflow/pipeline/show-pipeline/show-pipeline.saga';
import { onUpdatePipelineStart } from './create-job/create-job-stages/workflow/pipeline/update-pipeline/update-pipeline.saga';
import { onCreatePipelineTemplateStart } from './create-job/create-job-stages/workflow/pipeline/create-pipeline-template/create-pipeline-template.saga';
import { onCreateApplicationFormTemplateStart } from './create-job/create-job-stages/application-form/create-application-form-template';
import { onListStageApplicationsStart } from './show-job/list-stage-applicants';
import { onUpdateApplicationStart } from './show-job/update-application';
import { onCreatePipelineStart } from './create-job/create-job-stages/workflow/pipeline/create-pipeline';

export default function* onJobAction() {
  yield all([
    onCreateJobStart(),
    onShowJobStart(),
    onUpdateJobStart(),
    onShowApplicationFormStart(),
    onUpdateApplicationFormStart(),
    onShowPipelineStart(),
    onUpdatePipelineStart(),
    onCreatePipelineStart(),
    onCreatePipelineTemplateStart(),
    onCreateApplicationFormTemplateStart(),
    onListStageApplicationsStart(),
    onUpdateApplicationStart(),
  ]);
}
