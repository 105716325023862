import * as React from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { toast, ToastContainer } from 'react-toastify';
import * as Yup from 'yup';

import { useCleanup } from 'hooks';
import { discardEmptyFields, toastConfig } from 'utils';
import { OnSignupEmployeeSubmit } from 'features/authentication/types';
import UploadAvatar from 'features/UploadAvatar';
import { selectUser } from '../slice';
import { selectError, selectIsLoading, selectSuccess, resetToInitials } from './slice';
import { updateProfileInfoStart, UpdateProfileValues } from './actions';
import { Spinner } from 'react-bootstrap';

export { onGetUploadTokensAndUpdateProfileInfoStart } from './saga';
export { default as updateProfileInfoReducer } from './slice';

export interface IUpdateProfileInfoProps {}

// FORM VALIDATION
const validationSchema = Yup.object({
  first_name: Yup.string()
    .min(3, 'Name is too short')
    .max(15, 'Must be 15 characters or less'),
  last_name: Yup.string()
    .min(3, 'Name is too short')
    .max(20, 'Must be 20 characters or less'),
  email: Yup.string().email('Invalid email address'),
  password: Yup.string().min(6, 'Password has to be longer than 6 characters!'),
  password_confirmation: Yup.string().oneOf(
    [Yup.ref('password'), null],
    'Passwords must match',
  ),
});

// eslint-disable-next-line no-unused-vars
export default function UpdateProfileInfo(props: IUpdateProfileInfoProps) {
  const [image, setImage] = useState<File>();
  const dispatch = useDispatch();
  const isLoading = useSelector(selectIsLoading);
  const success = useSelector(selectSuccess);
  const error = useSelector(selectError);
  const user = useSelector(selectUser);

  useCleanup(resetToInitials, error.message, success);

  const onSubmit: OnSignupEmployeeSubmit = (values, { setSubmitting, resetForm }) => {
    setSubmitting(false);
    resetForm();
    const updateValues = discardEmptyFields(values) as UpdateProfileValues;
    const updateValuesAndAvatar = image
      ? { values: updateValues, avatar: { file: image, expires_in: 120 } }
      : { values: updateValues, avatar: undefined };
    dispatch(updateProfileInfoStart(updateValuesAndAvatar));
  };

  if (isLoading) return <Spinner animation="border" />;
  if (success) return <div>success</div>;
  if (error.message) {
    toast.error(error.message, toastConfig);
  }

  return (
    <div>
      <Formik
        initialValues={{
          first_name: user.first_name,
          last_name: user.last_name,
          email: user.email,
          password: '',
          password_confirmation: '',
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        <Form>
          <label htmlFor="first_name">New First Name</label>
          <Field name="first_name" type="text" />
          <ErrorMessage name="first_name" />
          <br />
          <label htmlFor="last_name">New Last Name</label>
          <Field name="last_name" type="text" />
          <ErrorMessage name="last_name" />
          <br />
          <label htmlFor="email">New Email Address</label>
          <Field name="email" type="email" />
          <ErrorMessage name="email" />
          <br />
          <label htmlFor="password">New password</label>
          <Field name="password" type="password" />
          <ErrorMessage name="password" />
          <br />
          <label htmlFor="password_confirmation">Confirm New Password</label>
          <Field type="password" name="password_confirmation" />
          <ErrorMessage name="password_confirmation" component="div" />
          <div>
            <UploadAvatar imageState={{ image, setImage }} />
          </div>
          <button type="submit">Submit</button>
          <br />
        </Form>
      </Formik>
      {error.message && <ToastContainer />}
    </div>
  );
}
