import { takeLatest, call, put } from 'redux-saga/effects';
import { createApplicationFormTemplateStart } from './actions';
import { loading, success, failure } from './slice';
import { setApplicationForm } from '../slice';
import { createApplicationFormTemplate } from './service';
import { Action } from '@reduxjs/toolkit';

function* createApplicationFormTemplateFlow(action: Action<unknown>) {
  if (createApplicationFormTemplateStart.match(action)) {
    try {
      yield put(loading());
      const { data } = yield call(createApplicationFormTemplate, action.payload);
      const { data: applicationForm } = data;
      yield put(setApplicationForm(applicationForm));
      yield put(success());
    } catch (error) {
      const { message }: { message: string } = error?.response?.data || error;
      const statusCode: number = error?.response?.status;
      yield put(failure({ message, statusCode }));
    }
  }
}

export default function* onCreateApplicationFormStart() {
  yield takeLatest(createApplicationFormTemplateStart, createApplicationFormTemplateFlow);
}
