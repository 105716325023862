import * as React from 'react';
import { Dropdown } from 'react-bootstrap';
import { Questions, QuestionsAction } from '../types';
import { QuestionsDispatch } from '../../show-application-form';
import { addQuestion } from '../actions';
import { nanoid } from 'nanoid';
import {
  IconShortAnswer,
  IconParagraph,
  IconCheckboxes,
  IconMultipleChoice,
  IconDropdown,
  IconDate,
  IconUploadFile,
} from 'assets/svg/icons';

export interface IAddQuestionProps {}

const onSelect = (eventKey: Questions, dispatch: React.Dispatch<QuestionsAction>) => {
  switch (eventKey) {
    case Questions.ShortAnswer:
    case Questions.Paragraph:
    case Questions.Date:
    case Questions.UploadFile:
      dispatch(addQuestion({ id: nanoid(), type: eventKey, headline: '' }));
      break;
    case Questions.MultipleChoice:
    case Questions.Checkboxes:
    case Questions.Dropdown:
      dispatch(
        addQuestion({
          id: nanoid(),
          type: eventKey,
          headline: '',
          options: [{ id: nanoid(), value: '' }],
        }),
      );
      break;
    default:
  }
};

export default function AddQuestion(props: IAddQuestionProps) {
  // const iconsColor = '#5F6368';
  const dispatch = React.useContext(QuestionsDispatch);
  return (
    <>
      <Dropdown
        className="question__add"
        drop="up"
        onSelect={(eventKey: string | null) =>
          onSelect(eventKey as Questions, dispatch as React.Dispatch<QuestionsAction>)
        }
      >
        <Dropdown.Toggle id="add-question">+ Add new question</Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item eventKey={Questions.ShortAnswer}>
            <IconShortAnswer />
            Short answer
          </Dropdown.Item>
          <Dropdown.Item eventKey={Questions.Paragraph}>
            <IconParagraph />
            Paragraph
          </Dropdown.Item>
          <Dropdown.Item eventKey={Questions.Checkboxes}>
            <IconCheckboxes />
            Checkboxes
          </Dropdown.Item>
          <Dropdown.Item eventKey={Questions.MultipleChoice}>
            <IconMultipleChoice />
            Multiple choice
          </Dropdown.Item>
          <Dropdown.Item eventKey={Questions.Dropdown}>
            <IconDropdown />
            Dropdown
          </Dropdown.Item>
          <Dropdown.Item eventKey={Questions.Date}>
            <IconDate />
            Date
          </Dropdown.Item>
          <Dropdown.Item eventKey={Questions.UploadFile}>
            <IconUploadFile />
            Upload File
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
}
