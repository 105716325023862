import * as React from 'react';

function SvgMultiplechoice(props: {}) {
  return (
    <svg width={22} height={22} {...props}>
      <path
        d="M11 5.5a5.5 5.5 0 105.5 5.5A5.447 5.447 0 0011 5.5zM11 0a11 11 0 1011 11A11.032 11.032 0 0011 0zm0 19.8a8.8 8.8 0 118.8-8.8 8.826 8.826 0 01-8.8 8.8z"
        fill="#b2b2b2"
      />
    </svg>
  );
}

export default SvgMultiplechoice;
