import api from 'services/api';
import { OrgCreateInfo } from '../../types';

export async function signupOrg(values: OrgCreateInfo) {
  const response = await api.post('api/dashboard/organizations', {
    orga: { ...values },
  });

  return response;
}
