import { createAction } from '@reduxjs/toolkit';
import { withPayloadType } from 'utils';
import { TApplicationForm, TApplicationFormDetail } from '../types';

export type UpdateApplicationFormPaylod = {
  [P in keyof QuestionsOmitted]: QuestionsOmitted[P];
} & { application_form_details: Omit<TApplicationFormDetail, 'id'>[] };

type QuestionsOmitted = Omit<TApplicationForm, 'application_form_details'>;

export const updateApplicationFormStart = createAction(
  'UPDATE_APPLICATION_FORM::START',
  withPayloadType<{
    applicationFormInfo: UpdateApplicationFormPaylod;
    organizationId: string;
  }>(),
);
