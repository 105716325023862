import * as React from 'react';
import { useContext } from 'react';
import { Button } from 'react-bootstrap';
import { TQuestion, QuestionsAction } from '../types';
import { QuestionsDispatch } from '../../show-application-form';
import { editQuestion } from '../actions';

export interface ISaveQuestionProps {
  questionUpdated: TQuestion;
  setIsEditting: React.Dispatch<React.SetStateAction<boolean>> | undefined;
  disabled: boolean;
}

export default function SaveQuestion(props: ISaveQuestionProps) {
  const { questionUpdated, setIsEditting, disabled } = props;
  const dispatch = useContext(QuestionsDispatch);
  return (
    <Button
      onClick={() => {
        (dispatch as React.Dispatch<QuestionsAction>)(editQuestion(questionUpdated));
        if (setIsEditting) {
          setIsEditting(false);
        }
      }}
      className={disabled ? 'disabled' : ''}
      variant="primary"
      disabled={disabled}
    >
      Save
    </Button>
  );
}
