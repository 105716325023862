import { combineReducers } from 'redux';

import { uploadAvatarReducer } from 'features/UploadAvatar';
import userUiReducer from 'features/user/ui.reducer';
import organizationUiReducer from 'features/organization/ui.reducer';
import authenticationUiReducer from 'features/authentication/ui.reducer';
import invitationsUiReducer from 'features/invitations/ui.reducer';
import persistSessionReducer from 'services/session/persist-user-session.slice';
import jobsReducer from 'features/jobs/ui.reducer';
import jobReducer from 'features/job/ui.reducer';

export default combineReducers({
  upload_avatar: uploadAvatarReducer,
  authentication: authenticationUiReducer,
  organization: organizationUiReducer,
  invitations: invitationsUiReducer,
  user: userUiReducer,
  jobs: jobsReducer,
  job: jobReducer,
  persistSession: persistSessionReducer,
});
