import api from 'services/api';

export async function getUploadTokens(filename: string, expires_in?: number) {
  const response = await api.post('/api/dashboard/upload-tokens', {
    upload_token: {
      filename,
      expires_in,
    },
  });

  return response;
}
