import { useRequestStates } from 'hooks';
import {
  selectIsLoading as selectCreateJobIsLoading,
  selectSuccess as selectCreateJobSuccess,
  selectError as selectCreateJobError,
} from '../slice';

export default function () {
  const {
    loading: createJobLoading,
    success: createJobSuccess,
    error: createJobError,
  } = useRequestStates(
    selectCreateJobIsLoading,
    selectCreateJobSuccess,
    selectCreateJobError,
  );

  return { createJobLoading, createJobSuccess, createJobError };
}
