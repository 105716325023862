import { IApplicationAnswer } from 'features/job/types';
import * as React from 'react';
import { Card } from 'react-bootstrap';

export interface IQuestionsAnswersProps {
  questions: IApplicationAnswer[];
}

export default function QuestionsAnswers(props: IQuestionsAnswersProps) {
  const { questions } = props;

  return (
    <Card className="application__answers">
      <Card.Header>Application answers</Card.Header>
      <Card.Body>
        {questions.map((question) => (
          <Card key={question.id}>
            {/* <Card.Header>Question-Paragraph</Card.Header> */}
            <div className="card-body pl-4 pr-4">
              <li className="aliplication__answers-question">{question.question}</li>
              <ul className="mt-1">
                {question.answer.map((answer, i) => (
                  <li style={{ listStyle: 'none', color: 'grey' }} key={i}>
                    {answer ? `- ${answer}` : null}
                  </li>
                ))}
              </ul>
            </div>
          </Card>
        ))}
      </Card.Body>
    </Card>
  );
}
