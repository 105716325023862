import { createSlice } from '@reduxjs/toolkit';

import { RootState } from 'redux/root-reducer';

export const initialState = {
  isAuthenticated: false,
};

export const authenticationSlice = createSlice({
  name: 'AUTHENTICATION',
  initialState,
  reducers: {
    setAuthenticated: (state) => {
      state.isAuthenticated = true;
    },
    removeAuthenticated: (state) => {
      state.isAuthenticated = false;
    },
  },
});

// REDUCER
export default authenticationSlice.reducer;

// ACTIONS
export const { setAuthenticated, removeAuthenticated } = authenticationSlice.actions;

// SELECTORS
export const selectIsAuthenticated = (state: RootState) =>
  state.authentication.isAuthenticated;
