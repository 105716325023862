import * as React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { FormGroup, Form as FormStrap, Button, Card, Spinner } from 'react-bootstrap';
import * as Yup from 'yup';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import HomeHeader from 'pages/home/HomeHeader';
import { useCleanup } from 'hooks';
import { OnLoginSubmit } from '../types';
import { loginStart } from './actions';
import { selectError, selectSuccess, selectIsLoading, resetToInitials } from './slice';
import { toastConfig } from 'utils';

export { onLoginStart } from './saga';
export { default as loginReducer } from './slice';

export interface ILoginProps {}

// FORM INPUT VALIDATION
const validationSchema = Yup.object({
  email: Yup.string().email('Invalid email address').required('Required'),
  password: Yup.string()
    .min(6, 'Password has to be longer than 6 characters!')
    .required('Password is required!'),
});

// eslint-disable-next-line no-unused-vars
export default function Login(props: ILoginProps) {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectIsLoading);
  const success = useSelector(selectSuccess);
  const error = useSelector(selectError);

  const onSubmit: OnLoginSubmit = (values, { resetForm }) => {
    dispatch(loginStart(values));
  };

  useCleanup(resetToInitials, error.message, success);

  if (error.message) {
    toast.error(error.message, toastConfig);
  }
  return (
    <div className="home">
      <HomeHeader />
      <div className="hero">
        <Card className="hero__content auth">
          <Card.Header>Sign In</Card.Header>
          <Card.Body>
            <Formik
              initialValues={{ email: '', password: '' }}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              <Form>
                <FormGroup controlId="email">
                  <FormStrap.Label>E-mail address</FormStrap.Label>
                  <Field
                    placeholder="Type your e-mail"
                    name="email"
                    type="email"
                    as={FormStrap.Control}
                  />
                  <ErrorMessage component="div" className="auth__error" name="email" />
                </FormGroup>
                <FormGroup>
                  <FormStrap.Label htmlFor="password">password</FormStrap.Label>
                  <Field
                    placeholder="Enter your password"
                    name="password"
                    type="password"
                    as={FormStrap.Control}
                  />
                  <ErrorMessage component="div" className="auth__error" name="password" />
                </FormGroup>
                <div className="auth__options">
                  <FormStrap.Group controlId="remeberMe">
                    <FormStrap.Check type="checkbox" label="Remeber me" />
                  </FormStrap.Group>
                  <Link className="font-weight-normal" to="/send-reset-password-email">
                    Forgot password?
                  </Link>
                </div>
                <Button
                  disabled={isLoading}
                  className="auth__btn"
                  variant="primary"
                  type="submit"
                >
                  SIGN IN
                </Button>
                {/* <Link to="/sign-up">Don't have An account?</Link> */}
                {/* <Link to="/resend-confirmation-email">Resend Confirmation Email</Link> */}
              </Form>
            </Formik>
          </Card.Body>
        </Card>
        <div className="hero__bg2 hero__bg" />
      </div>
      {isLoading && <Spinner animation="border" />}
      {error.message && <ToastContainer />}
    </div>
  );
}
