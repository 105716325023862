import { takeLatest, put, call, select } from 'redux-saga/effects';

import { OrganizationInfo } from 'redux/types';
import { selectTokens } from 'features/UploadAvatar/get-upload-tokens.slice';
import { getUploadTokensAndUploadAvatar } from 'features/UploadAvatar/upload-avatar.saga';
import { setOrganizationInfo } from '../slice';
import { loading, success, failure } from './slice';
import { updateOrganizationStart } from './actions';
import { updateOrganization } from './service';
import { Action } from '@reduxjs/toolkit';

function* updateOrganizationFlow(action: Action<unknown>) {
  if (updateOrganizationStart.match(action)) {
    const { values, image, id } = action.payload;
    try {
      yield put(loading());
      let data;
      if (image) {
        yield call(getUploadTokensAndUploadAvatar, image);
        const { file_url } = selectTokens(yield select());
        data = yield call(updateOrganization, { ...values, logo: file_url, id });
      } else {
        data = yield call(updateOrganization, { ...values, id });
      }
      const { data: organizationInfo }: { data: OrganizationInfo } = data.data;
      yield put(success());
      yield put(setOrganizationInfo(organizationInfo));
    } catch (error) {
      const { message }: { message: string } = error?.response?.data || error;
      const statusCode: number = error?.response?.status;
      yield put(failure({ message, statusCode }));
    }
  }
}

export function* onGetUploadTokensAndupdateOrganizationStart() {
  yield takeLatest(updateOrganizationStart, updateOrganizationFlow);
}
