import { takeLatest, put, call } from 'redux-saga/effects';
import { updatePipelineStart } from './update-pipeline.actions';
import { loading, success, failure } from './update-pipeline.slice';
import { updatePipeline } from './update-pipeline.service';
import { PipelineInfo } from '../../types';
import { setPipeline } from '../pipeline.slice';
import { Action } from '@reduxjs/toolkit';

function* updatePipelineFlow(action: Action<unknown>) {
  if (updatePipelineStart.match(action)) {
    try {
      yield put(loading());
      const { data } = yield call(updatePipeline, action.payload);
      const { data: pipeline }: { data: PipelineInfo } = data;
      yield put(success());
      yield put(setPipeline(pipeline));
    } catch (error) {
      const { message }: { message: string } = error?.response?.data || error;
      const statusCode: number = error?.response?.status;
      yield put(failure({ message, statusCode }));
    }
  }
}

export function* onUpdatePipelineStart() {
  yield takeLatest(updatePipelineStart, updatePipelineFlow);
}
