import * as React from 'react';
import { IStageApplication } from 'features/job/types';

export interface IApplicantProps {
  stageApplication: IStageApplication;
}

export default function Applicant(props: IApplicantProps) {
  const { stageApplication } = props;

  return (
    <div className="applicant">
      <div className="applicant__avatar" />
      <div className="applicant__text">
        <p className="applicant__name">{stageApplication.applicant.name}</p>
        <p className="applicant__summary">{stageApplication.summary}</p>
        <p className="applicant__small">applied 1 day ago</p>
        <p>{stageApplication.disqualified ? 'DISQUALIFIED' : ''}</p>
      </div>
    </div>
  );
}
