import api from 'services/api';

export async function inviteUser({
  invitationInfo,
  organizationId,
}: {
  invitationInfo: { email: string };
  organizationId: string;
}) {
  const response = await api.post(
    `api/dashboard/organizations/${organizationId}/invitations`,
    {
      invitation: {
        email: invitationInfo.email,
      },
    },
  );

  return response;
}
