import { OrganizationInfo } from '../../../../redux/types';
import { takeLatest, put, call } from 'redux-saga/effects';

import { history } from 'redux/store';
import { setOrganizationInfo } from 'features/organization/slice';
import { loading, success, failure } from './slice';
import { signupOrgStart } from './actions';
import { signupOrg } from './service';
import { Action } from '@reduxjs/toolkit';

function* signupOrgFlow(action: Action<unknown>) {
  if (signupOrgStart.match(action)) {
    try {
      yield put(loading());
      const { data } = yield call(signupOrg, action.payload);
      const { data: orgCreateInfo }: { data: OrganizationInfo } = data;
      yield put(success());
      yield put(setOrganizationInfo(orgCreateInfo));
      yield history.push('/login');
    } catch (error) {
      const { message }: { message: string } = error?.response?.data || error;
      const statusCode: number = error?.response?.status;
      yield put(failure({ message, statusCode }));
    }
  }
}

export function* onSignupOrgStart() {
  yield takeLatest(signupOrgStart, signupOrgFlow);
}
