import * as React from 'react';
import { useSelector } from 'react-redux';

import { useCleanup } from 'hooks';
import renderErrorElements from 'components/Errors/renderErrorElements';
import AvatarPreview from 'components/AvatarPreview';
import { selectTokens } from './get-upload-tokens.slice';
import {
  selectError,
  selectIsLoading,
  selectSuccess,
  resetToInitials,
} from './upload-avatar.slice';
import { Form } from 'react-bootstrap';

export { default as uploadTokensReducer } from './get-upload-tokens.slice';
export { default as uploadAvatarReducer } from './upload-avatar.slice';

interface ImageState {
  image: File | undefined;
  setImage: React.Dispatch<React.SetStateAction<File | undefined>>;
}
interface IUploadAvatarProps {
  imageState: ImageState;
}

export default function UploadAvatar(props: IUploadAvatarProps) {
  const {
    imageState: { image },
  } = props;
  const { file_url } = useSelector(selectTokens);
  const loading = useSelector(selectIsLoading);
  const success = useSelector(selectSuccess);
  const error = useSelector(selectError);

  useCleanup(resetToInitials, error.message, success);

  const handleChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
    e.persist();
    if (e.currentTarget.files !== null) {
      props.imageState.setImage(e.currentTarget.files[0]);
    }
  };

  return (
    <div>
      <Form.Group>
        <Form.File name="avatar" accept="image/*" onChange={handleChange} />
      </Form.Group>

      {image ? <AvatarPreview image={image} /> : ''}
      <div>{loading ? 'loading' : null}</div>
      <div>{success || null}</div>
      <div>{success && <img src={file_url} alt="avatar" />}</div>
      {renderErrorElements(error.message)}
    </div>
  );
}
