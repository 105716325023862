import api from 'services/api';

export async function createDepartment({
  departmentName,
  organizationId,
}: {
  departmentName: string;
  organizationId: string;
}) {
  const response = await api.post(
    `api/dashboard/organizations/${organizationId}/departments`,
    {
      department: {
        name: departmentName,
      },
    },
  );
  return response;
}
