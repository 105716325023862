import { combineReducers } from 'redux';

import { inviteUserReducer } from './invite-user';
import { listInvitationsReducer } from './list-invitations';
import { acceptInvitationReducer } from './accept-invitation';
import { rejectInvitationReducer } from './reject-invitation';
import { revokeInvitationReducer } from './revoke-invitation';
import { showInvitationReducer } from './show-invitation';

export default combineReducers({
  inviteUser: inviteUserReducer,
  acceptInvitation: acceptInvitationReducer,
  rejectInvitation: rejectInvitationReducer,
  revokeInvitation: revokeInvitationReducer,
  listInvitations: listInvitationsReducer,
  showInvitaion: showInvitationReducer,
});
