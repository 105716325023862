import api from 'services/api';
import { UserInfo } from 'redux/types';

export async function signupEmployee(values: UserInfo) {
  const response = await api.post('api/auth/signup', {
    user: { ...values },
  });

  return response;
}
