import { listDepartmentsStart } from '../list-departments/actions';
import { takeLatest, call, put } from 'redux-saga/effects';

import { loading, success, failure } from './slice';
import { deleteDepartmentStart } from './actions';
import { deleteDepartment } from './service';
import { history } from 'redux/store';
import { Action } from '@reduxjs/toolkit';

function* deleteDepartmentFlow(action: Action<unknown>) {
  if (deleteDepartmentStart.match(action)) {
    try {
      yield put(loading());
      yield call(deleteDepartment, action.payload);
      yield put(success());
      yield put(listDepartmentsStart({ organizationId: action.payload.organizationId }));
      yield history.push(history.location);
    } catch (error) {
      const { message }: { message: string } = error?.response?.data || error;
      const statusCode: number = error?.response?.status;
      yield put(failure({ message, statusCode }));
    }
  }
}

export function* onDeleteDepartmentStart() {
  yield takeLatest(deleteDepartmentStart, deleteDepartmentFlow);
}
