import * as React from 'react';

function SvgDate(props: {}) {
  return (
    <svg width={22.2} height={20.492} {...props}>
      <defs>
        <style>{'.Date_svg__a{fill:#b2b2b2}'}</style>
      </defs>
      <path
        className="Date_svg__a"
        d="M20.066 1.708h-2.135v1.281a.428.428 0 01-.427.427h-.854a.428.428 0 01-.427-.427V1.708H5.977v1.281a.428.428 0 01-.427.427h-.854a.428.428 0 01-.427-.427V1.708H2.135A2.141 2.141 0 000 3.843v14.515a2.141 2.141 0 002.135 2.135h17.931a2.141 2.141 0 002.134-2.135V3.843a2.141 2.141 0 00-2.134-2.135zm.427 16.01a1.07 1.07 0 01-1.068 1.067H2.775a1.07 1.07 0 01-1.067-1.067V8.112a.428.428 0 01.427-.427h17.931a.428.428 0 01.427.427zM5.977.427A.428.428 0 005.55 0h-.854a.428.428 0 00-.427.427v1.281h1.708zM17.931.427A.428.428 0 0017.504 0h-.854a.428.428 0 00-.427.427v1.281h1.708z"
      />
    </svg>
  );
}

export default SvgDate;
