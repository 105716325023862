import * as React from 'react';
import { useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Card, Button } from 'react-bootstrap';
import { StageInfo } from '../../types';
import { selectPipeline, addStage } from '../pipeline.slice';
import { selectSuccess } from './show-pipeline.slice';
import { nanoid } from 'nanoid';
import UpdatePipeline from '../update-pipeline';
import LeavePage from 'features/job/create-job/components/LeavePage';
import { WorkflowModalContext, IsDirtyContext } from 'features/job/create-job';
import PipelineStages from '../components/PipelineStages';

export { default as showPipelineReducer } from './show-pipeline.slice';

interface DeletedStages {
  deletedStages: StageInfo[];
  setDeletedStages: React.Dispatch<React.SetStateAction<StageInfo[]>>;
}

export const DeletedStagesContex = React.createContext<Partial<DeletedStages>>({});

export interface IShowPipelineProps {}

export default function ShowPipeline(props: IShowPipelineProps) {
  const stages = useSelector(selectPipeline).info?.stages;
  const dispatch = useDispatch();
  const success = useSelector(selectSuccess);
  const [deletedStages, setDeletedStages] = React.useState<StageInfo[]>([]);
  const [saveDefaultChecked, setSaveDefaultChecked] = React.useState(false);
  const { isDirty } = useContext(IsDirtyContext);
  const { showWorkflowModal, setShowWorkflowModal } = useContext(WorkflowModalContext);

  const onCancel = () => {
    if (setShowWorkflowModal) setShowWorkflowModal(false);
  };

  // const onSave = async (submitForm: (() => Promise<void>) & (() => Promise<any>)) => {
  //   await submitForm();
  //   if (setDirty) setDirty(false);
  // };

  return (
    <>
      {isDirty && showWorkflowModal && (
        <LeavePage handleCancel={onCancel} handleSave={() => 1} show={true} />
      )}
      {success && stages && (
        <DeletedStagesContex.Provider value={{ deletedStages, setDeletedStages }}>
          <Card>
            <Card.Body>
              <Card.Title>Pipeline</Card.Title>
              <label>
                <input
                  type="checkbox"
                  checked={saveDefaultChecked}
                  onChange={() => setSaveDefaultChecked(!saveDefaultChecked)}
                />{' '}
                Save as default
              </label>
              <Card.Subtitle>
                Customize pipeline and manage your stages in your hiring process.
              </Card.Subtitle>
              <PipelineStages />
              <div className="btns-space-between">
                <Button
                  onMouseUp={() =>
                    dispatch(
                      addStage({
                        name: '',
                        position: stages.length + 1,
                        draggableId: nanoid().toString(),
                        kind: 'custom',
                      }),
                    )
                  }
                >
                  + Add new stage
                </Button>

                <UpdatePipeline saveDefault={saveDefaultChecked} />
              </div>
            </Card.Body>
          </Card>
        </DeletedStagesContex.Provider>
      )}
    </>
  );
}
