import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RenderingState, ErrorInfo } from 'redux/types';
import { RootState } from 'redux/root-reducer';

const initialState: RenderingState = {
  isLoading: false,
  success: false,
  error: {
    message: '',
    statusCode: null,
  },
};

const updateJobSlice = createSlice({
  name: 'UPDATE_JOB',
  initialState,
  reducers: {
    loading: (state) => {
      state.isLoading = true;
    },
    success: (state) => {
      state.isLoading = false;
      state.success = true;
      state.error = initialState.error;
    },
    failure: (state, action: PayloadAction<ErrorInfo>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    resetToInitials: (state) => {
      state.isLoading = false;
      state.success = false;
      state.error = {
        message: '',
        statusCode: null,
      };
    },
  },
});

//REDUCER
export default updateJobSlice.reducer;

// ACTIONS
export const { loading, success, failure, resetToInitials } = updateJobSlice.actions;

/// SELECTORS
export const selectIsLoading = (state: RootState) => state.ui.job.update.isLoading;

export const selectSuccess = (state: RootState) => state.ui.job.update.success;

export const selectError = (state: RootState) => state.ui.job.update.error;
