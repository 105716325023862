import { takeLatest, call, put } from 'redux-saga/effects';
import { InvitationInfo } from 'redux/types';

import { setInvitationInfo } from '../invitation.slice';
import { loading, success, failure } from './slice';
import { showInvitationStart } from './actions';
import { getInvitation } from './service';
import { Action } from '@reduxjs/toolkit';

function* showInvitationFlow(action: Action<unknown>) {
  if (showInvitationStart.match(action)) {
    try {
      yield put(loading());
      const { data } = yield call(getInvitation, action.payload);
      const { data: invitationInfo }: { data: InvitationInfo } = data;
      yield put(success());
      yield put(setInvitationInfo(invitationInfo));
    } catch (error) {
      const { message }: { message: string } = error?.response?.data || error;
      const statusCode: number = error?.response?.status;
      yield put(failure({ message, statusCode }));
    }
  }
}

export function* onShowInvitationStart() {
  yield takeLatest(showInvitationStart, showInvitationFlow);
}
