import * as React from 'react';
import { Card, Form } from 'react-bootstrap';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Field, FieldProps } from 'formik';

export interface IJobDescriptionProps {}

export default function JobDescription(props: IJobDescriptionProps) {
  const toolBarConfig = [
    [
      'bold',
      'italic',
      'underline',
      'strike',
      { list: 'ordered' },
      { list: 'bullet' },
      { direction: 'rtl' },
      { header: [1, 2, 3, 4, 5, 6, false] },
      // { font: [] },
    ],

    // [{ align: [] }],
    // [{ header: 1 }, { header: 2 }], // custom button values
    // [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
    // ['blockquote', 'code-block'],
    // [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
    // [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    // ['clean'],
  ];

  return (
    <Card>
      <Card.Body>
        <Card.Title>Job Description</Card.Title>
        <Form.Group className="input-element mb-3 mr-0">
          <Form.Label>Description</Form.Label>
          <Field name="jobDescription">
            {({ field, form, meta }: FieldProps) => (
              <>
                <ReactQuill
                  onChange={(value) => form.setFieldValue('jobDescription', value)}
                  value={field.value}
                  modules={{ toolbar: toolBarConfig }}
                />
                {meta.touched && meta.error && <div className="error">{meta.error}</div>}
              </>
            )}
          </Field>
        </Form.Group>
        <Form.Group className="input-element">
          <Form.Label>Requirements</Form.Label>
          <Field name="jobRequirements">
            {({ field, form }: FieldProps) => (
              <>
                <ReactQuill
                  onChange={(value) => form.setFieldValue('jobRequirements', value)}
                  value={field.value}
                  modules={{ toolbar: toolBarConfig }}
                />
              </>
            )}
          </Field>
        </Form.Group>
      </Card.Body>
    </Card>
  );
}
