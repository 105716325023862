import * as React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ShowApplicationForm from './show-application-form';
import { toast, ToastContainer } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { showApplicationFormStart } from './show-application-form/actions';
import { selectIsLoading, selectError } from './show-application-form/slice';
import { selectJobDetails } from '../job-details/job-details.slice';
import { selectApplicationForm } from './slice';
import { Spinner } from 'react-bootstrap';
import { toastConfig } from 'utils';
import { useOrganizationId } from 'hooks';

export { default as applicationFormReducer } from './slice';

export interface IApplicationFormProps {}

export default function ApplicationForm(props: IApplicationFormProps) {
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const jobDetails = useSelector(selectJobDetails);
  const applicationForm = useSelector(selectApplicationForm);
  const isLoading = useSelector(selectIsLoading);
  const error = useSelector(selectError);
  const organizationId = useOrganizationId();

  React.useEffect(() => {
    if (applicationForm.info) return;
    if (organizationId) {
      dispatch(
        showApplicationFormStart({
          id: jobDetails!.application_form_id!,
          organizationId,
        }),
      );
    }
  }, [dispatch, jobDetails, applicationForm, organizationId]);

  if (isLoading) return <Spinner animation="border" />;
  if (error.message) {
    toast.error(error.message, toastConfig);
  }

  return (
    <div className="container">
      <ShowApplicationForm />
      <div className="btns-space-between">
        <Link
          className="btn-prev"
          to={`${match.url.replace('application-form', 'job-details')}`}
        >
          <FontAwesomeIcon icon="chevron-circle-left" size="lg" />{' '}
          <span>Job Details</span>
        </Link>
        <Link
          className="btn-next"
          to={`${match.url.replace('application-form', 'workflow')}`}
        >
          <span>Workflow</span> <FontAwesomeIcon icon="chevron-circle-right" size="lg" />
        </Link>
      </div>
      {error.message && <ToastContainer />}
    </div>
  );
}
