import { takeLatest, put, call } from 'redux-saga/effects';
import { acceptInvitationStart } from './actions';
import { loading, success, failure } from './slice';
import { acceptInvitation } from './service';
import { Action } from '@reduxjs/toolkit';

function* acceptInvitationFlow(action: Action<unknown>) {
  if (acceptInvitationStart.match(action)) {
    try {
      yield put(loading());
      yield call(acceptInvitation, action.payload);
      yield put(success());
    } catch (error) {
      const { message }: { message: string } = error?.response?.data || error;
      const statusCode: number = error?.response?.status;
      yield put(failure({ message, statusCode }));
    }
  }
}

export function* onAcceptInvitationStart() {
  yield takeLatest(acceptInvitationStart, acceptInvitationFlow);
}
