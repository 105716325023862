import api from 'services/api';

export async function updateApplication(payload: {
  jobId: string;
  stageId: string;
  newStageId?: string | null;
  applicationId: string | undefined;
  disqualified?: boolean;
  organizationId: string;
}) {
  const {
    jobId,
    stageId,
    newStageId,
    applicationId,
    disqualified,
    organizationId,
  } = payload;
  return api.put(
    `api/dashboard/organizations/${organizationId}/jobs/${jobId}/stages/${stageId}/applications/${applicationId}`,
    {
      application: {
        disqualified,
        stage_id: newStageId,
      },
    },
  );
}
