import * as React from 'react';
import { Button, Navbar } from 'react-bootstrap';

import SearchBar from 'components/SearchBar';
import ProfileDropdown from 'components/ProfileDropdown';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectIsEditorMode,
  toggleEditorOn,
  toggleEditorOff,
} from 'features/organization/site-editor/editor.slice';
import { useCurrentOrganization, useOrganizationSlug } from 'hooks';

export interface ITopNavigationProps {
  hasOrganization?: boolean;
}

export default function TopNavigation(props: ITopNavigationProps) {
  const { hasOrganization } = props;
  const location = useLocation();
  const organization = useCurrentOrganization();
  const dispatch = useDispatch();
  const isEditorMode = useSelector(selectIsEditorMode);
  const organizationSlug = useOrganizationSlug();

  React.useEffect(() => {
    if (location.pathname.includes('career-page/editor')) dispatch(toggleEditorOn());
    return function () {
      dispatch(toggleEditorOff());
    };
  }, [location.pathname, dispatch]);

  return (
    <>
      {!isEditorMode && (
        <div className="top-nav container">
          <Navbar className="top-nav__content justify-content-between">
            <Navbar.Brand className="top-nav__brand">
              {hasOrganization ? (
                <Link to={`/dashboard/${organizationSlug}/jobs`}>
                  {organization?.name.toUpperCase()}
                </Link>
              ) : (
                <Link to="/dashboard" className="home__logo">
                  Hireable
                </Link>
              )}
            </Navbar.Brand>
            <div className="top-nav__right">
              <SearchBar />
              <Link className="mr-4" component={Button} to={`/admin/create-organization`}>
                Create Organization
              </Link>
              <ProfileDropdown />
            </div>
          </Navbar>
        </div>
      )}
    </>
  );
}
