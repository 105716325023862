import { useRequestStates } from 'hooks';
import {
  selectIsLoading as selectDeleteDepartmentIsLoading,
  selectSuccess as selectDeleteDepartmentSuccess,
  selectError as selectDeleteDepartmentError,
} from '../delete-department/slice';

import {
  selectIsLoading as selectCreateDepartmentIsLoading,
  selectSuccess as selectCreateDepartmentSuccess,
  selectError as selectCreateDepartmentError,
} from '../create-department/slice';

import {
  selectIsLoading as selectUpdateDepartmentIsLoading,
  selectSuccess as selectUpdateDepartmentSuccess,
  selectError as selectUpdateDepartmentError,
} from '../update-department/slice';

export function useCreateDepartmentRequestStates() {
  const {
    loading: createDepartmentLoading,
    success: createDepartmentSuccess,
    error: createDepartmentError,
  } = useRequestStates(
    selectCreateDepartmentIsLoading,
    selectCreateDepartmentSuccess,
    selectCreateDepartmentError,
  );

  return { createDepartmentLoading, createDepartmentSuccess, createDepartmentError };
}

export function useDeleteDepartmentRequestStates() {
  const {
    loading: deleteDepartmentLoading,
    success: deleteDepartmentSuccess,
    error: deleteDepartmentError,
  } = useRequestStates(
    selectDeleteDepartmentIsLoading,
    selectDeleteDepartmentSuccess,
    selectDeleteDepartmentError,
  );

  return { deleteDepartmentLoading, deleteDepartmentSuccess, deleteDepartmentError };
}

export function useUpdateDepartmentRequestStates() {
  const {
    loading: updateDepartmentLoading,
    success: updateDepartmentSuccess,
    error: updateDepartmentError,
  } = useRequestStates(
    selectUpdateDepartmentIsLoading,
    selectUpdateDepartmentSuccess,
    selectUpdateDepartmentError,
  );

  return { updateDepartmentLoading, updateDepartmentSuccess, updateDepartmentError };
}
