import { combineReducers } from 'redux';
import { loginReducer } from './login';
import { logoutReducer } from './logout';
import { signupEmployeeReducer } from './sign_up/signup-employee';
import { resetPasswordReducer } from './reset-password';
import { sendResetPassEmailReducer } from './send-reset-password-email';
import { resendConfirmationEmailReducer } from './resend-confirmation-email';
import { signupOrgReducer } from './sign_up/signup-org';

export default combineReducers({
  login: loginReducer,
  logout: logoutReducer,
  signupEmployee: signupEmployeeReducer,
  signupOrg: signupOrgReducer,
  resetPassword: resetPasswordReducer,
  sendResetPassEmail: sendResetPassEmailReducer,
  resendConfirmationEmail: resendConfirmationEmailReducer,
});
