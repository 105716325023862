import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'redux/root-reducer';
import { TJob } from 'features/job/types';

const initialState: { info: TJob | null } = {
  info: null,
};

const jobDetailsSlice = createSlice({
  name: 'JOB',
  initialState,
  reducers: {
    setJobDetails: (state, action: PayloadAction<TJob>) => {
      state.info = action.payload;
    },
    resetJobDetails: (state) => {
      state.info = null;
    },
  },
});

// REDUCER
export default jobDetailsSlice.reducer;

// ACTIONS
export const { setJobDetails, resetJobDetails } = jobDetailsSlice.actions;

// SELECTORS
export const selectJobDetails = (state: RootState) => state.job.deatails.info;
