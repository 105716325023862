import { combineReducers } from 'redux';
import { showOrganizationReducer } from './show-organization';
import { createOrganizationReducer } from './create-organization';
import { updateOrganizationReducer } from './update-organizatoin';
import departmentsUiReducer from './departments/ui.reducer';
import templatesUiReducer from './pipeline-templates/ui.reducer';
import sectionsUiReducer from './site-editor/sections-ui.reducer';
import editorReducer from './site-editor/editor.slice';
import candidatesUiReducer from './candidates/ui.reducer';

export default combineReducers({
  showOrganization: showOrganizationReducer,
  createOrganization: createOrganizationReducer,
  updateOrganization: updateOrganizationReducer,
  departments: departmentsUiReducer,
  templates: templatesUiReducer,
  sections: sectionsUiReducer,
  editor: editorReducer,
  candidates: candidatesUiReducer,
});
