import { call, put, select } from 'redux-saga/effects';
import { uploadLoading, uploadSuccess, uploadFailure } from './upload-avatar.slice';
import { uploadAvatarToUrl } from './upload-avatar.service';
import { selectTokens } from './get-upload-tokens.slice';
import { getUploadTokensFlow } from './get-upload-tokens.saga';

export function* uploadAvatarFlow(file: File, upload_url: string) {
  try {
    yield put(uploadLoading());
    const { status } = yield call(uploadAvatarToUrl, file, upload_url);
    if (status === 200) {
      yield put(uploadSuccess());
    }
  } catch (error) {
    const { message }: { message: string } = error?.response?.data || error;
    const statusCode: number = error?.response?.status;
    yield put(uploadFailure({ message, statusCode }));
  }
}

export function* getUploadTokensAndUploadAvatar(image: {
  file: File;
  expires_in?: number;
}) {
  try {
    yield call(getUploadTokensFlow, image.file.name, image.expires_in);
    const { upload_url } = selectTokens(yield select());
    if (upload_url) {
      yield call(uploadAvatarFlow, image.file, upload_url);
    }
  } catch (error) {
    // eslint-disable-next-line no-console
  }
}
