import {
  TQuestion,
  QuestionOption,
  SetQuestionOptionsAction,
  EditQuestionAction,
} from './types';
import { AddQuestionAction, RemoveQuestionAction, SetQuestionsAction } from './types';

export const setQuestions = (payload: TQuestion[]): SetQuestionsAction => ({
  type: 'SET_QUESTIONS',
  payload,
});

export const addQuestion = (payload: TQuestion): AddQuestionAction => ({
  type: 'ADD_QUESTION',
  payload,
});

export const removeQuestion = (payload: { id: string }): RemoveQuestionAction => ({
  type: 'REMOVE_QUESTION',
  payload,
});

export const editQuestion = (payload: TQuestion): EditQuestionAction => ({
  type: 'EDIT_QUESTION',
  payload,
});

export const setQuestionOptions = (payload: {
  questionId: string;
  options: QuestionOption[];
}): SetQuestionOptionsAction => ({
  type: 'SET_QUESTION_OPTIONS',
  payload,
});
