import api from 'services/api';
import { UpdatePipelinePayload } from './update-pipeline.actions';

export async function updatePipeline({
  pipelineInfo,
  organizationId,
}: {
  pipelineInfo: UpdatePipelinePayload;
  organizationId: string;
}) {
  const { stages_attributes, pipelineId } = pipelineInfo;
  const response = api.put(
    `api/dashboard/organizations/${organizationId}/pipelines/${pipelineId}`,
    {
      pipeline: {
        stages_attributes,
      },
    },
  );
  return response;
}
