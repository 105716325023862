import { takeLatest, put, call } from 'redux-saga/effects';
import { createJobStart } from './actions';
import { loading, success, failure } from './slice';
import { createJob } from './service';
import { setJobDetails } from './create-job-stages/job-details/job-details.slice';
import { Action } from '@reduxjs/toolkit';

function* createJobFlow(action: Action<unknown>) {
  if (createJobStart.match(action)) {
    try {
      yield put(loading());
      const { data } = yield call(createJob, action.payload);
      const { data: jobDetails } = data;
      yield put(success());
      yield put(setJobDetails(jobDetails));
    } catch (error) {
      const { message }: { message: string } = error?.response?.data || error;
      const statusCode: number = error?.response?.status;
      yield put(failure({ message, statusCode }));
    }
  }
}

export default function* onCreateJobStart() {
  yield takeLatest(createJobStart, createJobFlow);
}
