import * as React from 'react';
import { Card, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { selectFilters } from '../slice';
import FilterRadio from './FilterRadio';
import FilterCheckbox from './FlilterCheckbox';

export interface IFiltersProps {}

export default function Filters(props: IFiltersProps) {
  const filters = useSelector(selectFilters);

  return (
    <Col lg={3}>
      <Card>
        <Card.Header style={{ fontWeight: 500 }}>Filter</Card.Header>
        <Card.Body style={{ padding: '20px 20px' }}>
          {filters?.map((filter) =>
            filter.metadata.type === 'checkbox' ? (
              <FilterCheckbox filter={filter} key={filter.metadata.field} />
            ) : (
              <FilterRadio filter={filter} key={filter.metadata.field} />
            ),
          )}
        </Card.Body>
      </Card>
    </Col>
  );
}
