import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'redux/root-reducer';
import { DepartmentInfo } from './types';

const initialState: { info: DepartmentInfo[] | null } = {
  info: null,
};

const departmentsSlice = createSlice({
  name: 'DEPARTMENTS',
  initialState,
  reducers: {
    setDepartmentsInfo: (state, action: PayloadAction<DepartmentInfo[]>) => {
      state.info = action.payload;
    },
    resetDepartmentsInfo: (state) => {
      state.info = initialState.info;
    },
  },
});

// REDUCER
export default departmentsSlice.reducer;

// ACTIONS
export const { setDepartmentsInfo, resetDepartmentsInfo } = departmentsSlice.actions;

// SELECTORS
export const selectDepartmentsInfo = (state: RootState) =>
  state.organization.departments.info;
