import * as React from 'react';
import { Modal, Button } from 'react-bootstrap';

export interface ILeavePageProps {
  handleSave: () => void;
  handleCancel: () => void;
  show: boolean;
}

export default function LeavePage(props: ILeavePageProps) {
  const { show, handleSave, handleCancel } = props;

  return (
    <>
      <Modal show={show} onHide={handleCancel}>
        <Modal.Header closeButton>
          <Modal.Title>Leave Page</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to leave this page? Changes you made may not be saved.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={handleCancel}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
