import { call, put, takeLatest } from 'redux-saga/effects';

import { history } from 'redux/store';
import { setUserInfo } from 'features/user/slice';
import { UserInfo } from 'redux/types';
import { getUserInfo } from './service';
import { loading, success, failure } from './slice';
import { showUserStart } from './actions';
import { Action } from '@reduxjs/toolkit';
import { setAuthenticated } from 'features/authentication/slice';

function* showUser(action: Action<unknown>) {
  if (showUserStart.match(action)) {
    try {
      yield put(loading());
      const { data } = yield call(getUserInfo);
      const { data: userInfo }: { data: UserInfo } = data;
      yield put(success());
      yield put(setUserInfo(userInfo));
      yield put(setAuthenticated());
      if (userInfo.organizations && userInfo.organizations[0]) {
        yield history.push('/dashboard');
      } else {
        yield history.push('/admin/create-organization');
      }
    } catch (error) {
      const { message }: { message: string } = error?.response?.data || error;
      const statusCode: number = error?.response?.status;
      yield put(failure({ message, statusCode }));
    }
  }
}

export function* onShowUserSatrt() {
  yield takeLatest(showUserStart, showUser);
}
