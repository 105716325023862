import { createAction } from '@reduxjs/toolkit';
import { StageInfo } from '../../types';
import { withPayloadType } from 'utils';

export interface CreatePipelineTemplatePayload {
  save_as_default: 'true';
  stages_attributes: StageInfo[];
}

export const createPipelineTemplateStart = createAction(
  'CREATE_PIPELINE_TEMPLATE::START',
  withPayloadType<{
    pipelineInfo: CreatePipelineTemplatePayload;
    organizationId: string;
  }>(),
);
