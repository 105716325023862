import * as React from 'react';

function SvgDragicon(props: {}) {
  return (
    <svg width={14.823} height={12} {...props}>
      <path
        d="M1.059 0a1.059 1.059 0 100 2.118h12.706a1.059 1.059 0 100-2.118zm0 4.941a1.059 1.059 0 000 2.118h12.706a1.059 1.059 0 000-2.118zm0 4.941a1.059 1.059 0 000 2.118h12.706a1.059 1.059 0 000-2.118z"
        fill="#bebebe"
      />
    </svg>
  );
}

export default SvgDragicon;
