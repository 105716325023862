import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { StageInfo, PipelineInfo } from '../types';
import { RootState } from 'redux/root-reducer';
import { nanoid } from 'nanoid';

const initialState: { info: PipelineInfo | null } = {
  info: null,
};

const pipelineSlice = createSlice({
  name: 'PIPELINE',
  initialState,
  reducers: {
    setPipeline: (state, action: PayloadAction<PipelineInfo>) => {
      state.info = action.payload;
      state.info.stages = state.info.stages
        .sort((a, b) => a.position - b.position)
        .map((stage) => {
          if (stage.draggableId) return stage;
          return { ...stage, draggableId: nanoid().toString() };
        });
    },
    addStage: (state, action: PayloadAction<StageInfo>) => {
      state.info!.stages.push(action.payload);
    },
    removeStage: (state, action: PayloadAction<{ id: string }>) => {
      state.info!.stages = state
        .info!.stages.filter((stage) => stage.draggableId !== action.payload.id)
        .map((stage, index) => ({ ...stage, position: index }));
    },
    editStageName: (state, action: PayloadAction<{ name: string; id: string }>) => {
      state.info!.stages = state.info!.stages.map((stage) => {
        if (stage.draggableId === action.payload.id) {
          return { ...stage, name: action.payload.name };
        } else {
          return stage;
        }
      });
    },
    resetPipeline: (state) => {
      state.info = null;
    },
  },
});

// REDUCER
export default pipelineSlice.reducer;

// ACTIONS
export const {
  setPipeline,
  addStage,
  removeStage,
  editStageName,
  resetPipeline,
} = pipelineSlice.actions;

// SELECTORS
export const selectPipeline = (state: RootState) => state.job.pipeline;
