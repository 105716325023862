import * as React from 'react';
import { useContext } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { TQuestionWithOptions } from '../../types';
import { IconDrag } from 'assets/svg/icons';
import { Card, ButtonGroup } from 'react-bootstrap';
import { EdittingStateContext } from './QuestionWithOptions';
import EditQuestion from '../EditQuestion';
import DeleteQuestion from '../DeleteQuestion';

export interface IQuestionWithOptionsSavedProps {
  question: TQuestionWithOptions;
  index: number;
}

export default function QuestionWithOptionsSaved(props: IQuestionWithOptionsSavedProps) {
  const { question, index } = props;
  const { setIsEditting } = useContext(EdittingStateContext);
  return (
    <Draggable draggableId={question.id} index={index}>
      {(provided) => (
        <div
          className="card question-container"
          ref={provided.innerRef}
          {...provided.draggableProps}
        >
          <Card.Body className="question__body">
            <Card.Title className="question__header">
              <div className="drag-handle" {...provided.dragHandleProps}>
                <IconDrag></IconDrag>
              </div>
              {`Question (${question.type.split('_').join(' ').toLowerCase()})`}
            </Card.Title>
            <p className="question__headline">{question.headline}</p>
            <p className="question__headline">Answers</p>
            <ul className="question__answers">
              {question.options?.map((option, i) => (
                <li key={i}>{option.value}</li>
              ))}
            </ul>
            <ButtonGroup className="question__btns">
              <EditQuestion setIsEditting={setIsEditting} />
              <DeleteQuestion questionId={question.id} />
            </ButtonGroup>
          </Card.Body>
        </div>
      )}
    </Draggable>
  );
}
