import { all, call } from 'redux-saga/effects';
import { onLoginStart } from './login';
import { onLogoutStart } from './logout/saga';
import { onResenConfirmationEmailStart } from './resend-confirmation-email';
import { onSendResetPassEmailStart } from './send-reset-password-email';
import { onResetPasswordStart } from './reset-password';
import { onSignupEmployeeStart } from './sign_up/signup-employee';
import { onSignupOrgStart } from './sign_up/signup-org';

// USER AUTH FLOW
export default function* onAuthentication() {
  yield all([
    call(onLoginStart),
    call(onSignupEmployeeStart),
    call(onSignupOrgStart),
    call(onLogoutStart),
    call(onResenConfirmationEmailStart),
    call(onSendResetPassEmailStart),
    call(onResetPasswordStart),
  ]);
}
