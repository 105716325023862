import Axios from 'axios';

export async function uploadAvatarToUrl(avatar: File, upload_url: string) {
  const formData = new FormData();
  formData.append('image', avatar, avatar.name);
  const response = await Axios({
    method: 'PUT',
    url: upload_url,
    data: avatar,
  });

  return response;
}
