import api from 'services/api';
import { updateJobValues } from './actions';

export async function updateJob({ jobDetails, jobId, organizationId }: updateJobValues) {
  const response = api.put(
    `api/dashboard/organizations/${organizationId}/jobs/${jobId}`,
    {
      job: {
        status: jobDetails.status,
        title: jobDetails.jobTitle,
        department: jobDetails.department,
        description: jobDetails.jobDescription,
        requirments: jobDetails.jobRequirements,
        country: jobDetails.location?.country?.value,
        city: jobDetails.location?.city?.value,
        zip_code: jobDetails.location?.zipCode,
        employment_type: jobDetails.employment?.employmentType?.value,
        education: jobDetails.employment?.education?.value,
        experience: jobDetails.employment?.experience?.value,
        keywords: jobDetails.employment?.keywords,
        salary_from: jobDetails.salaryDetails?.from,
        salary_to: jobDetails.salaryDetails?.to,
        salary_currency: jobDetails.salaryDetails?.currency?.value,
      },
    },
  );
  return response;
}
