import * as React from 'react';

export default function renderErrorsElement(errorMessage: string) {
  const errorsElement: JSX.Element[] = [];
  if (errorMessage) {
    const errors = errorMessage.split(',');
    for (const error of errors) {
      errorsElement.push(<h1>{error}</h1>);
    }
  }
  return errorsElement.map((error, i) => <div key={i}>{error}</div>);
}
