import * as React from 'react';
import * as Sentry from '@sentry/browser';

function ReportButton({ eventId }: { eventId: string | null }) {
  return (
    <button
      type="button"
      onClick={() => (eventId ? Sentry.showReportDialog({ eventId }) : null)}
    >
      Report feedback
    </button>
  );
}

export interface IErrorBoundaryProps {
  children?: any;
}

export interface IErrorBoundaryState {
  eventId: string | null;
  hasError: boolean;
}

export default class ErrorBoundary extends React.Component<
  IErrorBoundaryProps,
  IErrorBoundaryState
> {
  constructor(props: IErrorBoundaryProps) {
    super(props);

    this.state = { eventId: null, hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, info: any) {
    Sentry.withScope((scope) => {
      scope.setExtras(info);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId });
    });
    // eslint-disable-next-line no-console
    console.log(error);
  }

  public render() {
    const { children } = this.props;
    const { hasError, eventId } = this.state;

    return <div>{hasError ? <ReportButton eventId={eventId} /> : children}</div>;
  }
}
