import api from 'services/api';
import { CreatePipelineTemplatePayload } from './create-pipeline-template.actions';

export async function createPipelineTemplate({
  pipelineInfo,
  organizationId,
}: {
  pipelineInfo: CreatePipelineTemplatePayload;
  organizationId: string;
}) {
  const { stages_attributes, save_as_default } = pipelineInfo;
  const response = api.post(`api/dashboard/organizations/${organizationId}/pipelines`, {
    pipeline: {
      save_as_default,
      stages_attributes,
    },
  });
  return response;
}
