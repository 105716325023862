import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'redux/root-reducer';
import { ErrorInfo, RenderingState } from 'redux/types';

export const initialState: RenderingState = {
  isLoading: false,
  success: false,
  error: {
    message: '',
    statusCode: null,
  },
};

const createApplicationFormTemplateSlice = createSlice({
  name: 'CREATE_APPLICATION_FORM_TEMPLATE',
  initialState,
  reducers: {
    loading: (state) => {
      state.isLoading = true;
    },
    success: (state) => {
      state.isLoading = false;
      state.success = true;
      state.error.message = '';
      state.error.statusCode = null;
    },
    failure: (state, action: PayloadAction<ErrorInfo>) => {
      state.isLoading = false;
      state.error.message = action.payload.message;
      state.error.statusCode = action.payload.statusCode;
    },
  },
});

// REDECER
export default createApplicationFormTemplateSlice.reducer;

// ACTIONS
export const { loading, success, failure } = createApplicationFormTemplateSlice.actions;

// SELECTORS
export const selectIsLoading = (state: RootState) =>
  state.ui.job.create.applicationForm.createTemplate.isLoading;
export const selectSuccess = (state: RootState) =>
  state.ui.job.create.applicationForm.createTemplate.success;
export const selectError = (state: RootState) =>
  state.ui.job.create.applicationForm.createTemplate.error;
