import * as React from 'react';
import { useState, createContext } from 'react';
import { TQuestionNoOptions, EdittingState } from '../../types';
import QuestionNoOptionsSaved from './QuestionNoOptionsSaved';
import QuestionNoOptionsEditting from './QuestionNoOptionsEditting';

export interface IQuestionNoOptionsProps {
  question: TQuestionNoOptions;
  index: number;
  newQuestion: boolean;
}

export const EdittingStateContext = createContext<Partial<EdittingState>>({});

export default function QuestionNoOptions(props: IQuestionNoOptionsProps) {
  const { question, index, newQuestion } = props;
  const [isEditting, setIsEditting] = useState<boolean>(false);

  return (
    <EdittingStateContext.Provider value={{ isEditting, setIsEditting }}>
      {isEditting || newQuestion ? (
        <QuestionNoOptionsEditting
          question={question}
          index={index}
          newQuestion={newQuestion}
        />
      ) : (
        <QuestionNoOptionsSaved question={question} index={index} />
      )}
    </EdittingStateContext.Provider>
  );
}
