import { call, all } from 'redux-saga/effects';

import { onGetUploadTokensAndCreateOrganizationStart } from './create-organization';
import { onGetUploadTokensAndupdateOrganizationStart } from './update-organizatoin';
import { onGetOrganizationStart } from './show-organization';
import onDepartmentsAction from './departments/saga';
import onTemplatesAction from './pipeline-templates/saga';
import onCandidatesAction from './candidates/saga';

export default function* onOrganizationAction() {
  yield all([
    call(onGetUploadTokensAndCreateOrganizationStart),
    call(onGetUploadTokensAndupdateOrganizationStart),
    call(onGetOrganizationStart),
    call(onDepartmentsAction),
    call(onTemplatesAction),
    call(onCandidatesAction),
  ]);
}
