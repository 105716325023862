import * as React from 'react';
import { Dispatch } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ListGroup } from 'react-bootstrap';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import { StageInfo } from '../../types';
import { selectPipeline, setPipeline } from '../pipeline.slice';
import Stage from '../components/Stage';
import StageStatic from '../components/StageStatic';
export { default as showPipelineReducer } from '../show-pipeline/show-pipeline.slice';

const onDragEnd = (
  result: DropResult,
  dispatch: Dispatch<any>,
  pipelineId: number,
  stages: StageInfo[],
) => {
  const { destination, source } = result;

  if (!destination) return;

  if (
    destination.droppableId === source.droppableId &&
    destination.index === source.index
  )
    return;

  const newStages = [...stages];
  const [removed] = newStages.splice(source.index, 1);
  newStages.splice(destination.index, 0, removed);

  dispatch(
    setPipeline({
      id: pipelineId,
      stages: newStages.map((stage, index) => ({ ...stage, position: index })),
    }),
  );
};

export interface IPipelineStagesProps {}

export default function PipelineStages(props: IPipelineStagesProps) {
  const stages = useSelector(selectPipeline).info!.stages!;
  const dispatch = useDispatch();
  const pipelineId = useSelector(selectPipeline).info!.id!;

  return (
    <DragDropContext
      onDragEnd={(result) => onDragEnd(result, dispatch, pipelineId, stages)}
    >
      <Droppable droppableId={'droppable'}>
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            <ListGroup>
              {stages.map((stage: StageInfo, index: number) => {
                if (stage.kind === 'sourced' || stage.kind === 'applied') {
                  return <StageStatic key={stage.draggableId} stageName={stage.name} />;
                } else {
                  return (
                    <Stage
                      key={stage.draggableId}
                      stage={stage}
                      index={index}
                      newStage={!stage.name}
                    />
                  );
                }
              })}
            </ListGroup>
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}
