import { createAction } from '@reduxjs/toolkit';
import { StageInfo } from '../../types';
import { withPayloadType } from 'utils';

export interface UpdatePipelinePayload {
  pipelineId?: number;
  stages_attributes: StageInfo[];
}

export const updatePipelineStart = createAction(
  'UPDATE_PIPELINE::START',
  withPayloadType<{ pipelineInfo: UpdatePipelinePayload; organizationId: string }>(),
);
