import * as React from 'react';
import { useState, createContext } from 'react';
import { TQuestionWithOptions, EdittingState } from '../../types';
import QuestionWithOptionsEditting from './QuestionWithOptionsEditting';
import QuestionWithOptionsSaved from './QuestionWithOptionsSaved';

export interface IQuestionWithOptionsProps {
  question: TQuestionWithOptions;
  index: number;
  newQuestion: boolean;
}

export const EdittingStateContext = createContext<Partial<EdittingState>>({});

export default function QuestionWithOptions(props: IQuestionWithOptionsProps) {
  const { question, index, newQuestion } = props;
  const [isEditting, setIsEditting] = useState<boolean>(false);
  return (
    <EdittingStateContext.Provider value={{ isEditting, setIsEditting }}>
      {isEditting || newQuestion ? (
        <QuestionWithOptionsEditting
          index={index}
          question={question}
          newQuestion={newQuestion}
        />
      ) : (
        <QuestionWithOptionsSaved index={index} question={question} />
      )}
    </EdittingStateContext.Provider>
  );
}
