import api from 'services/api';

export async function getJob({
  id,
  organizationId,
}: {
  id: string;
  organizationId: string;
}) {
  return api.get(`api/dashboard/organizations/${organizationId}/jobs/${id}`);
}
