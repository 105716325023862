import 'scss/main.scss';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Router } from 'react-router-dom';
import jwt from 'jsonwebtoken';
import Routes from 'routes';
import { history } from 'redux/store';
import './assets/svg/icons/font-awesome-icons';

import ErrorBoundary from 'components/Errors/ErrorBoundary';
import { persistUserSessionStart } from 'services/session/persist-user-session.actions';
import { getTokenFromLocalStorage } from 'utils';

function App() {
  const dispatch = useDispatch();
  const token = getTokenFromLocalStorage();
  if (token) {
    const now = Date.now();
    const decoded: any = jwt.decode(token);
    const expiration: number = decoded.exp * 1000;
    if (expiration > now) {
      dispatch(persistUserSessionStart());
    } else {
      history.push('/login');
    }
  }

  return (
    <div>
      <ErrorBoundary>
        <Router history={history}>
          {/* <Navigation /> */}
          <Routes />
        </Router>
      </ErrorBoundary>
    </div>
  );
}

export default App;
