import * as React from 'react';
import { Spinner } from 'react-bootstrap';
import {
  useCreateDepartmentRequestStates,
  useDeleteDepartmentRequestStates,
  useUpdateDepartmentRequestStates,
} from '../hooks';

export interface IDepartmentsSpinnersProps {}

export default function DepartmentsSpinners(props: IDepartmentsSpinnersProps) {
  const { updateDepartmentLoading } = useUpdateDepartmentRequestStates();
  const { deleteDepartmentLoading } = useDeleteDepartmentRequestStates();

  const { createDepartmentLoading } = useCreateDepartmentRequestStates();
  return (
    <>
      {(deleteDepartmentLoading ||
        updateDepartmentLoading ||
        createDepartmentLoading) && <Spinner animation="border" />}
    </>
  );
}
