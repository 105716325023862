import * as React from 'react';
import { ToggleButton, ToggleButtonGroup, Form } from 'react-bootstrap';
import { FormikHandlers } from 'formik';
import { FieldStates } from '../types';

export interface IApplicationFormFieldProps {
  name: string;
  fieldValue: string;
  label: string;
  mandatory?: boolean;
}

export default function ApplicationFormField(
  props: IApplicationFormFieldProps & Partial<Pick<FormikHandlers, 'handleChange'>>,
) {
  const { label, name, handleChange, fieldValue, mandatory } = props;
  return (
    <div className="card-small">
      <Form.Label className="form-label--dark">{label}</Form.Label>
      <ToggleButtonGroup
        onChange={handleChange}
        type="radio"
        name={name}
        defaultValue={fieldValue}
      >
        <ToggleButton onChange={handleChange} value={FieldStates.Required}>
          Rerquired
        </ToggleButton>
        <ToggleButton
          disabled={mandatory ? true : false}
          onChange={handleChange}
          value={FieldStates.Optional}
        >
          Optional
        </ToggleButton>
        <ToggleButton
          disabled={mandatory ? true : false}
          onChange={handleChange}
          value={FieldStates.Off}
        >
          Off
        </ToggleButton>
      </ToggleButtonGroup>
    </div>
  );
}
