import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useOrganizationId } from 'hooks';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Card, Form, ListGroup } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Department from '../components/Department';
import DepartmentsSpinners from '../components/DepartmentsSpinners';
import DepartmentsToasters from '../components/DepartmentsToasters';
import CreateDepartment from '../create-department';
import { selectDepartmentsInfo } from '../slice';
import { listDepartmentsStart } from './actions';
export { default as listDepartmentsReducer } from './slice';
export { onListDepartmentsStart } from './saga';

export interface IDepartmentsProps {}

export default function Departments(props: IDepartmentsProps) {
  const dispatch = useDispatch();
  const [search, setSearch] = useState('');
  const departments = useSelector(selectDepartmentsInfo);
  const organizationId = useOrganizationId();

  const filterSearch = (e: any) => {
    setSearch(e.target.value);
  };

  // fetch departments
  useEffect(() => {
    if (organizationId) {
      dispatch(listDepartmentsStart({ organizationId }));
    }
  }, [organizationId]);

  return (
    <>
      <Card.Body>
        <Card.Title>Departments</Card.Title>
        <div className="inputs-flex">
          <div className="departments__search-container">
            <Form.Control
              onChange={(e) => filterSearch(e)}
              className="departments__search"
              placeholder="Search departments"
              type="text"
            />
            <FontAwesomeIcon icon="search" />
          </div>
          <CreateDepartment />
        </div>
        <ListGroup>
          {departments
            ?.filter((department) => department?.name.includes(search))
            ?.map((department) => (
              <Department key={department.id} department={department} />
            ))}
        </ListGroup>
      </Card.Body>
      <DepartmentsToasters />
      <DepartmentsSpinners />
    </>
  );
}
