import { createAction } from '@reduxjs/toolkit';

import { withPayloadType } from 'utils';

type InvitationInfo = { email: string };

export const inviteUserStart = createAction(
  'INVITE_USER::START',
  withPayloadType<{ invitationInfo: InvitationInfo; organizationId: string }>(),
);
