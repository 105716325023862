import { withPayloadType } from 'utils';
import { createAction } from '@reduxjs/toolkit';

export const updateDepartmentStart = createAction(
  'UPDATE_DEPARTMENT::START',
  withPayloadType<{
    departmentName: string;
    departmentId: string;
    organizationId: string;
  }>(),
);
