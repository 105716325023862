import * as React from 'react';
import { nanoid } from 'nanoid';
import Form from 'react-bootstrap/Form';
import { IconDrag } from 'assets/svg/icons';
import { DroppableProvided, Draggable } from 'react-beautiful-dnd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { QuestionOption } from '../../types';

export interface IOptionsEdittingProps {
  optionsState: [
    QuestionOption[],
    React.Dispatch<React.SetStateAction<QuestionOption[]>>,
  ];
  provided: DroppableProvided;
}

export default function OptionsEditting(props: IOptionsEdittingProps) {
  const { optionsState, provided } = props;
  const [options, setOptions] = optionsState;
  return (
    <ul className="question__options">
      {options.map((option, i) => (
        <Draggable key={option.id} draggableId={option.id} index={i}>
          {(provided) => (
            <div
              className="question__option"
              ref={provided.innerRef}
              {...provided.draggableProps}
            >
              <div className="drag-handle" {...provided.dragHandleProps}>
                <IconDrag></IconDrag>
              </div>
              <div className="question__option-input">
                <Form.Control
                  className="ml-1"
                  value={option.value}
                  onChange={(e) =>
                    setOptions(
                      options.map((item) =>
                        item.id === option.id
                          ? { id: option.id, value: e.target.value }
                          : item,
                      ),
                    )
                  }
                ></Form.Control>
                <button
                  onClick={() =>
                    setOptions(options.filter((item) => option.id !== item.id))
                  }
                  className="btn-reset"
                  type="button"
                >
                  <FontAwesomeIcon icon="times" color="#CFCFCF" />
                </button>
              </div>
            </div>
          )}
        </Draggable>
      ))}
      {provided.placeholder}
      <div className="question__add-option">
        {/* <FontAwesomeIcon icon="plus" color="#BEBEBE" /> */}
        <p onClick={() => setOptions([...options, { id: nanoid(), value: '' }])}>
          Add answer
        </p>
      </div>
    </ul>
  );
}
