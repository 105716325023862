import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'redux/root-reducer';
import { SearchApplicationsRes } from './types';

const initialState: { info: SearchApplicationsRes | null } = {
  info: null,
};

const candidatesSlice = createSlice({
  name: 'CANDIDATES_APPLICATIONS',
  initialState,
  reducers: {
    setCandidatesInfo: (state, action: PayloadAction<SearchApplicationsRes>) => {
      state.info = action.payload;
    },
    resetCandidatesInfo: (state) => {
      state.info = initialState.info;
    },
  },
});

// REDUCER
export default candidatesSlice.reducer;

// ACTIONS
export const { setCandidatesInfo, resetCandidatesInfo } = candidatesSlice.actions;

// SELECTORS
export const selectCandidates = (state: RootState) =>
  state.organization.candidates.info?.items;
export const selectFilters = (state: RootState) =>
  state.organization.candidates.info?.filters;
export const selectCursor = (state: RootState) =>
  state.organization.candidates.info?.cursor;
