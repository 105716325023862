import * as React from 'react';
import { useEffect, useState } from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import SearchBar from 'components/SearchBar';
import CandidateCard from '../components/CandidateCard';
import { listCandidatesStart } from './actions';
import { selectCandidates } from '../slice';
import { selectIsLoading } from './slice';
import Filters from '../components/Filters';
import { ISelectedFilters } from '../types';
import { useOrganizationId } from 'hooks';
import NotFound from 'pages/error/NotFound';

export { default as listCandidatesReducer } from './slice';
export { onListCandidatesStart } from './saga';

interface ISelectedFiltersContext {
  selectedFilters: ISelectedFilters;
  setSelectedFilters: React.Dispatch<React.SetStateAction<ISelectedFilters>>;
}

export const SelectedFiltersContext = React.createContext<
  Partial<ISelectedFiltersContext>
>({});

export interface IListCandidatesProps {}

export default function ListCandidates(props: IListCandidatesProps) {
  const dispatch = useDispatch();
  const loading = useSelector(selectIsLoading);
  const organizationId = useOrganizationId();
  const [candidatesSearch, setCandidatesSearch] = useState('');
  const candidates = useSelector(selectCandidates)?.filter((candidate) => {
    if (candidatesSearch) {
      return candidate.applicant.name.includes(candidatesSearch);
    } else {
      return true;
    }
  });
  const [selectedFilters, setSelectedFilters] = useState<ISelectedFilters>({
    jobs: [],
    created_after: undefined,
    disqualified: undefined,
  });
  const [showNotFound, setShowNotFound] = useState<boolean>(false);

  useEffect(() => {
    if (selectedFilters && organizationId) {
      dispatch(listCandidatesStart({ selectedFilters, organizationId }));
      setShowNotFound(false);
    } else {
      setShowNotFound(true);
    }
  }, [selectedFilters, organizationId, dispatch]);

  if (showNotFound) return <NotFound />;

  return (
    <SelectedFiltersContext.Provider value={{ selectedFilters, setSelectedFilters }}>
      <div className="container pt-4">
        <div className="inputs-flex">
          <h2 style={{ fontSize: '18px', fontWeight: 500 }}>All Candidates</h2>
          <SearchBar
            searchInput={candidatesSearch}
            setSearchInput={setCandidatesSearch}
          />
        </div>
        <Row>
          <Filters />
          <Col style={{ display: 'flex', flexWrap: 'wrap' }} lg={9}>
            {candidates?.map((candidate) => (
              <CandidateCard candidate={candidate} key={candidate.id} />
            ))}
          </Col>
        </Row>
        {loading && <Spinner animation="border" />}
      </div>
    </SelectedFiltersContext.Provider>
  );
}
