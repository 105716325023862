import * as React from 'react';
import { IStageApplication } from 'features/job/types';
import { Col, Card } from 'react-bootstrap';
import { useRouteMatch, NavLink } from 'react-router-dom';
import Applicant from './Applicant';

export interface IApplicantsProps {
  stageApplications: IStageApplication[];
}

export default function Applicants(props: IApplicantsProps) {
  const match = useRouteMatch();
  const { stageApplications } = props;
  return (
    <Col lg={3}>
      <Card>
        <Card.Header>All Candidates</Card.Header>
        <ul className="applicants__nav">
          <li>
            <a>All</a>
          </li>
          <li>
            <a>Bookmarked</a>
          </li>
          <li>
            <a>Disqualified</a>
          </li>
        </ul>
        <div>
          {stageApplications.map((stageApplication) => (
            <NavLink
              key={stageApplication.id}
              activeClassName="selected"
              className="custom-link"
              to={`${match.url}/application/${stageApplication.id}`}
            >
              <Applicant stageApplication={stageApplication} />
            </NavLink>
          ))}
        </div>
      </Card>
    </Col>
  );
}
