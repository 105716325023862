import { createAction } from '@reduxjs/toolkit';
import { withPayloadType } from 'utils';

export interface CreatePipelinePayload {
  stages_attributes?: { name: string; position: number }[];
}

export const createPipelineStart = createAction(
  'CREATE_PIPELINE::START',
  withPayloadType<{ pipelineInfo: CreatePipelinePayload; organizationId: string }>(),
);
