import * as React from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useCleanup, useOrganizationId } from 'hooks';
import { toast, ToastContainer } from 'react-toastify';

import { selectIsLoading, selectSuccess, selectError, resetToInitials } from './slice';
import { acceptInvitationStart } from './actions';
import { toastConfig } from 'utils';

export { default as acceptInvitationReducer } from './slice';

export interface IAcceptInvitationProps {
  id: string;
}

export default function AcceptInvitation(props: IAcceptInvitationProps) {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectIsLoading);
  const success = useSelector(selectSuccess);
  const error = useSelector(selectError);
  const organizationId = useOrganizationId();

  useCleanup(resetToInitials, error.message, success);

  if (isLoading) return <Spinner animation="border" />;
  if (success) return <div>success</div>;
  if (error.message) {
    toast.error(error.message, toastConfig);
  }
  return (
    <div>
      <Button
        onClick={() => {
          if (organizationId) {
            dispatch(acceptInvitationStart({ id: props.id, organizationId }));
          }
        }}
        variant="primary"
      >
        accept invitation
      </Button>
      {error.message && <ToastContainer />}
    </div>
  );
}
