import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ErrorInfo, RenderingState } from 'redux/types';
import { RootState } from 'redux/root-reducer';

const initialState: RenderingState = {
  isLoading: false,
  success: false,
  error: {
    message: '',
    statusCode: null,
  },
};

export const uploadAvatarSlice = createSlice({
  name: 'UPLOAD_AVATAR',
  initialState,
  reducers: {
    // GET UPLOAD AVATAR TOKENS
    tokensLoading: (state) => {
      state.isLoading = true;
    },
    tokensSuccess: (state) => {
      state.isLoading = false;
      state.error.message = '';
    },
    tokensFailure: (state, action: PayloadAction<ErrorInfo>) => {
      state.isLoading = false;
      state.success = false;
      state.error.message = action.payload.message;
    },
    // UPLOAD AVATAR TO URL
    uploadLoading: (state) => {
      state.isLoading = true;
    },
    uploadSuccess: (state) => {
      state.isLoading = false;
      state.success = true;
    },
    uploadFailure: (state, action: PayloadAction<ErrorInfo>) => {
      state.isLoading = false;
      state.success = false;
      state.error.message = action.payload.message;
    },
    resetToInitials: (state) => {
      state.isLoading = initialState.isLoading;
      state.success = initialState.success;
      state.error = initialState.error;
    },
  },
});

// REDUCER
export default uploadAvatarSlice.reducer;

// ACTIONS
export const {
  tokensLoading,
  tokensSuccess,
  tokensFailure,
  uploadLoading,
  uploadSuccess,
  uploadFailure,
  resetToInitials,
} = uploadAvatarSlice.actions;

// SELECTORS
export const selectIsLoading = (state: RootState) => state.ui.upload_avatar.isLoading;
export const selectSuccess = (state: RootState) => state.ui.upload_avatar.success;
export const selectError = (state: RootState) => state.ui.upload_avatar.error;
