import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { InvitationInfo } from 'redux/types';
import { RootState } from 'redux/root-reducer';

const initialState: { info: InvitationInfo | null } = {
  info: null,
};

const invitationSlice = createSlice({
  name: 'INVITAITON',
  initialState,
  reducers: {
    setInvitationInfo: (state, action: PayloadAction<InvitationInfo>) => {
      state.info = action.payload;
    },
  },
});

// REDUCER
export default invitationSlice.reducer;

// ACTIONS
export const { setInvitationInfo } = invitationSlice.actions;

// SELECTORS
export const selectInvitationInfo = (state: RootState) => state.invitation.info;
