import api from 'services/api';
import {
  TCity,
  Option,
} from 'features/job/create-job/create-job-stages/job-details/types';
import { SetLocationState } from 'features/job/create-job/create-job-stages/job-details/components/LocationDetails';
import { Location } from 'history';
import { ToastOptions } from 'react-toastify';

export function setTokenToRequestHeader(token: string | null) {
  if (token) {
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    delete api.defaults.headers.common['Authorization'];
  }
}

export function setTokenToLocalStorage(auth: string | null) {
  if (auth) {
    const token = auth.split(' ')[1];
    window.localStorage.setItem('jwt', token);
  }
}

export function getTokenFromLocalStorage(): string | null {
  return window.localStorage.getItem('jwt');
}

export function removeTokenFromLocalStorage() {
  window.localStorage.removeItem('jwt');
}

export function getTokenFromUrl(loundefinedcation: any) {
  const searchParams = new URLSearchParams(window.location.search);
  const token = searchParams.get('reset_password_token');
  return token;
}

// THE FUNCTION ENSURES TYPE SAFETY FOR WHATEVER TYPE OF PAYLOAD PASSED TO CREATE ACTION FUNCTION
export function withPayloadType<T>() {
  return (t: T) => ({ payload: t });
}

export function discardEmptyFields(obj: any) {
  const newObj: any = {};
  for (const [key, value] of Object.entries(obj)) {
    if (value) {
      newObj[key] = value;
    }
  }
  return newObj;
}

export function getInitials(firstname: string, lastname: string) {
  return firstname[0].toUpperCase() + lastname[0].toUpperCase();
}

// export const populateCountriesOptions = async (setCountriesOptions: SetLocationState) => {
//   const countriesRes: TCountry[] = await getCountries();
//   const countriesParsed = countriesRes.map((country: TCountry) => ({
//     label: country.Name,
//     value: country.Code,
//   }));
//   setCountriesOptions(countriesParsed);
// };

export const populateCitiesOptions = (
  cities: TCity[] | undefined,
  countryOption: { label: string; value: string },
  setCitiesOptions: SetLocationState,
) => {
  const citiesOfCountry = cities
    ?.filter((city: TCity) => city.country.match(countryOption.label))
    .map((city: TCity) => ({
      label: city.name,
      value: city.name,
    }));
  setCitiesOptions(citiesOfCountry);
};

export const populateCurrenciesOptions = (currencies: any) => {
  const currenciesOptions: Option<any>[] = [];

  for (const [key] of Object.entries(currencies)) {
    currenciesOptions.push({ label: key, value: key });
  }
  return currenciesOptions;
};

/* when navigating always set Modal to be shown and declare what route to go to then set
 Modal to be hidden again (modal doesn't render unless [showModel, isDirty] are both true */

export const onCreateJobNavSelect = (
  eventKey: string | null,
  setRedirectTo: React.Dispatch<React.SetStateAction<string>> | undefined,
  location: Location<unknown>,
  setShowJobDetailsModal: React.Dispatch<React.SetStateAction<boolean>> | undefined,
  setShowApplicationFormModal: React.Dispatch<React.SetStateAction<boolean>> | undefined,
  setShowWorkflowModal: React.Dispatch<React.SetStateAction<boolean>> | undefined,
) => {
  // if route is "job-details" and "application-form" or "workflow" selected
  if (location.pathname.includes('job-details')) {
    if (setShowJobDetailsModal) {
      setShowJobDetailsModal(true);
      if (eventKey === 'application-form') {
        if (setRedirectTo) {
          setRedirectTo('/application-form');
        }
      }
      if (eventKey === 'workflow') {
        if (setRedirectTo) setRedirectTo('/workflow');
      }
    }
  }

  // if route is "application-form" and "job-details" or "workflow" selected
  if (location.pathname.includes('application-form')) {
    if (setShowApplicationFormModal) {
      setShowApplicationFormModal(true);

      if (eventKey === 'job-details') {
        if (setRedirectTo) setRedirectTo('/job-details');
      }
      if (eventKey === 'workflow') {
        if (setRedirectTo) setRedirectTo('/workflow');
      }
    }
  }

  // if route is "workflow" and "job-details" or "application-form" selected
  if (location.pathname.includes('workflow')) {
    if (setShowWorkflowModal) {
      setShowWorkflowModal(true);

      if (eventKey === 'job-details') {
        if (setRedirectTo) setRedirectTo('/job-details');
      }
      if (eventKey === 'application-form') {
        if (setRedirectTo) setRedirectTo('/application-form');
      }
    }
  }
};

export const removeLastRouteString = (match: any) => {
  return match.url.split('/').slice(0, -1).join('/');
};

export const toastConfig: Partial<ToastOptions> = {
  position: 'top-right',
  autoClose: false,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: false,
  progress: undefined,
};
