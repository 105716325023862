import api from 'services/api';

export async function deleteDepartment({
  departmentId,
  organizationId,
}: {
  departmentId: string;
  organizationId: string;
}) {
  const response = await api.delete(
    `api/dashboard/organizations/${organizationId}/departments/${departmentId}`,
  );
  return response;
}
