import { createAction } from '@reduxjs/toolkit';
import { withPayloadType } from 'utils';
import { JobFormValues } from '../types';

export type updateJobValues = {
  jobDetails: JobFormValues;
  jobId: string;
  organizationId: string;
};

export const updateJobStart = createAction(
  'UPDATE_JOB::START',
  withPayloadType<updateJobValues>(),
);
