import * as React from 'react';
import { Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface IContactDetailsProps {
  phone: string;
  email: string;
}

export default function ContactDetails(props: IContactDetailsProps) {
  const { phone, email } = props;

  return (
    <Card className="contact-details">
      <Card.Header>Contact details</Card.Header>
      <Card.Body>
        <p>
          <FontAwesomeIcon flip="horizontal" icon="phone" className="mr-3" />
          {phone}
        </p>
        <p>
          <FontAwesomeIcon icon="envelope" className="mr-3" />
          {email}
        </p>
      </Card.Body>
    </Card>
  );
}
