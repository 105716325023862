import * as React from 'react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { SelectCallback } from 'react-bootstrap/helpers';
import { Dropdown, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { selectJobDetails } from 'features/job/create-job/create-job-stages/job-details/job-details.slice';
import { updateApplicationStart } from '../../update-application/actions';
import { IStageApplication } from 'features/job/types';
import { useOrganizationId, useOrganizationSlug, useRequestStates } from 'hooks';
import {
  selectIsLoading,
  selectSuccess,
  selectError,
} from '../../update-application/slice';
import { showJobStart } from '../../actions';
export interface IApplicationControlsProps {
  stageId: string;
  stageApplication: IStageApplication;
}

export default function ApplicationControls(props: IApplicationControlsProps) {
  const { stageId, stageApplication } = props;
  const { applicationId }: { applicationId: string } = useParams();

  return (
    <div className="application__header-controls">
      {stageApplication.disqualified ? (
        'Disqualified'
      ) : (
        <DisqualifyApplication stageId={stageId} applicationId={applicationId} />
      )}

      <FontAwesomeIcon icon="bookmark" />
      <FontAwesomeIcon icon="arrow-left" />
      <FontAwesomeIcon icon="arrow-right" />

      <UpdateApplicationStage stageId={stageId} applicationId={applicationId} />
    </div>
  );
}

const DisqualifyApplication = (props: { stageId: string; applicationId: any }) => {
  const { stageId, applicationId } = props;
  const dispatch = useDispatch();
  const organizationId = useOrganizationId();
  const organizationSlug = useOrganizationSlug();
  const jobId = useSelector(selectJobDetails)!.id;

  return (
    <OverlayTrigger
      placement="left"
      delay={{ show: 250, hide: 400 }}
      overlay={<Tooltip id="button-disqualify">Disqualify</Tooltip>}
    >
      <FontAwesomeIcon
        onClick={() => {
          if (organizationId) {
            dispatch(
              updateApplicationStart({
                jobId,
                stageId,
                applicationId,
                disqualified: true,
                organizationId,
                organizationSlug,
              }),
            );
          }
        }}
        className="disqualify"
        icon="ban"
        color="#DB2828"
      />
    </OverlayTrigger>
  );
};

const UpdateApplicationStage = (props: { stageId: string; applicationId: any }) => {
  const { stageId, applicationId } = props;
  const dispatch = useDispatch();
  const organizationId = useOrganizationId();
  const organizationSlug = useOrganizationSlug();
  const stages = useSelector(selectJobDetails)!.pipeline.stages;
  const currentStage = stages?.find((stage) => stage.id!.toString() === stageId);
  const jobId = useSelector(selectJobDetails)!.id;
  const jobDetails = useSelector(selectJobDetails);
  const currentStageIndex = stages?.findIndex(
    (stage) => stage.id?.toString() === stageId,
  );
  const { success } = useRequestStates(selectIsLoading, selectSuccess, selectError);

  useEffect(() => {
    // UPDATE JOB DETAILS AFTER UPDATING AN APPLICATION
    if (success && jobDetails && organizationId) {
      dispatch(showJobStart({ id: jobId, organizationId }));
    }
  }, [success, dispatch, jobDetails, jobId, organizationId]);

  const onSelect: SelectCallback = (eventKey) => {
    if (organizationId) {
      dispatch(
        updateApplicationStart({
          jobId,
          stageId,
          newStageId: eventKey,
          applicationId,
          organizationId,
          organizationSlug,
        }),
      );
    }
  };
  return (
    <>
      <Dropdown onSelect={onSelect}>
        <Dropdown.Toggle variant="success" id="publish-dropdown">
          {currentStage?.name}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {stages?.slice(currentStageIndex! + 1).map((stage) => (
            <Dropdown.Item eventKey={stage.id?.toString()} key={stage.id}>
              {stage.name}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};
