import { Action } from '@reduxjs/toolkit';
import { takeLatest, put, call } from 'redux-saga/effects';
import { createPipelineStart } from './create-pipeline.actions';
import { loading, success, failure } from './create-pipeline.slice';
import { createPipeline } from './create-pipeline.service';
import { PipelineInfo } from '../../types';
import { setPipeline } from '../pipeline.slice';

function* createPipelineFlow(action: Action<unknown>) {
  if (createPipelineStart.match(action)) {
    try {
      yield put(loading());
      const { data } = yield call(createPipeline, action.payload);
      const { data: pipeline }: { data: PipelineInfo } = data;
      yield put(success());
      yield put(setPipeline(pipeline));
    } catch (error) {
      const { message }: { message: string } = error?.response?.data || error;
      const statusCode: number = error?.response?.status;
      yield put(failure({ message, statusCode }));
    }
  }
}

export function* onCreatePipelineStart() {
  yield takeLatest(createPipelineStart, createPipelineFlow);
}
