export enum Questions {
  ShortAnswer = 'SHORT_ANSWER',
  Paragraph = 'PARAGRAPH',
  MultipleChoice = 'MULTIPLE_CHOICE',
  Checkboxes = 'CHECKBOXES',
  Dropdown = 'DROPDOWN',
  UploadFile = 'UPLOAD_FILE',
  Date = 'DATE',
}

export interface QuestionBase<Questions> {
  type: Questions;
  headline: string;
  id: string;
}

export type TShortAnswer = QuestionBase<Questions.ShortAnswer>;

export type TParagraph = QuestionBase<Questions.Paragraph>;

export type TUploadFile = QuestionBase<Questions.UploadFile>;

export type TDate = QuestionBase<Questions.Date>;

export interface QuestionOption {
  id: string;
  value: string;
}

export interface TMultipleChoice extends QuestionBase<Questions.MultipleChoice> {
  options: QuestionOption[];
}

export interface TCheckboxes extends QuestionBase<Questions.Checkboxes> {
  options: QuestionOption[];
}

export interface TDropdown extends QuestionBase<Questions.Dropdown> {
  options: QuestionOption[];
}

export type TQuestionNoOptions = TShortAnswer | TParagraph | TUploadFile | TDate;

export type TQuestionWithOptions = TMultipleChoice | TCheckboxes | TDropdown;

export type TQuestion = TQuestionNoOptions | TQuestionWithOptions;

// QUESTIONS ACTIONS //
export interface SetQuestionsAction {
  type: 'SET_QUESTIONS';
  payload: TQuestion[];
}

export interface AddQuestionAction {
  type: 'ADD_QUESTION';
  payload: TQuestion;
}

export interface RemoveQuestionAction {
  type: 'REMOVE_QUESTION';
  payload: { id: string };
}

export interface EditQuestionAction {
  type: 'EDIT_QUESTION';
  payload: TQuestion;
}

export interface SetQuestionOptionsAction {
  type: 'SET_QUESTION_OPTIONS';
  payload: { questionId: string; options: QuestionOption[] };
}

export type QuestionsAction =
  | SetQuestionsAction
  | AddQuestionAction
  | RemoveQuestionAction
  | EditQuestionAction
  | SetQuestionOptionsAction;

export interface EdittingState {
  isEditting: boolean;
  setIsEditting: React.Dispatch<React.SetStateAction<boolean>>;
}
