import { combineReducers } from 'redux';
import { listDepartmentsReducer } from './list-departments';
import { createDepartmentReducer } from './create-department';
import { updateDepartmentReducer } from './update-department';
import { deleteDepartmentReducer } from './delete-department';

export default combineReducers({
  list: listDepartmentsReducer,
  create: createDepartmentReducer,
  update: updateDepartmentReducer,
  delete: deleteDepartmentReducer,
});
