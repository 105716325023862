import * as React from 'react';

interface ISvgJobs {
  active?: boolean;
}

function SvgJobsBlue(props: ISvgJobs) {
  const { active } = props;
  return (
    <svg width={20} height={19} {...props}>
      <path
        d="M18 4h-4V2a1.993 1.993 0 00-2-2H8a1.993 1.993 0 00-2 2v2H2A1.985 1.985 0 00.01 6L0 17a1.993 1.993 0 002 2h16a1.993 1.993 0 002-2V6a1.993 1.993 0 00-2-2zm-6 0H8V2h4z"
        fill={active ? '#006fd9' : 'rgba(250, 250, 250, 0.7'}
      />
    </svg>
  );
}

export default SvgJobsBlue;
