import api from 'services/api';

export async function rejectInvitation({
  id,
  organizationId,
}: {
  id: string;
  organizationId: string;
}) {
  return api.put(
    `api/dashboard/organizations/${organizationId}/invitations/${id}/reject`,
  );
}
