import * as React from 'react';
import { ListGroup } from 'react-bootstrap';
import { TQuestion, Questions } from '../types';
import QuestionNoOptions from './QuestionNoOptions/QuestionNoOptions';
import QuestionWithOptions from './QuestionWithOptions/QuestionWithOptions';
import { Droppable } from 'react-beautiful-dnd';

export interface IQuestionsListProps {
  questions: TQuestion[];
}

export default function QuestionsList(props: IQuestionsListProps) {
  const { questions } = props;
  return (
    <Droppable droppableId={'droppableQuestions'}>
      {(provided) => (
        <div ref={provided.innerRef} {...provided.droppableProps}>
          <ListGroup>
            {questions.map((question, index) => {
              switch (question.type) {
                case Questions.ShortAnswer:
                case Questions.Paragraph:
                case Questions.Date:
                case Questions.UploadFile:
                  return (
                    <QuestionNoOptions
                      key={question.id}
                      index={index}
                      question={question}
                      newQuestion={!question.headline}
                    />
                  );
                case Questions.MultipleChoice:
                case Questions.Checkboxes:
                case Questions.Dropdown:
                  return (
                    <QuestionWithOptions
                      key={question.id}
                      index={index}
                      question={question}
                      newQuestion={!question.headline}
                    />
                  );
              }
            })}
            {provided.placeholder}
          </ListGroup>
        </div>
      )}
    </Droppable>
  );
}
