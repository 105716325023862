import { listTemplatesStart } from '../list-templates/actions';
import { takeLatest, call, put } from 'redux-saga/effects';

import { loading, success, failure } from './slice';
import { createTemplateStart } from './actions';
import { createTemplate } from './service';
import { Action } from '@reduxjs/toolkit';

function* createTemplateFlow(action: Action<unknown>) {
  if (createTemplateStart.match(action)) {
    try {
      yield put(loading());
      yield call(createTemplate, action.payload);
      yield put(success());
      yield put(listTemplatesStart({ organizationId: action.payload.organizationId }));
    } catch (error) {
      const { message }: { message: string } = error?.response?.data || error;
      const statusCode: number = error?.response?.status;
      yield put(failure({ message, statusCode }));
    }
  }
}

export function* onCreateTemplateStart() {
  yield takeLatest(createTemplateStart, createTemplateFlow);
}
