import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useRouteMatch } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast, ToastContainer } from 'react-toastify';
import ShowPipeline from './pipeline/show-pipeline';
import {
  selectIsLoading,
  selectError,
} from './pipeline/show-pipeline/show-pipeline.slice';
import { selectJobDetails } from '../job-details/job-details.slice';
import { showPipelineStart } from './pipeline/show-pipeline/show-pipeline.actions';
import { selectPipeline } from './pipeline/pipeline.slice';
import { useContext } from 'react';
import { RedirectToContext, IsDirtyContext, WorkflowModalContext } from '../..';
import {
  useCreateJobRedirection,
  useOrganizationId,
  useUnsavedChangesPopup,
} from 'hooks';
import { Spinner } from 'react-bootstrap';
import { toastConfig } from 'utils';

export { default as pipelineReducer } from './pipeline/pipeline.slice';

export interface IWorkflowProps {}

export default function Workflow() {
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const isLoading = useSelector(selectIsLoading);
  const pipelineId = useSelector(selectJobDetails)?.pipeline_id;
  const stages = useSelector(selectPipeline).info?.stages;
  const error = useSelector(selectError);
  const { redirectTo, setRedirectTo } = useContext(RedirectToContext);
  const { setShowWorkflowModal } = useContext(WorkflowModalContext);
  const { isDirty } = useContext(IsDirtyContext);
  const organizationId = useOrganizationId();
  useCreateJobRedirection(redirectTo, setRedirectTo, isDirty, match);
  useUnsavedChangesPopup(isDirty);

  React.useEffect(() => {
    if (stages) return;
    if (pipelineId && organizationId) {
      dispatch(showPipelineStart({ id: pipelineId, organizationId }));
    }

    return function () {
      if (setShowWorkflowModal) setShowWorkflowModal(false);
    };
  }, [dispatch, pipelineId, organizationId, setShowWorkflowModal, stages]);

  useCreateJobRedirection(redirectTo, setRedirectTo, isDirty, match);

  if (isLoading) return <Spinner animation="border" />;
  if (error.message) return <div>{error.message}</div>;
  if (error.message) {
    toast.error(error.message, toastConfig);
  }

  return (
    <div className="container">
      <ShowPipeline />
      <div className="btns-space-between">
        <Link
          className="btn-prev"
          to={`${match.url.replace('workflow', 'application-form')}`}
        >
          <FontAwesomeIcon icon="chevron-circle-left" size="lg" />{' '}
          <span>Application Form</span>
        </Link>
      </div>
      {error.message && <ToastContainer />}
    </div>
  );
}
