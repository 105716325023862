import * as React from 'react';
import { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, FormGroup, Form } from 'react-bootstrap';
import { Field, ErrorMessage, FieldProps } from 'formik';
import { JobTitleContext } from 'features/job/create-job';
import SelectMemoized from './SelectMemoized';
import { JobFormValues } from '../types';
import { StylesContext } from '../show-job-details';
import { selectDepartmentsInfo } from 'features/organization/departments/slice';
import { useOrganizationId } from 'hooks';
import { listDepartmentsStart } from 'features/organization/departments/list-departments/actions';

export interface IJobTitleProps {}

export default function JobTitle(props: IJobTitleProps) {
  const dispatch = useDispatch();
  const organizationId = useOrganizationId();
  const [_, setJobTitle] = useContext(JobTitleContext);
  const [borderStyles, setBorderStyles] = React.useState<React.CSSProperties>({});
  const selectStyles = React.useContext(StylesContext);
  const departmentsOptions = useSelector(selectDepartmentsInfo)?.map((department) => ({
    value: department.id,
    label: department.name,
  }));

  // fetch departments
  useEffect(() => {
    if (organizationId) {
      dispatch(listDepartmentsStart({ organizationId }));
    }
  }, [organizationId]);

  return (
    <Card>
      <Card.Body>
        <Card.Title>Job Details</Card.Title>
        <div className="inputs-flex">
          <FormGroup className="input-element" controlId="jobTitle">
            <Form.Label>Job Title</Form.Label>
            <Field name="jobTitle">
              {({ form, meta, field }: FieldProps) => (
                <>
                  <Form.Control
                    value={field.value}
                    onChange={(e) => {
                      form.handleChange(e);
                      if (setJobTitle) {
                        setJobTitle(
                          e.target.value.replace(/^./, e.target.value[0]?.toUpperCase()),
                        );
                      }
                      if (e.target.value.length === 0 || meta.error) {
                        setBorderStyles({ borderColor: '#FF1313' });
                      } else {
                        setBorderStyles({ borderColor: '#33D205' });
                      }
                    }}
                    onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                      form.handleBlur(e);
                      if (e.target.value.length === 0 || meta.error) {
                        setBorderStyles({ borderColor: '#FF1313' });
                      } else {
                        setBorderStyles({ borderColor: '#ced4da' });
                      }
                    }}
                    onFocus={(e: React.FocusEvent<HTMLInputElement>) => {
                      if (meta.error) {
                        setBorderStyles({ borderColor: '#FF1313' });
                      } else {
                        setBorderStyles({ borderColor: '#0175D7' });
                      }
                    }}
                    type="text"
                    placeholder="Type job title"
                    style={{
                      ...borderStyles,
                    }}
                  />
                  {meta.touched && meta.error && (
                    <div className="error">{meta.error}</div>
                  )}
                </>
              )}
            </Field>
          </FormGroup>
          <FormGroup className="input-element" controlId="department">
            <Form.Label>Department</Form.Label>
            <Field
              name="department"
              type="text"
              placeholder="Type Department"
              as={Form.Control}
            >
              {({
                form: { setFieldValue },
                field: { value, name },
              }: FieldProps<any, JobFormValues>) => (
                <SelectMemoized
                  value={value}
                  onSelect={(value) => setFieldValue(name, value)}
                  options={
                    departmentsOptions?.length
                      ? departmentsOptions
                      : [{ label: '', value: '' }]
                  }
                  selectStyles={selectStyles}
                  placeholder="Department"
                />
              )}
            </Field>
            <ErrorMessage className="error" name="department" component="div" />
          </FormGroup>
        </div>
      </Card.Body>
    </Card>
  );
}
