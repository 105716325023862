import * as React from 'react';
import { useContext } from 'react';
import { QuestionsDispatch } from '../../show-application-form';
import { QuestionsAction } from '../types';
import { removeQuestion } from '../actions';

export interface IDeleteQuestionProps {
  questionId: string;
}

export default function DeleteQuestion(props: IDeleteQuestionProps) {
  const { questionId } = props;
  const dispatch = useContext(QuestionsDispatch);

  return (
    <button
      type="button"
      onClick={() =>
        (dispatch as React.Dispatch<QuestionsAction>)(removeQuestion({ id: questionId }))
      }
      className="btn-delete btn-text btn-reset ml-4"
    >
      Delete
    </button>
  );
}
