import * as React from 'react';
import { useSelector } from 'react-redux';
import { selectUser } from '../slice';

export { onShowUserSatrt } from './saga';
export { default as showUserReducer } from './slice';

export interface IShowUserProps {}

// eslint-disable-next-line no-unused-vars
export default function ShowUser(props: IShowUserProps) {
  const user = useSelector(selectUser);

  return (
    <div>
      <div>
        <h2>user Info</h2>
        <div>{`first name: ${user.first_name}`}</div>
        <div>{`last name: ${user.last_name}`}</div>
        <div>{`email: ${user.email}`}</div>
        <p>avatar: </p>
        <img src={user.avatar} alt="avatar" width="100px" />
      </div>
    </div>
  );
}
