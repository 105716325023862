import * as React from 'react';
import { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { Nav } from 'react-bootstrap';
import { onCreateJobNavSelect } from 'utils/index';
import {
  RedirectToContext,
  ApplicationFormModalContext,
  WorkflowModalContext,
  JobDetailsModalContext,
} from '..';

type SelectEvent = React.SyntheticEvent<unknown>;

export interface ICreateJobHeaderNavigationProps {
  navItemDisabled: boolean;
}

export default function CreateJobHeaderNavigation(
  props: ICreateJobHeaderNavigationProps,
) {
  const { navItemDisabled } = props;
  const location = useLocation();
  const { setShowJobDetailsModal } = useContext(JobDetailsModalContext);

  const { setShowApplicationFormModal } = useContext(ApplicationFormModalContext);

  const { setShowWorkflowModal } = useContext(WorkflowModalContext);

  const { setRedirectTo } = useContext(RedirectToContext);

  return (
    <Nav className="header__nav" activeKey={location.pathname.split('/').pop()}>
      <Nav.Item>
        <Nav.Link
          eventKey="job-details"
          onSelect={(eventKey: string | null) =>
            onCreateJobNavSelect(
              eventKey,
              setRedirectTo,
              location,
              setShowJobDetailsModal,
              setShowApplicationFormModal,
              setShowWorkflowModal,
            )
          }
        >
          Job Details
        </Nav.Link>
      </Nav.Item>
      <Nav.Item aria-disabled={navItemDisabled}>
        <Nav.Link
          eventKey="application-form"
          onSelect={(eventKey: string | null) =>
            onCreateJobNavSelect(
              eventKey,
              setRedirectTo,
              location,
              setShowJobDetailsModal,
              setShowApplicationFormModal,
              setShowWorkflowModal,
            )
          }
        >
          Application Form
        </Nav.Link>
      </Nav.Item>
      <Nav.Item aria-disabled={navItemDisabled}>
        <Nav.Link
          eventKey="workflow"
          onSelect={(eventKey: string | null) =>
            onCreateJobNavSelect(
              eventKey,
              setRedirectTo,
              location,
              setShowJobDetailsModal,
              setShowApplicationFormModal,
              setShowWorkflowModal,
            )
          }
        >
          Workflow
        </Nav.Link>
      </Nav.Item>
    </Nav>
  );
}
