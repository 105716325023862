import * as React from 'react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';

import AcceptInvitation from '../accept-invitation';
import RejectInvitation from '../reject-invitation';

import { useSelector, useDispatch } from 'react-redux';

import { selectIsLoading, selectError } from './slice';
import { showInvitationStart } from './actions';
import { selectInvitationInfo } from '../invitation.slice';
import { Spinner } from 'react-bootstrap';
import { toastConfig } from 'utils';
import { useOrganizationId } from 'hooks';

export { default as showInvitationReducer } from './slice';

export interface IShowInvitationProps {}

export default function ShowInvitation(props: IShowInvitationProps) {
  const dispatch = useDispatch();
  const { id }: { id: string } = useParams();
  const isLoading = useSelector(selectIsLoading);
  const error = useSelector(selectError);
  const invitation = useSelector(selectInvitationInfo);
  const organizationId = useOrganizationId();

  useEffect(() => {
    if (id && organizationId) dispatch(showInvitationStart({ id, organizationId }));
  }, [id, dispatch, organizationId]);

  if (isLoading) return <Spinner animation="border" />;
  if (error.message) {
    toast.error(error.message, toastConfig);
  }

  if (id && invitation) {
    return (
      <div>
        <p>{invitation.organization!.name}</p>
        <AcceptInvitation id={id} />
        <RejectInvitation id={id} />
        {error.message && <ToastContainer />}
      </div>
    );
  }
  return null;
}
