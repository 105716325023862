import { JobFormValues } from '../types';
import { TJob } from 'features/job/types';

// // FORM INITIAL VALUES
export let initialValues: JobFormValues = {
  jobTitle: '',
  department: undefined,
  location: {
    country: undefined,
    city: undefined,
    zipCode: '',
  },
  employment: {
    employmentType: undefined,
    experience: undefined,
    education: undefined,
    keywords: [],
  },
  jobDescription: '',
  jobRequirements: '',
  salaryDetails: {
    from: '',
    to: '',
    currency: undefined,
  },
};

export function populateFields(jobInfo: TJob): JobFormValues {
  return {
    jobTitle: jobInfo.title,
    department: {
      value: jobInfo.department.id.toString(),
      label: jobInfo.department.name,
    },
    location: {
      country: jobInfo.country
        ? {
            value: jobInfo.country,
            label: jobInfo.country,
            flag: '',
            currency: jobInfo.salary_currency,
          }
        : undefined,
      city: jobInfo.city
        ? {
            value: jobInfo.city,
            label: jobInfo.city,
          }
        : undefined,
      zipCode: jobInfo.zip_code,
    },
    employment: {
      employmentType: jobInfo.employment_type
        ? {
            label: jobInfo.employment_type,
            value: jobInfo.employment_type,
          }
        : undefined,
      experience: jobInfo.experience
        ? {
            label: jobInfo.experience,
            value: jobInfo.experience,
          }
        : undefined,
      education: jobInfo.education
        ? {
            label: jobInfo.education,
            value: jobInfo.education,
          }
        : undefined,
      keywords: jobInfo.keywords || [],
    },
    jobDescription: jobInfo.description,
    jobRequirements: jobInfo.requirments,
    salaryDetails: {
      from: jobInfo.salary_from,
      to: jobInfo.salary_to,
      currency: jobInfo.salary_currency
        ? {
            label: jobInfo.salary_currency,
            value: jobInfo.salary_currency,
          }
        : undefined,
    },
  };
}
