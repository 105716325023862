import { combineReducers } from 'redux';
import jobDetailsReducer from './create-job/create-job-stages/job-details/job-details.slice';
import { applicationFormReducer } from './create-job/create-job-stages/application-form';
import { pipelineReducer } from './create-job/create-job-stages/workflow';
import { stageApplicationsReducer } from './show-job/list-stage-applicants';

export default combineReducers({
  deatails: jobDetailsReducer,
  applicationForm: applicationFormReducer,
  pipeline: pipelineReducer,
  stageApplications: stageApplicationsReducer,
});
