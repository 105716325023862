import { useCleanup, useMyToast, useRequestStates } from 'hooks';
import * as React from 'react';
import { useState } from 'react';
import { Button } from 'react-bootstrap';
import CreateDepartmentModal from './Modal';
import { selectIsLoading, selectSuccess, selectError, resetToInitials } from './slice';

export { default as createDepartmentReducer } from './slice';
export { onCreateDepartmentStart } from './saga';
export interface ICreateDepartmentProps {}

export default function CreateDepartment(props: ICreateDepartmentProps) {
  const [showModal, setShowModal] = useState<boolean>(false);
  const handleClose = () => setShowModal(false);
  const { loading, error, success } = useRequestStates(
    selectIsLoading,
    selectSuccess,
    selectError,
  );
  useMyToast(error, loading, { success, message: 'Department created successfully!' });
  useCleanup(resetToInitials, error.message, success);

  return (
    <>
      <Button onClick={() => setShowModal(true)} variant="primary">
        + Add department
      </Button>
      <CreateDepartmentModal showModal={showModal} handleClose={handleClose} />
    </>
  );
}
