import { createAction } from '@reduxjs/toolkit';

import { withPayloadType } from 'utils';

interface Payload {
  id: string;
  values: {
    name?: string;
    website?: string;
    slug?: string;
  };
  image?: {
    file: File;
    expires_in: number;
  };
}

export const updateOrganizationStart = createAction(
  'UPDATE_ORGANIZATION::START',
  withPayloadType<Payload>(),
);
