import { useOrganizationId } from 'hooks';
import * as React from 'react';
import { useState } from 'react';
import { ButtonGroup } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import DeleteDepartmentModal from '../delete-department/Modal';
import { DepartmentInfo } from '../types';
import { updateDepartmentStart } from '../update-department/actions';

export interface IDepartmentProps {
  department: DepartmentInfo;
}

export default function Department(props: IDepartmentProps) {
  // state
  const { department } = props;
  const dispatch = useDispatch();
  const [isEditting, setIsEditting] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState('');
  const disabled = !inputValue.trim();
  const [showModal, setShowModal] = useState<boolean>(false);
  const handleClose = () => setShowModal(false);
  const organizationId = useOrganizationId();

  // methods
  const onSave = () => {
    setIsEditting(false);
    if (organizationId) {
      dispatch(
        updateDepartmentStart({
          departmentName: inputValue,
          departmentId: department!.id!.toString(),
          organizationId,
        }),
      );
    }
  };

  const onCancel = () => {
    setIsEditting(false);
  };

  const onEdit = () => {
    setIsEditting(true);
  };

  return (
    <>
      {isEditting ? (
        <div className="stage-editting">
          <input
            autoFocus
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                onSave();
              }
            }}
            onChange={(e) => setInputValue(e.target.value)}
            value={inputValue || department.name}
            className="card-small"
          ></input>
          <ButtonGroup>
            <button onClick={onCancel} className="btn-cancel btn-text btn-reset mr-1">
              Cancel
            </button>
            <button
              disabled={disabled}
              onClick={onSave}
              className={`btn-save btn-text btn-reset ml-4 ${disabled ? 'disabled' : ''}`}
            >
              Save
            </button>
          </ButtonGroup>
        </div>
      ) : (
        <div className="card-small pr-4">
          <label className="form-label form-label--dark">{department?.name}</label>
          <ButtonGroup>
            <button onClick={onEdit} className="btn-edit btn-text btn-reset mr-3">
              Edit
            </button>
            <button
              onClick={() => setShowModal(true)}
              className="btn-delete btn-text btn-reset ml-4"
            >
              Delete
            </button>
            <DeleteDepartmentModal
              department={department}
              handleClose={handleClose}
              showModal={showModal}
            />
          </ButtonGroup>
        </div>
      )}
    </>
  );
}
