import * as React from 'react';
import { JobTitleContext } from '..';

export interface ICreateJobHeaderTitleProps {
  title: string | undefined;
}

export default function CreateJobHeaderTitle(props: ICreateJobHeaderTitleProps) {
  const [jobTitle] = React.useContext(JobTitleContext);

  return <h1 className="header__text">{jobTitle || props.title || 'Create new job'}</h1>;
}
