import api from 'services/api';
import { ResetPassword } from 'redux/types';

export async function resetPassword(values: ResetPassword) {
  const response = await api.put('api/auth/password', {
    user: {
      ...values,
    },
  });

  return response;
}
