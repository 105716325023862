import * as React from 'react';
import { NavLink, useHistory, useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import { StageInfo } from '../../create-job/create-job-stages/workflow/types';
import { selectJobDetails } from '../../create-job/create-job-stages/job-details/job-details.slice';

export interface IJobHeaderProps {}

export default function JobHeader() {
  const match = useRouteMatch();
  const history = useHistory();
  const jobDetails = useSelector(selectJobDetails);
  const isJobDraft = jobDetails?.status === 'draft';

  const onEditJob = () => {
    if (isJobDraft) {
      history.push(`${match.url}/edit/job-details`);
    } else {
      alert('job is pulished');
    }
  };

  return (
    <div className="job__header">
      <div className="job__header-top">
        <h1 className="job__header-text">{jobDetails?.title}</h1>
        <Button onClick={onEditJob} variant="primary">
          Edit
        </Button>
      </div>
      <ul className="job__header-stages">
        {jobDetails &&
          jobDetails.pipeline?.stages.map((stage) => (
            <StagesNav key={stage.id} stage={stage} />
          ))}
      </ul>
    </div>
  );
}

export const StagesNav = (props: { stage: StageInfo; disabled?: boolean }) => {
  const { stage, disabled } = props;
  const match = useRouteMatch();

  return (
    <li className="job__header-stage">
      <NavLink
        className={`${!stage.applications_count || disabled ? 'disabled' : ''}`}
        activeClassName="selected"
        to={`${match.url}/stage/${stage.id}`}
      >
        <span>{stage.applications_count}</span>
        {stage.name}
      </NavLink>
    </li>
  );
};
