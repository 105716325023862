import { call, put, takeLatest } from 'redux-saga/effects';

import {
  setTokenToLocalStorage,
  setTokenToRequestHeader,
  getTokenFromLocalStorage,
} from 'utils';

import { loading, success, failure } from './slice';
import { loginStart } from './actions';
import { login } from './service';
import { Action } from '@reduxjs/toolkit';
import { showUserStart } from 'features/user/show-user/actions';

function* loginFlow(action: Action<unknown>) {
  if (loginStart.match(action)) {
    try {
      yield put(loading());
      const { headers } = yield call(login, action.payload);
      yield setTokenToLocalStorage(headers.authorization);
      yield setTokenToRequestHeader(getTokenFromLocalStorage());
      yield put(success());
      yield put(showUserStart());
    } catch (error) {
      const { message }: { message: string } = error?.response?.data || error;
      const statusCode: number = error?.response?.status;
      yield put(failure({ message, statusCode }));
    }
  }
}

export function* onLoginStart() {
  yield takeLatest(loginStart, loginFlow);
}
