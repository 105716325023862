import { history } from 'redux/store';
import { Action } from '@reduxjs/toolkit';
import { takeLatest, call, put } from 'redux-saga/effects';
import { TemplateInfo } from '../types';

import { setTemplatesInfo } from '../slice';
import { loading, success, failure } from './slice';
import { listTemplatesStart } from './actions';
import { getTemplates } from './service';

function* listTemplatesFlow(action: Action<unknown>) {
  if (listTemplatesStart.match(action)) {
    try {
      yield put(loading());
      const { data } = yield call(getTemplates, action.payload);
      const { data: templatesInfo }: { data: TemplateInfo[] } = data;
      yield put(setTemplatesInfo(templatesInfo));
      yield put(success());
      yield history.push(history.location);
    } catch (error) {
      const { message }: { message: string } = error?.response?.data || error;
      const statusCode: number = error?.response?.status;
      yield put(failure({ message, statusCode }));
    }
  }
}

export function* onListTemplatesStart() {
  yield takeLatest(listTemplatesStart, listTemplatesFlow);
}
