import * as React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { TQuestionNoOptions } from '../../types';
import { Card, Form, ButtonGroup } from 'react-bootstrap';
import { IconDrag } from 'assets/svg/icons';
import { EdittingStateContext } from './QuestionNoOptions';
import { useContext, useState } from 'react';
import SaveQuestion from '../SaveQuestion';
import CancelQuestion from '../CancelQuestion';
import DeleteQuestion from '../DeleteQuestion';

export interface IQuestionNoOptionsEdittingProps {
  question: TQuestionNoOptions;
  index: number;
  newQuestion: boolean;
}

export default function QuestionNoOptionsEditting(
  props: IQuestionNoOptionsEdittingProps,
) {
  const { question, index, newQuestion } = props;
  const { setIsEditting } = useContext(EdittingStateContext);
  const [questionHeadline, setQuestionHeadline] = useState<string>(question.headline);
  return (
    <Draggable draggableId={question.id} index={index}>
      {(provided) => (
        <div
          className="card question-container"
          ref={provided.innerRef}
          {...provided.draggableProps}
        >
          <Card.Body className="question__body">
            <Card.Title className="question__header">
              <div className="drag-handle" {...provided.dragHandleProps}>
                <IconDrag></IconDrag>
              </div>
              {`Question (${question.type.split('_').join(' ').toLowerCase()})`}
            </Card.Title>
            <Form.Control
              type="text"
              value={questionHeadline}
              onChange={(e) => setQuestionHeadline(e.target.value)}
              className="question__headline--editting"
            ></Form.Control>
            <hr className="question__divider" />
            <ButtonGroup>
              <CancelQuestion
                setIsEditting={setIsEditting}
                newQuestion={newQuestion}
                questionId={question.id}
              />
              <SaveQuestion
                questionUpdated={{
                  id: question.id,
                  type: question.type,
                  headline: questionHeadline,
                }}
                setIsEditting={setIsEditting}
                disabled={!questionHeadline.trim()}
              />
            </ButtonGroup>
            <ButtonGroup className="question__btns">
              <DeleteQuestion questionId={question.id} />
            </ButtonGroup>
          </Card.Body>
        </div>
      )}
    </Draggable>
  );
}
