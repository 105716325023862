import { put, takeLatest, call } from 'redux-saga/effects';
import { showJobStart } from './actions';
import { loading, success, failure } from './slice';
import { getJob } from './service';
import { TJob } from '../types';
import { setJobDetails } from '../create-job/create-job-stages/job-details/job-details.slice';
import { Action } from '@reduxjs/toolkit';

function* showJobFlow(action: Action<unknown>) {
  if (showJobStart.match(action)) {
    try {
      yield put(loading());
      const { data } = yield call(getJob, action.payload);
      const { data: job }: { data: TJob } = data;
      yield put(success());
      yield put(setJobDetails(job));
    } catch (error) {
      const { message }: { message: string } = error?.response?.data || error;
      const statusCode: number = error?.response?.status;
      yield put(failure({ message, statusCode }));
    }
  }
}

export default function* onShowJobStart() {
  yield takeLatest(showJobStart, showJobFlow);
}
