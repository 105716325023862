import * as React from 'react';

function SvgUploadfile(props: {}) {
  return (
    <svg width={22.266} height={15.586} {...props}>
      <path
        d="M18.7 6.77a3.344 3.344 0 00-3.117-4.544 3.323 3.323 0 00-1.854.564A5.567 5.567 0 003.34 5.567c0 .094 0 .188.007.282a5.011 5.011 0 001.663 9.737h12.8a4.453 4.453 0 00.89-8.816zm-5.017 2.136h-2.271v3.9a.558.558 0 01-.557.557h-1.67a.558.558 0 01-.557-.557v-3.9H6.353a.556.556 0 01-.393-.95L9.627 4.29a.559.559 0 01.786 0l3.667 3.667a.556.556 0 01-.393.95z"
        fill="#b2b2b2"
      />
    </svg>
  );
}

export default SvgUploadfile;
