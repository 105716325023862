import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Card, Dropdown } from 'react-bootstrap';
import { useRouteMatch } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StagesNav } from 'features/job/show-job/components/JobHeader';
import { updateJobStart } from 'features/job/create-job/create-job-stages/job-details/update-job-details/actions';
import { TJob } from '../../job/types';
import { useOrganizationId } from 'hooks';

export interface IJobCardProps {
  job: TJob;
}

export default function JobCard(props: IJobCardProps) {
  const { job } = props;
  const match = useRouteMatch();
  const organizationId = useOrganizationId();
  const dispatch = useDispatch();

  const onUpdateJobStatus = (eventKey: string | null) => {
    if (eventKey === 'internal') {
      if (organizationId) {
        dispatch(
          updateJobStart({
            jobId: job.id,
            jobDetails:
              job.status === 'published'
                ? { status: 'unpublished' }
                : { status: 'draft' },
            organizationId,
          }),
        );
      }
    } else {
      if (organizationId) {
        dispatch(
          updateJobStart({
            jobId: job.id,
            jobDetails: { status: 'published' },
            organizationId,
          }),
        );
      }
    }
  };

  return (
    <Card className="jobCard">
      <div className="jobCard__top">
        <h3 className="jobCard__title">
          <FontAwesomeIcon icon="thumbtack" />
          <Link to={`${match.url}/${job.id}`}>{job.title}</Link>
        </h3>
        <div className="jobCard__controls">
          <Dropdown onSelect={(e) => onUpdateJobStatus(e)}>
            {job?.status === 'draft' || job?.status === 'unpublished' ? (
              <Dropdown.Toggle variant="secondary" id="jobStatus">
                Internal
              </Dropdown.Toggle>
            ) : (
              <Dropdown.Toggle variant="success" id="jobStatus">
                Published
              </Dropdown.Toggle>
            )}

            <Dropdown.Menu>
              {job?.status === 'published' ? (
                <Dropdown.Item eventKey="internal">Save Internally</Dropdown.Item>
              ) : (
                <Dropdown.Item eventKey="published">Publish</Dropdown.Item>
              )}
            </Dropdown.Menu>
          </Dropdown>
          <FontAwesomeIcon className="ml-2" icon="ellipsis-v" />
        </div>
      </div>

      <ul className="job__header-stages jobCard__stages">
        {job &&
          job.pipeline.stages.map((stage) => (
            <StagesNav key={stage.id} stage={stage} disabled />
          ))}
      </ul>
      <div className="jobCard__footer">
        <p>Candidates: 130 total · Last candidate: 2 days ago</p>
        <p>
          New candidates ( 1 )<FontAwesomeIcon className="ml-2" icon="chevron-down" />
        </p>
      </div>
    </Card>
  );
}
