import * as React from 'react';
import * as Yup from 'yup';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { Button, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';

import { OnEmailSubmit } from '../../authentication/types';
import { inviteUserStart } from './actions';
import { selectIsLoading, selectSuccess, selectError, resetToInitials } from './slice';
import { useCleanup, useOrganizationId } from 'hooks';
import { toastConfig } from 'utils';

export { default as inviteUserReducer } from './slice';

export interface IInviteUserProps {}

// FORM INPUT VALIDATION
const validationSchema = Yup.object({
  email: Yup.string().email('Invalid email address').required('Required'),
});

export default function InviteUser(props: IInviteUserProps) {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectIsLoading);
  const success = useSelector(selectSuccess);
  const error = useSelector(selectError);
  const organizationId = useOrganizationId();

  const onSubmit: OnEmailSubmit = (values, { resetForm }) => {
    resetForm();
    if (organizationId) {
      dispatch(inviteUserStart({ invitationInfo: values, organizationId }));
    }
  };

  useCleanup(resetToInitials, error.message, success);

  if (isLoading) return <Spinner animation="border" />;
  if (success) return <div>success</div>;
  if (error.message) {
    toast.error(error.message, toastConfig);
  }

  return (
    <div>
      <Formik
        initialValues={{ email: '' }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        <Form>
          <label htmlFor="email">Email Address</label>
          <Field name="email" type="email" id="email" />
          <ErrorMessage name="email" />
          <br />
          <Button type="submit" variant="secondary">
            invite user
          </Button>
          <br />
        </Form>
      </Formik>
      {error.message && <ToastContainer />}
    </div>
  );
}
