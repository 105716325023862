import { useOrganizationId } from 'hooks';
import * as React from 'react';
// import { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { DepartmentInfo } from '../types';
import { deleteDepartmentStart } from './actions';
// import { selectIsLoading, selectSuccess, selectError } from './delete-department.slice';

export interface IDeleteDepartmentModalProps {
  department: DepartmentInfo;
  handleClose: () => void;
  showModal: boolean;
}

export default function DeleteDepartmentModal(props: IDeleteDepartmentModalProps) {
  const { showModal, handleClose, department } = props;
  const dispatch = useDispatch();
  // const [departmentName, setDepartmentName] = useState('');
  // const { loading, error, success } = useRequestStates(
  //   selectIsLoading,
  //   selectSuccess,
  //   selectError,
  // );
  const organizationId = useOrganizationId();

  // useMyToast(error, loading);
  // useCleanup(resetToInitials, error.message, success);

  const deleteDepartment = () => {
    handleClose();
    if (organizationId) {
      dispatch(
        deleteDepartmentStart({
          departmentId: department!.id!.toString(),
          organizationId,
        }),
      );
    }
  };
  return (
    <div className="position-absolute">
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add new department</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>are you sure you want to delete {department.name} department</p>{' '}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={deleteDepartment}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      {/* {(error.message || error.statusCode === 500 || success) && <ToastContainer />} */}
    </div>
  );
}
