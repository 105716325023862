import api from 'services/api';
import { UpdateProfileValues } from './actions';

interface Values extends UpdateProfileValues {
  avatar?: string;
}

export async function updateProfileInfo(values: Values) {
  const response = await api.put('/api/dashboard/update-profile', {
    user: {
      ...values,
    },
  });

  return response;
}
