import * as React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { TRoute } from '.';
import { selectIsAuthenticated } from '../features/authentication/slice';

function useAuth() {
  const isAuthenticated = useSelector(selectIsAuthenticated);
  return isAuthenticated;
}

export default function PublicRoute(props: TRoute) {
  const { component: Component, routes, ...rest } = props;
  const isAuthenticated = useAuth();
  return (
    <Route
      {...rest}
      render={() =>
        isAuthenticated ? <Redirect to="/dashboard" /> : <Component routes={routes} />
      }
    />
  );
}
