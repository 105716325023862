import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TJob } from '../job/types';
import { RootState } from 'redux/root-reducer';

const initialState: { list: TJob[] } = { list: [] };

const jobsSlice = createSlice({
  name: 'JOBS',
  initialState,
  reducers: {
    setJobs: (state, action: PayloadAction<TJob[]>) => {
      state.list = action.payload;
    },
  },
});

// ACTIONS
export const { setJobs } = jobsSlice.actions;

// REDUCER
export default jobsSlice.reducer;

// SELECTORS
export const selectJobs = (state: RootState) => state.jobs.list;
