import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RenderingState, ErrorInfo } from 'redux/types';
import { RootState } from 'redux/root-reducer';

export const initialState: RenderingState = {
  isLoading: false,
  success: false,
  error: {
    message: '',
    statusCode: null,
  },
};

export const signupEmployeeSlice = createSlice({
  name: 'SIGN_UP_EMPLOYEE',
  initialState,
  reducers: {
    loading: (state) => {
      state.isLoading = true;
    },
    success: (state) => {
      state.isLoading = false;
      state.success = true;
      state.error.message = '';
      state.error.statusCode = null;
    },
    failure: (state, action: PayloadAction<ErrorInfo>) => {
      state.isLoading = false;
      state.error.message = action.payload.message;
      state.error.statusCode = action.payload.statusCode;
    },
    resetToInitials: (state) => {
      state.isLoading = initialState.isLoading;
      state.success = initialState.success;
      state.error = initialState.error;
    },
  },
});

// REDECER
export default signupEmployeeSlice.reducer;

// ACTIONS
export const { loading, success, failure, resetToInitials } = signupEmployeeSlice.actions;

// SELECTORS
export const selectIsLoading = (state: RootState) =>
  state.ui.authentication.signupEmployee.isLoading;
export const selectSuccess = (state: RootState) =>
  state.ui.authentication.signupEmployee.success;
export const selectError = (state: RootState) =>
  state.ui.authentication.signupEmployee.error;
