import { createAction } from '@reduxjs/toolkit';

import { withPayloadType } from 'utils';

interface Payload {
  values: {
    name: string;
    summary: string;
    slug: string;
  };
  image: {
    file: File;
    expires_in: number;
  };
}

export const createOrganizationStart = createAction(
  'CREATE_ORGANIZATION_START',
  withPayloadType<Payload>(),
);
