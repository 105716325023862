import { listDepartmentsStart } from '../list-departments/actions';
import { takeLatest, call, put } from 'redux-saga/effects';

import { loading, success, failure } from './slice';
import { updateDepartmentStart } from './actions';
import { updateDepartment } from './service';
import { history } from 'redux/store';
import { Action } from '@reduxjs/toolkit';

function* updateDepartmentFlow(action: Action<unknown>) {
  if (updateDepartmentStart.match(action)) {
    try {
      yield put(loading());
      yield call(updateDepartment, action.payload);
      yield put(success());
      yield put(listDepartmentsStart({ organizationId: action.payload.organizationId }));
      yield history.push(history.location);
    } catch (error) {
      const { message }: { message: string } = error?.response?.data || error;
      const statusCode: number = error?.response?.status;
      yield put(failure({ message, statusCode }));
    }
  }
}

export function* onUpdateDepartmentStart() {
  yield takeLatest(updateDepartmentStart, updateDepartmentFlow);
}
