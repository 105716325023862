import * as React from 'react';
import { Toast } from 'react-bootstrap';

export interface ICustomToastProps {
  status: string;
  message: string;
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function CustomToast(props: ICustomToastProps) {
  const { status, message, show, setShow } = props;

  return (
    <Toast show={show} onClose={() => setShow(false)}>
      <Toast.Header>
        <img src="holder.js/20x20?text=%20" className="rounded mr-2" alt="toast" />
        <strong className="mr-auto">{status}</strong>
      </Toast.Header>
      <Toast.Body>{message}</Toast.Body>
    </Toast>
  );
}
