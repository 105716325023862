import api from 'services/api';

interface Params {
  name: string;
  templatable_type: 'Pipeline';
  templatable_id: number;
  organizationId: string;
}

export async function createTemplate({
  name,
  templatable_type,
  templatable_id,
  organizationId,
}: Params) {
  const response = await api.post(
    `api/dashboard/organizations/${organizationId}/templates`,
    {
      template: {
        name,
        templatable_type,
        templatable_id,
      },
    },
  );
  return response;
}
