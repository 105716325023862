import * as React from 'react';
import { Link, Switch, Route, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Spinner } from 'react-bootstrap';
import { useCleanup, useOrganizationId } from 'hooks';
import { toast, ToastContainer } from 'react-toastify';

import { listInvitationsStart } from './actions';
import { selectIsLoading, selectSuccess, selectError, resetToInitials } from './slice';
import { selectInvitations } from '../slice';
import ShowInvitation from '../show-invitation';
import { toastConfig } from 'utils';

export { default as listInvitationsReducer } from './slice';
export interface IListInvitationsProps {}

export default function ListInvitations(props: IListInvitationsProps) {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectIsLoading);
  const success = useSelector(selectSuccess);
  const error = useSelector(selectError);
  const invitations = useSelector(selectInvitations);
  const { path, url } = useRouteMatch();
  const organizationId = useOrganizationId();
  useCleanup(resetToInitials, error.message, success);

  if (isLoading) return <Spinner animation="border" />;
  if (error.message) {
    toast.error(error.message, toastConfig);
  }

  return (
    <div>
      <Button
        onClick={() => {
          if (organizationId) {
            dispatch(listInvitationsStart({ organizationId }));
          }
        }}
        variant="primary"
      >
        list invitations
      </Button>
      {invitations.map((invitation) => (
        <Link key={invitation.id} to={`${url}/${invitation.id}`}>
          {invitation.organization!.name}
        </Link>
      ))}
      <Switch>
        <Route path={`${path}/:id`}>
          <ShowInvitation />
        </Route>
      </Switch>
      {error.message && <ToastContainer />}
    </div>
  );
}
