import { Questions } from './questions/types';

export interface TApplicationFormInputs {
  name: TFieldState;
  email: TFieldState;
  phone_number: TFieldState;
  education: TFieldState;
  experience: TFieldState;
  summary: TFieldState;
  resume: TFieldState;
  cover_letter: TFieldState;
}

export enum FieldStates {
  Required = 'required',
  Optional = 'optional',
  Off = 'off',
}

export type TFieldState = FieldStates.Required | FieldStates.Optional | FieldStates.Off;

export interface TApplicationForm {
  id: string | number;
  email: FieldStates.Required;
  name: FieldStates.Required;
  phone_number: TFieldState;
  education: TFieldState;
  experience: TFieldState;
  summary: TFieldState;
  resume: TFieldState;
  cover_letter: TFieldState;
  created_at: string;
  updated_at: string;
  application_form_details: TApplicationFormDetail[] | [];
}

export interface TApplicationFormDetail {
  id: number;
  title: string;
  required: boolean;
  schema: {
    type: Questions;
    answers?: string[];
  };
  position: number;
  created_at?: string;
  updated_at?: string;
}
