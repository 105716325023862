import * as React from 'react';
import { Option } from '../types';
import Select, { ValueType } from 'react-select';

export interface ISelectMemoizedProps {
  value: Option<any>;
  onSelect: (value: ValueType<Option<string>>) => void;
  placeholder: string;
  selectStyles: any;
  options: Option<any>[];
  filterOption?: any;
}

const SelectMemoized = React.memo(function ({
  value,
  onSelect,
  placeholder,
  selectStyles,
  options,
  filterOption,
}: ISelectMemoizedProps) {
  return (
    <Select
      value={value}
      placeholder={placeholder}
      options={options}
      styles={selectStyles}
      onChange={(value) => onSelect(value)}
      filterOption={filterOption}
    />
  );
});

export default SelectMemoized;
