import { createAction } from '@reduxjs/toolkit';
import { withPayloadType } from 'utils';

export const updateApplicationStart = createAction(
  'UPDATE_APPLICATION::START',
  withPayloadType<{
    jobId: string;
    stageId: string;
    newStageId?: string | null;
    applicationId: string | undefined;
    disqualified?: boolean;
    organizationId: string;
    organizationSlug: string;
  }>(),
);
