import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { FormGroup, Form as FormStrap, Button, Card, Spinner } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import * as Yup from 'yup';

import { useCleanup } from 'hooks';
import { OnEmailSubmit } from '../types';
import HomeHeader from 'pages/home/HomeHeader';
import { selectError, selectSuccess, selectIsLoading, resetToInitials } from './slice';
import { sendResetPassEmailStart } from './actions';
import { toastConfig } from 'utils';

export { onSendResetPassEmailStart } from './saga';
export { default as sendResetPassEmailReducer } from './slice';

export interface IResetPassEmailProps {}

// FORM INPUT VALIDATION
const validationSchema = Yup.object({
  email: Yup.string().email('Invalid email address').required('Required'),
});

// eslint-disable-next-line no-unused-vars
export default function ResetPassEmail(props: IResetPassEmailProps) {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectIsLoading);
  const success = useSelector(selectSuccess);
  const error = useSelector(selectError);

  const onSubmit: OnEmailSubmit = (values, { resetForm }) => {
    resetForm();
    dispatch(sendResetPassEmailStart(values));
  };

  useCleanup(resetToInitials, error.message, success);

  if (isLoading) return <Spinner animation="border" />;
  if (success) return <div>success</div>;
  if (error.message) {
    toast.error(error.message, toastConfig);
  }

  return (
    <div className="home">
      <HomeHeader />
      <div className="hero">
        <Card className="hero__content auth">
          <Card.Header>Reset Password</Card.Header>
          <Card.Body>
            <Formik
              initialValues={{ email: '' }}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              <Form>
                <FormGroup>
                  <FormStrap.Label htmlFor="email">Email Address</FormStrap.Label>
                  <Field
                    placeholder="Type your e-mail"
                    name="email"
                    type="email"
                    id="email"
                    as={FormStrap.Control}
                  />
                  <ErrorMessage component="div" className="auth__error" name="email" />
                </FormGroup>
                <Button className="auth__btn" type="submit">
                  Send
                </Button>
              </Form>
            </Formik>
          </Card.Body>
        </Card>
      </div>
      {error.message && <ToastContainer />}
    </div>
  );
}
