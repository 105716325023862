import * as React from 'react';
import { useContext } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Formik, FormikHandlers } from 'formik';
import { useSelector } from 'react-redux';
import { Card } from 'react-bootstrap';
import { useCreateJobRedirection, useUnsavedChangesPopup } from 'hooks';
import {
  RedirectToContext,
  IsDirtyContext,
  ApplicationFormModalContext,
} from 'features/job/create-job';
import { FieldStates } from '../types';
import Questions, { normalizeToQuestions, normalizeQuestions } from '../questions';
import { selectApplicationForm } from '../slice';
import ApplicationFormField, {
  IApplicationFormFieldProps,
} from '../components/ApplicationFormField';
import { QuestionsAction } from '../questions/types';
import questionsReducer from '../questions/reducer';
import UpdateApplicationForm from '../update-application-form';
import { setQuestions } from '../questions/actions';
import AddQuestion from '../questions/components/AddQuestion';

export { default as showApplicationFormReducer } from './slice';
export { default as onShowApplicationFormStart } from './saga';

export interface IShowApplicationFormProps {}

export const QuestionsDispatch: React.Context<Partial<
  React.Dispatch<QuestionsAction>
>> = React.createContext({});

type TMemoizedProps = IApplicationFormFieldProps &
  Partial<Pick<FormikHandlers, 'handleChange'>>;

export default function ShowApplicationForm() {
  // STOP ALL FORMIK CHILDREN FROM RE-RENDERING EVERY TIME FORMIK STATE CHANGES
  const ApplicationFormFieldMemo = React.memo(
    ({ fieldValue, handleChange, name, label, mandatory = false }: TMemoizedProps) => (
      <ApplicationFormField
        name={name}
        label={label}
        fieldValue={fieldValue}
        handleChange={handleChange}
        mandatory={mandatory}
      />
    ),
  );
  const applicationFormInfo = useSelector(selectApplicationForm).info;
  const [questions, questionsDispatch] = React.useReducer(questionsReducer, []);
  const [saveDefaultChecked, setSaveDefaultChecked] = React.useState(false);
  const { isDirty, setDirty } = useContext(IsDirtyContext);
  const { redirectTo, setRedirectTo } = useContext(RedirectToContext);
  const match = useRouteMatch();
  const { setShowApplicationFormModal } = useContext(ApplicationFormModalContext);
  useCreateJobRedirection(redirectTo, setRedirectTo, isDirty, match);
  useUnsavedChangesPopup(isDirty);

  // SET AND RESET QUESTIONS
  React.useEffect(() => {
    if (applicationFormInfo) {
      questionsDispatch(
        setQuestions(normalizeToQuestions(applicationFormInfo.application_form_details)),
      );
    }
    return () => {
      questionsDispatch(setQuestions([]));
      if (setShowApplicationFormModal) setShowApplicationFormModal(false);
    };
  }, [applicationFormInfo, setShowApplicationFormModal]);

  return (
    applicationFormInfo && (
      <Card>
        <Card.Body>
          <Card.Title>Application form</Card.Title>
          <label>
            <input
              type="checkbox"
              checked={saveDefaultChecked}
              onChange={() => setSaveDefaultChecked(!saveDefaultChecked)}
            />{' '}
            Save as default
          </label>
          <Card.Subtitle>Personal info</Card.Subtitle>
          <Formik
            initialValues={applicationFormInfo}
            onSubmit={(values, { setSubmitting }) => {
              setTimeout(() => {
                console.log(
                  JSON.stringify(
                    {
                      ...values,
                      application_form_details: normalizeQuestions(
                        questions,
                        applicationFormInfo.application_form_details,
                      ),
                    },
                    null,
                    2,
                  ),
                );
                setSubmitting(false);
              }, 1000);
            }}
          >
            {({
              handleChange,
              handleSubmit,
              values,
              isSubmitting,
              setSubmitting,
              dirty,
            }) => {
              return (
                <form
                  style={{ position: 'static' }}
                  onBlur={() => {
                    if (setDirty) setDirty(dirty);
                  }}
                  onMouseOut={() => {
                    if (setDirty) setDirty(dirty);
                  }}
                  onSubmit={handleSubmit}
                >
                  <ApplicationFormFieldMemo
                    name="name"
                    label="Name"
                    fieldValue={FieldStates.Required}
                    mandatory
                  />
                  <ApplicationFormFieldMemo
                    name="email"
                    label="E-mail address"
                    fieldValue={FieldStates.Required}
                    mandatory
                  />
                  <ApplicationFormFieldMemo
                    name="phone_number"
                    label="Phone number"
                    fieldValue={values.phone_number}
                    handleChange={handleChange}
                  />
                  <Card.Subtitle>Profile</Card.Subtitle>
                  <ApplicationFormFieldMemo
                    name="education"
                    label="Education"
                    fieldValue={values.education}
                    handleChange={handleChange}
                  />
                  <ApplicationFormFieldMemo
                    name="experience"
                    label="Experience"
                    fieldValue={values.experience}
                    handleChange={handleChange}
                  />
                  <ApplicationFormFieldMemo
                    name="summary"
                    label="Summary"
                    fieldValue={values.summary}
                    handleChange={handleChange}
                  />
                  <ApplicationFormFieldMemo
                    name="resume"
                    label="Resume"
                    fieldValue={values.resume}
                    handleChange={handleChange}
                  />
                  <ApplicationFormFieldMemo
                    name="cover_letter"
                    label="Cover letter"
                    fieldValue={values.cover_letter}
                    handleChange={handleChange}
                  />
                  <hr />
                  <Card.Title>Additional questoins</Card.Title>
                  <QuestionsDispatch.Provider value={questionsDispatch}>
                    <Questions questions={questions} />
                    <div className="btns-space-between">
                      <AddQuestion />
                      <UpdateApplicationForm
                        disabled={isSubmitting}
                        questions={questions}
                        values={values}
                        setSubmitting={setSubmitting}
                        saveDefault={saveDefaultChecked}
                      />
                    </div>
                  </QuestionsDispatch.Provider>
                </form>
              );
            }}
          </Formik>
        </Card.Body>
      </Card>
    )
  );
}
