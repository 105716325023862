import { useRequestStates } from 'hooks';
import {
  selectIsLoading as selectUpdateJobDetailsIsLoading,
  selectSuccess as selectUpdateJobDetailsSuccess,
  selectError as selectUpdateJobDetailsError,
} from '../create-job-stages/job-details/update-job-details/slice';

export default function () {
  const {
    loading: updateJobDetailsLoading,
    success: updateJobDetailsSuccess,
    error: updateJobDetailsError,
  } = useRequestStates(
    selectUpdateJobDetailsIsLoading,
    selectUpdateJobDetailsSuccess,
    selectUpdateJobDetailsError,
  );

  return { updateJobDetailsLoading, updateJobDetailsSuccess, updateJobDetailsError };
}
