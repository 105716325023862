import * as React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { useAuth } from 'hooks';
import {
  selectIsLoading,
  selectSuccess,
  selectError,
} from 'services/session/persist-user-session.slice';
import { TRoute } from '.';
import { Spinner } from 'react-bootstrap';
import { toastConfig } from 'utils';

export default function PrivateRoute(props: TRoute) {
  const { component: Component, ...rest } = props;

  const isLoading = useSelector(selectIsLoading);
  const success = useSelector(selectSuccess);
  const error = useSelector(selectError);
  const isAuthenticated = useAuth();
  const authAndSuccess = success && isAuthenticated;

  if (isLoading) return <Spinner animation="border" />;
  if (error.message) {
    toast.error(error.message, toastConfig);
  }

  return (
    <>
      <Route
        {...rest}
        render={() =>
          isAuthenticated || authAndSuccess ? (
            <Component routes={rest.routes} />
          ) : (
            <Redirect to="/login" />
          )
        }
      />
      {error.message && <ToastContainer />}
    </>
  );
}
