import * as React from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
export interface ICancelTemplateModalProps {
  handleClose: () => void;
  showModal: boolean;
  onConfirm: any;
}

export default function CancelTemplateModal(props: ICancelTemplateModalProps) {
  const { showModal, handleClose, onConfirm } = props;

  return (
    <div className="position-absolute">
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Exit</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Label>htendam</Form.Label>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={onConfirm}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
