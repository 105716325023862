import { useOrganizationId } from 'hooks';
import * as React from 'react';
import { createContext, useEffect, useState } from 'react';
import { Button, Card, ListGroup } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';
import Template from '../components/Template';
import CreateTemplate from '../create-template';
import CreateTemplateModal from '../create-template/components/CreateTemplateModal';
import { selectTemplatesInfo } from '../slice';
import { listTemplatesStart } from './actions';
export { default as listTemplatesReducer } from './slice';
export { onListTemplatesStart } from './saga';

export interface ITemplatesProps {}

export default function Templates(props: ITemplatesProps) {
  const dispatch = useDispatch();
  const templates = useSelector(selectTemplatesInfo);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [isNewTemplate, setIsNewTemplate] = useState(false);
  const [templateName, setTemplateName] = useState<string>('');
  const organizationId = useOrganizationId();

  // fetch templates
  useEffect(() => {
    if (organizationId) {
      dispatch(listTemplatesStart({ organizationId }));
    }
  }, [organizationId]);

  const handleClose = () => {
    setIsNewTemplate(false);
    setShowModal(false);
    setTemplateName('');
  };
  const onSave = () => {
    setShowModal(false);
    setIsNewTemplate(true);
  };

  return (
    <>
      {!isNewTemplate && (
        <Card.Body>
          <Card.Title className="mb-0">Recruiting pipeline</Card.Title>
          <div className="inputs-flex mb-4">
            <small style={{ width: '70%', color: '#999999' }}>
              Customize pipeline and manage your stages in your hiring process.
            </small>
            <Button onClick={() => setShowModal(true)} variant="primary">
              + Add template
            </Button>
            <CreateTemplateModal
              onSave={onSave}
              handleClose={handleClose}
              showModal={showModal}
              templateNameState={{ templateName, setTemplateName }}
            />
          </div>
          <ListGroup>
            {templates?.map((template) => (
              <Template key={template.id} template={template} />
            ))}
          </ListGroup>
        </Card.Body>
      )}
      {isNewTemplate && (
        <CreateTemplate
          setIsNewTemplate={setIsNewTemplate}
          templateNameState={{ templateName, setTemplateName }}
        />
      )}
    </>
  );
}
