import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RenderingState, ErrorInfo } from 'redux/types';
import { RootState } from 'redux/root-reducer';

const initialState: RenderingState = {
  isLoading: false,
  success: false,
  error: {
    message: '',
    statusCode: null,
  },
};

const revokeInvitationSlice = createSlice({
  name: 'REJECT_INVITATION',
  initialState,
  reducers: {
    loading: (state) => {
      state.isLoading = true;
    },
    success: (state) => {
      state.isLoading = false;
      state.error.message = '';
      state.error.statusCode = null;
      state.success = true;
    },
    failure: (state, action: PayloadAction<ErrorInfo>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    resetToInitials: (state) => {
      state.isLoading = initialState.isLoading;
      state.success = initialState.success;
      state.error = initialState.error;
    },
  },
});

// REDUCER
export default revokeInvitationSlice.reducer;

// ACTIONS
export const {
  loading,
  failure,
  success,
  resetToInitials,
} = revokeInvitationSlice.actions;

// SELECTORS
export const selectIsLoading = (state: RootState) =>
  state.ui.invitations.revokeInvitation.isLoading;
export const selectSuccess = (state: RootState) =>
  state.ui.invitations.revokeInvitation.success;
export const selectError = (state: RootState) =>
  state.ui.invitations.revokeInvitation.error;
