import * as React from 'react';

export interface IEditQuestionProps {
  setIsEditting: React.Dispatch<React.SetStateAction<boolean>> | undefined;
}

export default function EditQuestion(props: IEditQuestionProps) {
  const { setIsEditting } = props;
  return (
    <button
      onClick={() => (setIsEditting ? setIsEditting(true) : null)}
      type="button"
      className="btn-edit btn-text btn-reset mr-1"
    >
      Edit
    </button>
  );
}
