import { takeLatest, put, call } from 'redux-saga/effects';

import { removeTokenFromLocalStorage, setTokenToRequestHeader } from 'utils';
import { history } from 'redux/store';
import { resetUserInfo } from 'features/user/slice';
import { resetOrganizationInfo } from 'features/organization/slice';
import { removeAuthenticated } from '../slice';
import { loading, success, failure } from './slice';
import { logoutStart } from './actions';
import { logout } from './service';

function* logoutFlow() {
  try {
    yield put(loading());
    yield call(logout);
    yield put(success());
    yield put(removeAuthenticated());
    yield put(resetUserInfo());
    yield put(resetOrganizationInfo());
    yield removeTokenFromLocalStorage();
    yield setTokenToRequestHeader(null);
    yield history.push('/login');
  } catch (error) {
    const { message }: { message: string } = error?.response?.data || error;
    const statusCode: number = error?.response?.status;
    yield put(failure({ message, statusCode }));
  }
}

export function* onLogoutStart() {
  yield takeLatest(logoutStart, logoutFlow);
}
