import { combineReducers } from 'redux';
import workflowReducer from './create-job-stages/workflow/workflow-ui.reducer';
import { createJobReducer } from '.';
import applicationFormUiReducer from './create-job-stages/application-form/ui.reducer';

export default combineReducers({
  jobDetails: createJobReducer,
  workflow: workflowReducer,
  applicationForm: applicationFormUiReducer,
});
