import { put, takeLatest, call } from 'redux-saga/effects';
import { updateApplicationStart } from './actions';
import { loading, success, failure } from './slice';
import { updateApplication } from './service';
import { Action } from '@reduxjs/toolkit';
import { history } from 'redux/store';
import { listStageApplicationsStart } from '../list-stage-applicants/actions';

function* updateApplicationFlow(action: Action<unknown>) {
  if (updateApplicationStart.match(action)) {
    try {
      yield put(loading());
      yield call(updateApplication, action.payload);
      yield put(success());
      yield put(
        listStageApplicationsStart({
          job_id: action.payload.jobId,
          stage_id: action.payload.stageId,
          organizationId: action.payload.organizationId,
        }),
      );
      if (action.payload.newStageId) {
        history.push(
          `/dashboard/${action.payload.organizationSlug}/jobs/${action.payload.jobId}/stage/${action.payload.newStageId}/application`,
        );
      }
    } catch (error) {
      const { message }: { message: string } = error?.response?.data || error;
      const statusCode: number = error?.response?.status;
      yield put(failure({ message, statusCode }));
    }
  }
}

export default function* onUpdateApplicationStart() {
  yield takeLatest(updateApplicationStart, updateApplicationFlow);
}
