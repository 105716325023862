import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { toast, ToastContainer } from 'react-toastify';
import { FormGroup, Form as FormStrap, Button, Card, Spinner } from 'react-bootstrap';

import * as Yup from 'yup';

import HomeHeader from 'pages/home/HomeHeader';
import { useCleanup } from 'hooks';
import { OnSignupEmployeeSubmit } from '../../types';
import { selectError, selectIsLoading, selectSuccess, resetToInitials } from './slice';
import { signupEmployeeStart } from './actions';
import { toastConfig } from 'utils';

export { onSignupEmployeeStart } from './saga';
export { default as signupEmployeeReducer } from './slice';

export interface ISignupEmployeeProps {}

// FORM VALIDATION
const validationSchema = Yup.object({
  first_name: Yup.string()
    .min(3, 'Name is too short')
    .max(15, 'Must be 15 characters or less')
    .required('Required'),
  last_name: Yup.string()
    .min(3, 'Name is too short')
    .max(20, 'Must be 20 characters or less')
    .required('Required'),
  email: Yup.string().email('Invalid email address').required('Required'),
  password: Yup.string()
    .min(6, 'Password has to be longer than 6 characters!')
    .required('Password is required!'),
  password_confirmation: Yup.string().oneOf(
    [Yup.ref('password'), null],
    'Passwords must match',
  ),
});

// eslint-disable-next-line no-unused-vars
export default function SignupEmployee() {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectIsLoading);
  const success = useSelector(selectSuccess);
  const error = useSelector(selectError);

  useCleanup(resetToInitials, error.message, success);

  const onSubmit: OnSignupEmployeeSubmit = (values, { setSubmitting, resetForm }) => {
    setSubmitting(false);
    resetForm();
    dispatch(signupEmployeeStart(values));
  };

  if (isLoading) return <Spinner animation="border" />;
  if (success) {
    toast.success('Confirmation email has been sent to you!', toastConfig);
  }
  if (error.message) {
    toast.error(error.message, toastConfig);
  }

  return (
    <div className="home">
      <HomeHeader />
      <div className="hero">
        <Card className="hero__content auth">
          <Card.Header>Sign Up</Card.Header>
          <Card.Body>
            <Formik
              initialValues={{
                first_name: '',
                last_name: '',
                email: '',
                password: '',
                password_confirmation: '',
              }}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              <Form>
                <FormGroup
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    margin: '0',
                  }}
                >
                  <FormGroup className="mr-4 ">
                    <FormStrap.Label htmlFor="first_name">First Name</FormStrap.Label>
                    <Field
                      placeholder="Type your first name"
                      as={FormStrap.Control}
                      name="first_name"
                      type="text"
                    />
                    <ErrorMessage
                      component="div"
                      className="auth__error"
                      name="first_name"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormStrap.Label htmlFor="last_name">Last Name</FormStrap.Label>
                    <Field
                      placeholder="Type your last name"
                      as={FormStrap.Control}
                      name="last_name"
                      type="text"
                    />
                    <ErrorMessage
                      component="div"
                      className="auth__error"
                      name="last_name"
                    />
                  </FormGroup>
                </FormGroup>
                <FormGroup>
                  <FormStrap.Label htmlFor="email">Email Address</FormStrap.Label>
                  <Field
                    placeholder="Enter your e-mail"
                    as={FormStrap.Control}
                    name="email"
                    type="email"
                  />
                  <ErrorMessage component="div" className="auth__error" name="email" />
                </FormGroup>
                <FormGroup>
                  <FormStrap.Label htmlFor="password">password</FormStrap.Label>
                  <Field
                    placeholder="Enter your password"
                    as={FormStrap.Control}
                    name="password"
                    type="password"
                  />
                  <ErrorMessage component="div" className="auth__error" name="password" />
                </FormGroup>
                <FormGroup>
                  <FormStrap.Label htmlFor="password_confirmation">
                    password confirmation
                  </FormStrap.Label>
                  <Field
                    placeholder="Enter your password"
                    as={FormStrap.Control}
                    name="password_confirmation"
                    type="password"
                  />
                  <ErrorMessage
                    component="div"
                    className="auth__error"
                    name="password_confirmation"
                  />
                </FormGroup>
                <Button className="auth__btn" variant="primary" type="submit">
                  SIGN UP
                </Button>
              </Form>
            </Formik>
          </Card.Body>
        </Card>
        <div className="hero__bg2 hero__bg" />
      </div>
      {error.message || (success && <ToastContainer />)}
    </div>
  );
}
