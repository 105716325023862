import { put, call } from 'redux-saga/effects';

import { UploadTokens } from 'redux/types';
import { setUploadTokens } from './get-upload-tokens.slice';
import { tokensLoading, tokensSuccess, tokensFailure } from './upload-avatar.slice';
import { getUploadTokens } from './get-upload-tokens.service';

export function* getUploadTokensFlow(filename: string, expires_in?: number) {
  try {
    yield put(tokensLoading());
    const { data } = yield call(getUploadTokens, filename, expires_in);
    const {
      data: { file_url, upload_url },
    }: { data: UploadTokens } = data;
    yield put(tokensSuccess());
    yield put(setUploadTokens({ file_url, upload_url }));
  } catch (error) {
    const { message }: { message: string } = error?.response?.data || error;
    const statusCode: number = error?.response?.status;
    yield put(tokensFailure({ message, statusCode }));
  }
}
