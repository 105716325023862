import * as React from 'react';
import { Card, FormGroup, Form } from 'react-bootstrap';
import { createFilter, Styles } from 'react-select';
import { FieldProps, Field } from 'formik';
import { Config } from 'react-select/src/filters';
import { JobFormValues } from '../types';
import { StylesContext } from '../show-job-details';
import { useSelector } from 'react-redux';
import { Option } from '../types';
import SelectMemoized from './SelectMemoized';
import { selectJobDetails } from '../job-details.slice';
import countries from '../countries';
import { useState } from 'react';
import egyptCities from '../egyptCities';

export type SetLocationState = React.Dispatch<
  React.SetStateAction<Option<string>[] | undefined>
>;

export interface ILocationDetailsProps {}

const filterConfig: Config = {
  ignoreCase: true,
  trim: true,
  matchFrom: 'start',
  ignoreAccents: true,
};

const flag: (countryCode: string | undefined) => React.CSSProperties = (
  countryCode: string | undefined,
) => ({
  display: 'flex',
  alignItems: 'center',

  ':before': {
    content: '" "',
    height: '24px',
    width: '24px',
    marginRight: '10px',
    backgroundImage: countryCode
      ? `url(https://www.countryflags.io/${countryCode}/flat/24.png)`
      : 'initial',
  },
});

export default function LocationDetails(props: ILocationDetailsProps) {
  const jobDetails = useSelector(selectJobDetails);
  const [selectedCountry, setSelectedCountry] = useState<Option<string>>({
    label: jobDetails?.country || '',
    value: jobDetails?.country || '',
  });
  const [selectedCity, setSelectedCity] = useState<Option<string> | undefined>({
    label: jobDetails?.city || '',
    value: jobDetails?.city || '',
  });
  const selectStyles = React.useContext(StylesContext);
  const countryStyles: Partial<Styles> | undefined = {
    singleValue: (styles) => ({
      ...styles,
      ...flag(selectedCountry?.value || jobDetails?.country),
    }),
  };
  const options = countries.map((country) => ({
    value: country.code,
    label: country.name,
  }));

  const citiesOptions = egyptCities.map((city) => ({
    label: city.governorate_name_en,
    value: city.governorate_name_en,
  }));

  return (
    <Card>
      <Card.Body>
        <Card.Title>Location</Card.Title>
        <div className="inputs-flex">
          <FormGroup className="input-element" controlId="country">
            <Form.Label>Country</Form.Label>
            <Field name="location.country">
              {({
                form: { setFieldValue },
                field: { value, name },
              }: FieldProps<Option<string>, JobFormValues>) => (
                <SelectMemoized
                  value={value}
                  onSelect={(value) => {
                    setFieldValue(name, value);
                    setSelectedCountry(value as Option<string>);
                    setSelectedCity(undefined);
                  }}
                  options={options}
                  filterOption={createFilter(filterConfig)}
                  selectStyles={{ ...countryStyles, ...selectStyles }}
                  placeholder="Choose country"
                />
              )}
            </Field>
          </FormGroup>
          <FormGroup className="input-element" controlId="city">
            <Form.Label>City</Form.Label>
            <Field name="location.city">
              {({
                form: { setFieldValue },
                field: { value, name },
              }: FieldProps<any, JobFormValues>) => (
                <SelectMemoized
                  value={selectedCity || { label: '', value: '' }}
                  onSelect={(value) => {
                    setFieldValue(name, value);
                    setSelectedCity(value as Option<string>);
                  }}
                  options={
                    selectedCountry?.value === 'EG'
                      ? citiesOptions
                      : [{ label: '', value: '' }]
                  }
                  selectStyles={selectStyles}
                  placeholder="Choose city"
                />
              )}
            </Field>
          </FormGroup>
          <FormGroup className="input-element" controlId="zipCode">
            <Form.Label>Zip Code</Form.Label>
            <Field type="text" name="location.zipCode">
              {({ meta, field }: FieldProps) => (
                <>
                  <Form.Control {...field} placeholder="Enter code" />
                  {meta.touched && meta.error && (
                    <div className="error">enter a number</div>
                  )}
                </>
              )}
            </Field>
          </FormGroup>
        </div>
      </Card.Body>
    </Card>
  );
}
