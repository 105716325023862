import * as React from 'react';
import { Dispatch, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import { Button, Card, ListGroup } from 'react-bootstrap';
import Stage from 'features/job/create-job/create-job-stages/workflow/pipeline/components/Stage';
import StageStatic from 'features/job/create-job/create-job-stages/workflow/pipeline/components/StageStatic';
import { StageInfo } from 'features/job/create-job/create-job-stages/workflow/types';
import { nanoid } from 'nanoid';
import {
  addStage,
  selectPipeline,
  setPipeline,
} from 'features/job/create-job/create-job-stages/workflow/pipeline/pipeline.slice';
import CancelTemplateModal from './components/CancelTemplateModal';
import { createTemplateStart } from './actions';
import { createPipelineStart } from 'features/job/create-job/create-job-stages/workflow/pipeline/create-pipeline/create-pipeline.actions';
import {
  selectSuccess as selectCreatePipelineSuccess,
  resetToInitials as createPipelineReset,
  selectError as selectCreatePipelineError,
} from 'features/job/create-job/create-job-stages/workflow/pipeline/create-pipeline/create-pipeline.slice';
import {
  selectSuccess as selectCreateTemplateSuccess,
  resetToInitials as createTemplateReset,
} from './slice';
import { useOrganizationId } from 'hooks';

export { default as createTemplateReducer } from './slice';
export { onCreateTemplateStart } from './saga';

const onDragEnd = (
  result: DropResult,
  dispatch: Dispatch<any>,
  stages: StageInfo[] | undefined,
) => {
  const { destination, source } = result;

  if (!destination) return;

  if (
    destination.droppableId === source.droppableId &&
    destination.index === source.index
  )
    return;

  const newStages = [...stages];
  const [removed] = newStages.splice(source.index, 1);
  newStages.splice(destination.index, 0, removed);

  dispatch(
    setPipeline({
      stages: newStages.map((stage, index) => ({ ...stage, position: index })),
    }),
  );
};

export interface ICreateTemplateProps {
  templateNameState: {
    templateName: string;
    setTemplateName: React.Dispatch<React.SetStateAction<string>>;
  };
  setIsNewTemplate: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function CreateTemplate(props: ICreateTemplateProps) {
  const {
    templateNameState: { templateName, setTemplateName },
    setIsNewTemplate,
  } = props;
  const dispatch = useDispatch();
  const stages = useSelector(selectPipeline).info?.stages;
  const pipelineId = useSelector(selectPipeline).info?.id;
  const [showModal, setShowModal] = useState<boolean>(false);
  const createPipelineSuccess = useSelector(selectCreatePipelineSuccess);
  const createTemplateSuccess = useSelector(selectCreateTemplateSuccess);
  const organizationId = useOrganizationId();

  // Set initial pipeline
  useEffect(() => {
    dispatch(
      setPipeline({
        stages: [
          { name: 'Sourced', position: 0, kind: 'sourced' },
          { name: 'Applied', position: 1, kind: 'applied' },
        ],
      }),
    );
  }, [dispatch]);

  // Create template only if pipeline created successfully
  useEffect(() => {
    if (createPipelineSuccess && pipelineId && organizationId) {
      dispatch(
        createTemplateStart({
          name: templateName,
          templatable_type: 'Pipeline',
          templatable_id: pipelineId,
          organizationId,
        }),
      );
    }
  }, [createPipelineSuccess, pipelineId, organizationId, templateName, dispatch]);

  // List templates after template created successfully
  useEffect(() => {
    if (createTemplateSuccess) {
      setIsNewTemplate(false);
      setShowModal(false);
      setTemplateName('');
    }
  }, [createTemplateSuccess]);

  useEffect(() => {
    return function () {
      dispatch(createTemplateReset());
      dispatch(createPipelineReset());
    };
  }, [dispatch]);
  // On cancel confirm
  const onConfirm = () => {
    setIsNewTemplate(false);
    setShowModal(false);
  };

  const onAddStage = () => {
    dispatch(
      addStage({
        name: '',
        position: stages!.length + 1,
        draggableId: nanoid().toString(),
        kind: 'custom',
      }),
    );
  };

  const onSaveTemplate = () => {
    // first create pipeline
    if (stages && stages?.length !== 0) {
      if (organizationId) {
        dispatch(
          createPipelineStart({
            pipelineInfo: {
              stages_attributes: stages
                .map((stage) => ({
                  name: stage.name,
                  position: stage.position,
                }))
                .filter((stage) => stage.position > 1),
            },
            organizationId,
          }),
        );
      }
    } else {
      if (organizationId) {
        dispatch(
          createPipelineStart({
            pipelineInfo: { stages_attributes: [] },
            organizationId,
          }),
        );
      }
    }
  };

  return (
    <>
      <Card.Body>
        <Card.Title className="mb-0">{templateName}</Card.Title>
        <div className="inputs-flex mb-4">
          <Card.Subtitle>
            Customize pipeline and manage your stages in your hiring process.
          </Card.Subtitle>
          <Button onMouseUp={() => onAddStage()}>+ Add new stage</Button>
        </div>
        <DragDropContext onDragEnd={(result) => onDragEnd(result, dispatch, stages)}>
          <Droppable droppableId={'droppable'}>
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                <ListGroup>
                  {stages?.map((stage: StageInfo, index: number) => {
                    if (stage.kind === 'sourced' || stage.kind === 'applied') {
                      return (
                        <StageStatic key={stage.draggableId} stageName={stage.name} />
                      );
                    } else {
                      return (
                        <Stage
                          key={stage.draggableId}
                          stage={stage}
                          index={index}
                          newStage={!stage.name}
                        />
                      );
                    }
                  })}
                </ListGroup>

                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <div className="btns-space-between">
          <Button
            onClick={() => {
              setShowModal(true);
            }}
            variant="light"
          >
            Cancel
          </Button>
          <CancelTemplateModal
            handleClose={() => setShowModal(false)}
            showModal={showModal}
            onConfirm={onConfirm}
          />
          <Button variant="primary" onClick={onSaveTemplate}>
            Save
          </Button>
        </div>
      </Card.Body>
    </>
  );
}
