import { call, put, takeLatest } from 'redux-saga/effects';
import { resendConfirmationEmail } from './service';
import { resendConfirmationEmailStart } from './actions';
import { loading, success, failure } from './slice';
import { Action } from '@reduxjs/toolkit';

function* resendConfirmationEmailFlow(action: Action<unknown>) {
  if (resendConfirmationEmailStart.match(action)) {
    try {
      yield put(loading());
      yield call(resendConfirmationEmail, action.payload.email);
      yield put(success());
    } catch (error) {
      const { message }: { message: string } = error?.response?.data || error;
      const statusCode: number = error?.response?.status;
      yield put(failure({ message, statusCode }));
    }
  }
}

export function* onResenConfirmationEmailStart() {
  yield takeLatest(resendConfirmationEmailStart, resendConfirmationEmailFlow);
}
