import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ErrorInfo, RenderingState } from 'redux/types';
import { RootState } from 'redux/root-reducer';

const initialState: RenderingState = {
  isLoading: false,
  success: false,
  error: {
    message: '',
    statusCode: null,
  },
};

const persistSessionSlice = createSlice({
  name: 'PERSIST_USER_SESSION',
  initialState,
  reducers: {
    loading: (state) => {
      state.isLoading = true;
    },
    success: (state) => {
      state.isLoading = false;
      state.success = true;
      state.error = initialState.error;
    },
    failure: (state, action: PayloadAction<ErrorInfo>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

// REDUCER
export default persistSessionSlice.reducer;

// ACTIONS
export const { loading, success, failure } = persistSessionSlice.actions;

// SELECTORS
export const selectIsLoading = (state: RootState) => state.ui.persistSession.isLoading;
export const selectSuccess = (state: RootState) => state.ui.persistSession.success;
export const selectError = (state: RootState) => state.ui.persistSession.error;
