import { RootState } from 'redux/root-reducer';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TApplicationForm, FieldStates } from './types';

const initialState: { info: TApplicationForm | null } = {
  info: null,
};

const applicationFormSlice = createSlice({
  name: 'APPLICATION_FORM',
  initialState,
  reducers: {
    setApplicationForm: (
      state,
      action: PayloadAction<Omit<TApplicationForm, 'name' | 'email'>>,
    ) => {
      state.info = {
        name: FieldStates.Required,
        email: FieldStates.Required,
        ...action.payload,
      };
    },
    resetApplicationForm: (state) => {
      state.info = null;
    },
  },
});

// REDUCER
export default applicationFormSlice.reducer;

// ACTIONS
export const { setApplicationForm, resetApplicationForm } = applicationFormSlice.actions;

// SELECTORS
export const selectApplicationForm = (state: RootState) => state.job.applicationForm;
