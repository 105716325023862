import { combineReducers } from 'redux';
import { showApplicationFormReducer } from './show-application-form';
import { updateApplicationFormReducer } from './update-application-form';
import { createApplicationFormTemplateReducer } from './create-application-form-template';

export default combineReducers({
  show: showApplicationFormReducer,
  update: updateApplicationFormReducer,
  createTemplate: createApplicationFormTemplateReducer,
});
