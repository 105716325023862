import * as React from 'react';
import { Card, FormGroup, Form } from 'react-bootstrap';
import { Field, FieldProps } from 'formik';
import { StylesContext } from '../show-job-details';
import CommonCurrencies from 'assets/CommonCurrency.json';
import { populateCurrenciesOptions } from 'utils';
import { JobFormValues } from '../types';
import SelectMemoized from './SelectMemoized';

export interface IJobTitleProps {}

export default function JobTitle(props: IJobTitleProps) {
  const selectStyles = React.useContext(StylesContext);
  const currencies = populateCurrenciesOptions(CommonCurrencies);

  return (
    <Card>
      <Card.Body>
        <Card.Title>Salary Details</Card.Title>
        <div className="inputs-flex">
          <FormGroup className="input-element" controlId="from">
            <Form.Label>From</Form.Label>
            <Field name="salaryDetails.from" type="text">
              {({ meta, field }: FieldProps) => (
                <>
                  <Form.Control {...field} placeholder="Enter amount" />
                  {meta.touched && meta.error && (
                    <div className="error">enter a number, it can't be negative</div>
                  )}
                </>
              )}
            </Field>
          </FormGroup>
          <FormGroup className="input-element" controlId="to">
            <Form.Label>To</Form.Label>
            <Field name="salaryDetails.to" type="text">
              {({ meta, field }: FieldProps) => (
                <>
                  <Form.Control {...field} placeholder="Enter amount" />
                  {meta.touched && meta.error && (
                    <div className="error">enter a number, it can't be negative</div>
                  )}
                </>
              )}
            </Field>
          </FormGroup>
          <FormGroup className="input-element" controlId="currency">
            <Form.Label>Currency</Form.Label>
            <Field name="salaryDetails.currency">
              {({
                form: { setFieldValue },
                field: { value, name },
              }: FieldProps<any, JobFormValues>) => (
                <SelectMemoized
                  value={value}
                  onSelect={(value) => setFieldValue(name, value)}
                  options={currencies}
                  selectStyles={selectStyles}
                  placeholder="Choose currency"
                />
              )}
            </Field>
          </FormGroup>
        </div>
      </Card.Body>
    </Card>
  );
}
