import * as React from 'react';

interface ISvgTimeline {
  active?: boolean;
}

function SvgTimelineBlue(props: ISvgTimeline) {
  const { active } = props;
  return (
    <svg width={26.17} height={14.275} {...props}>
      <path
        d="M26.17 2.379a2.386 2.386 0 01-2.379 2.379 2.022 2.022 0 01-.607-.083L18.949 8.9a2.1 2.1 0 01.083.619 2.379 2.379 0 11-4.758 0 2.1 2.1 0 01.083-.619l-3.033-3.033a2.339 2.339 0 01-1.237 0l-5.412 5.422a2.022 2.022 0 01.083.607 2.379 2.379 0 11-2.379-2.379 2.022 2.022 0 01.607.083L8.41 4.187a2.1 2.1 0 01-.083-.619 2.379 2.379 0 014.758 0 2.1 2.1 0 01-.083.619l3.033 3.033a2.339 2.339 0 011.237 0L21.5 2.986a2.022 2.022 0 01-.083-.607 2.379 2.379 0 114.758 0z"
        fill={active ? '#006fd9' : 'rgba(250, 250, 250, 0.7'}
      />
    </svg>
  );
}

export default SvgTimelineBlue;
