import { createAction } from '@reduxjs/toolkit';
import { withPayloadType } from 'utils';
import { TApplicationForm, TApplicationFormDetail } from '../types';

export type CreateApplicationFormTemplatePaylod = {
  [P in keyof QuestionsOmitted]: QuestionsOmitted[P];
} & { application_form_details: Omit<TApplicationFormDetail, 'id'>[] } & {
  save_as_default: 'true';
};

type QuestionsOmitted = Omit<TApplicationForm, 'application_form_details'>;

export const createApplicationFormTemplateStart = createAction(
  'CREATE_APPLICATION_FORM_TEMPLATE::START',
  withPayloadType<{
    applicationFormInfo: CreateApplicationFormTemplatePaylod;
    organizationId: string;
  }>(),
);
