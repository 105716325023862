import api from 'services/api';
import { CreateApplicationFormTemplatePaylod } from './actions';

export async function createApplicationFormTemplate({
  applicationFormInfo,
  organizationId,
}: {
  applicationFormInfo: CreateApplicationFormTemplatePaylod;
  organizationId: string;
}) {
  const response = api.post(
    `api/dashboard/organizations/${organizationId}/application_forms`,
    {
      application_form: {
        save_as_default: applicationFormInfo.save_as_default,
        phone_number: applicationFormInfo.phone_number,
        education: applicationFormInfo.education,
        experience: applicationFormInfo.experience,
        summary: applicationFormInfo.summary,
        resume: applicationFormInfo.summary,
        cover_letter: applicationFormInfo.cover_letter,
        application_form_details_attributes: applicationFormInfo.application_form_details,
      },
    },
  );
  return response;
}
