import * as React from 'react';

interface ISvgCandidates {
  active?: boolean;
}

function SvgCandidatesBlue(props: ISvgCandidates) {
  const { active } = props;
  return (
    <svg width={20} height={20} {...props}>
      <path
        d="M10 10a5 5 0 10-5-5 5.015 5.015 0 005 5zm0 2.5c-3.312 0-10 1.688-10 5V20h20v-2.5c0-3.312-6.688-5-10-5z"
        fill={active ? '#006fd9' : 'rgba(250, 250, 250, 0.7'}
      />
    </svg>
  );
}

export default SvgCandidatesBlue;
