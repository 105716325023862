import * as React from 'react';
import { Card, FormGroup, Form } from 'react-bootstrap';
import { Field, FieldProps } from 'formik';
import { StylesContext } from '../show-job-details';
import Tags from '@yaireo/tagify/dist/react.tagify';
import { JobFormValues, Option } from '../types';
import {
  employmentOptions,
  experienceOptions,
  educationOptions,
  Employment,
  Experience,
  Education,
} from './EmploymentDetails.slice';
import { useSelector } from 'react-redux';
import SelectMemoized from './SelectMemoized';
import { selectJobDetails } from '../job-details.slice';

const getParsedKeywords = (tags_JSON: string): string[] => {
  type Tag = { value: string };
  const keywords: string[] = [];
  const tags: Tag[] = JSON.parse(tags_JSON);
  tags.map((tag: Tag) => keywords.push(tag.value));
  return keywords;
};

const baseTagifySettings = {
  maxTags: 6,
  placeholder: 'Type keywords(ex. design, teamwork. etc)',
};

export interface IEmploymentDetailsProps {}

export default function EmploymentDetails(props: IEmploymentDetailsProps) {
  const jobInfo = useSelector(selectJobDetails);
  const selectStyles = React.useContext(StylesContext);

  return (
    <Card>
      <Card.Body>
        <Card.Title>Employment Details</Card.Title>
        <div className="inputs-flex">
          <FormGroup className="input-element" controlId="employmentType">
            <Form.Label>Employment Type</Form.Label>
            <Field name="employment.employmentType">
              {({
                form: { setFieldValue },
                field: { value, name },
              }: FieldProps<Option<Employment>, JobFormValues>) => (
                <SelectMemoized
                  value={value}
                  onSelect={(value) => setFieldValue(name, value)}
                  options={employmentOptions}
                  selectStyles={selectStyles}
                  placeholder="Choose type"
                />
              )}
            </Field>
          </FormGroup>
          <FormGroup className="input-element" controlId="experience">
            <Form.Label>Experience</Form.Label>
            <Field name="employment.experience">
              {({
                form: { setFieldValue },
                field: { value, name },
              }: FieldProps<Option<Experience>, JobFormValues>) => (
                <SelectMemoized
                  value={value}
                  onSelect={(value) => setFieldValue(name, value)}
                  options={experienceOptions}
                  selectStyles={selectStyles}
                  placeholder="Years of experience"
                />
              )}
            </Field>
          </FormGroup>
        </div>
        <div className="inputs-flex">
          <FormGroup className="input-element" controlId="education">
            <Form.Label>Education</Form.Label>
            <Field name="employment.education">
              {({
                form: { setFieldValue },
                field: { value, name },
              }: FieldProps<Option<Education>, JobFormValues>) => (
                <SelectMemoized
                  value={value}
                  onSelect={(value) => setFieldValue(name, value)}
                  options={educationOptions}
                  selectStyles={selectStyles}
                  placeholder="Choose education level"
                />
              )}
            </Field>
          </FormGroup>
          <FormGroup className="input-element" controlId="keywords">
            <Form.Label>Keywords</Form.Label>
            <Field name="employment.keywords">
              {({ form }: FieldProps<string[], JobFormValues>) => (
                <Tags
                  className="form-control"
                  settings={{ ...baseTagifySettings }} // tagify settings object
                  onChange={(e: any) => {
                    form.setFieldValue(
                      'employment.keywords',
                      getParsedKeywords(e.target.value),
                    );
                  }}
                >
                  {jobInfo?.keywords}
                </Tags>
              )}
            </Field>
          </FormGroup>
        </div>
      </Card.Body>
    </Card>
  );
}
