import api from 'services/api';
import { CreatePipelinePayload } from './create-pipeline.actions';

export async function createPipeline({
  pipelineInfo,
  organizationId,
}: {
  pipelineInfo: CreatePipelinePayload;
  organizationId: string;
}) {
  const { stages_attributes } = pipelineInfo;
  const response = api.post(`api/dashboard/organizations/${organizationId}/pipelines`, {
    pipeline: {
      stages_attributes,
    },
  });
  return response;
}
