import { takeLatest, call, put } from 'redux-saga/effects';

import { listInvitationsStart } from './actions';
import { loading, success, failure } from './slice';
import { listInvitations } from './service';
import { setInvitations } from '../slice';
import { Action } from '@reduxjs/toolkit';

function* listInvitationsFlow(action: Action<unknown>) {
  if (listInvitationsStart.match(action)) {
    try {
      yield put(loading());
      const {
        data: { data },
      } = yield call(listInvitations, action.payload.organizationId);
      yield put(success());
      yield put(setInvitations(data));
    } catch (error) {
      const { message }: { message: string } = error?.response?.data || error;
      const statusCode: number = error?.response?.status;
      yield put(failure({ message, statusCode }));
    }
  }
}

export function* onListInvitationsStart() {
  yield takeLatest(listInvitationsStart, listInvitationsFlow);
}
