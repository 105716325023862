import * as React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectIsLoading } from 'services/session/persist-user-session.slice';
import { useAuth } from 'hooks';
import { Spinner } from 'react-bootstrap';

export default function HomeHEader() {
  const isAuthenticated = useAuth();
  const isLoading = useSelector(selectIsLoading);
  const match = useRouteMatch();

  return (
    <div className="home__header">
      <Link to="/" className="home__logo">
        Hireable
      </Link>
      {isLoading ? (
        <Spinner animation="border" />
      ) : (
        <>
          {!isAuthenticated && match.url === '/' && (
            <div className="home__headerButtons">
              <Link className="btn btn-light btn-lg mr-2" to="/sign-up">
                Sign Up
              </Link>
              <Link className="btn btn-primary btn-lg" to="/login">
                Sign In
              </Link>
            </div>
          )}
          {!isAuthenticated && match.url === '/login' && (
            <div className="home__headerButtons">
              <p>Don't have an account?</p>
              <Link
                style={{ minWidth: '0', fontWeight: 500, lineHeight: 1.6 }}
                className="btn btn-link pl-2 pr-2"
                to="/sign-up"
              >
                Sign Up
              </Link>
            </div>
          )}
          {!isAuthenticated && match.url.includes('sign-up') && (
            <div className="home__headerButtons">
              <p>Already have an account?</p>
              <Link
                style={{ minWidth: '0', fontWeight: 500, lineHeight: 1.6 }}
                className="btn btn-link pl-2 pr-2"
                to="/login"
              >
                Sign In
              </Link>
            </div>
          )}
        </>
      )}
    </div>
  );
}
