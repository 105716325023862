import * as React from 'react';
import { useContext } from 'react';
import { Form } from 'react-bootstrap';
import { SelectedFiltersContext } from '../list-candidates';
import { Filter } from '../types';

export interface IFilterCheckboxProps {
  filter: Filter;
}

export default function FilterCheckbox(props: IFilterCheckboxProps) {
  const { filter } = props;
  const { field: filterName } = filter.metadata;

  const { selectedFilters, setSelectedFilters } = useContext(SelectedFiltersContext);

  const onFilterSelection = (e: any) => {
    if (selectedFilters && setSelectedFilters) {
      (selectedFilters[filterName] as string[])?.includes(e.target.value)
        ? setSelectedFilters({
            ...selectedFilters,
            [filterName]: (selectedFilters[filterName] as string[])?.filter(
              (el: any) => el !== e.target.value,
            ),
          })
        : setSelectedFilters({
            ...selectedFilters,
            [filterName]: [...selectedFilters[filterName], e.target.value],
          });
    }
  };

  return (
    <div className="candidates-filter">
      <p className="candidates-filter__label">{filter.metadata.group_label}:</p>

      <Form.Group
        className="candidates-filter__options"
        onChange={onFilterSelection}
        controlId={filter.metadata.field}
      >
        {filter.options.map((option) => (
          <Form.Group controlId={option.value}>
            <Form.Check
              name={option.label}
              type="checkbox"
              value={option.value}
              label={option.label}
            />
          </Form.Group>
        ))}
      </Form.Group>
    </div>
  );
}
