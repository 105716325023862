import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'redux/root-reducer';
import { TemplateInfo } from './types';

const initialState: { info: TemplateInfo[] | null } = {
  info: null,
};

const templatesSlice = createSlice({
  name: 'TEMPLATES',
  initialState,
  reducers: {
    setTemplatesInfo: (state, action: PayloadAction<TemplateInfo[]>) => {
      state.info = action.payload.filter(
        (template) => template.templatable_type === 'Pipeline',
      );
    },
    resetTemplatesInfo: (state) => {
      state.info = initialState.info;
    },
  },
});

// REDUCER
export default templatesSlice.reducer;

// ACTIONS
export const { setTemplatesInfo, resetTemplatesInfo } = templatesSlice.actions;

// SELECTORS
export const selectTemplatesInfo = (state: RootState) =>
  state.organization.templates.info;
