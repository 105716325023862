import { withPayloadType } from 'utils';
import { createAction } from '@reduxjs/toolkit';

export const createTemplateStart = createAction(
  'CREATE_TEMPLATE::START',
  withPayloadType<{
    name: string;
    templatable_type: 'Pipeline';
    templatable_id: any;
    organizationId: string;
  }>(),
);
