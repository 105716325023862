import * as React from 'react';
import { useContext } from 'react';
import { Button } from 'react-bootstrap';
import { QuestionsDispatch } from '../../show-application-form';
import { removeQuestion } from '../actions';
import { QuestionsAction } from '../types';

export interface ICancelQuestionProps {
  setIsEditting: React.Dispatch<React.SetStateAction<boolean>> | undefined;
  newQuestion: boolean;
  questionId: string;
}

export default function CancelQuestion(props: ICancelQuestionProps) {
  const { setIsEditting, newQuestion, questionId } = props;
  const dispatch = useContext(QuestionsDispatch);
  const onClick = () => {
    if (newQuestion) {
      (dispatch as React.Dispatch<QuestionsAction>)(removeQuestion({ id: questionId }));
    }

    return setIsEditting ? setIsEditting(false) : null;
  };
  return (
    <Button className="mr-4" onClick={onClick} variant="light">
      Cancel
    </Button>
  );
}
