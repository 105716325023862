import { takeLatest, put, call } from 'redux-saga/effects';
import { updateJobStart } from './actions';
import { loading, success, failure } from './slice';
import { updateJob } from './service';
import { setJobDetails } from '../job-details.slice';
import { Action } from '@reduxjs/toolkit';

function* updateJobFlow(action: Action<unknown>) {
  if (updateJobStart.match(action)) {
    try {
      yield put(loading());
      const { data } = yield call(updateJob, action.payload);
      const { data: jobDetails } = data;
      yield put(success());
      yield put(setJobDetails(jobDetails));
    } catch (error) {
      const { message }: { message: string } = error?.response?.data || error;
      const statusCode: number = error?.response?.status;
      yield put(failure({ message, statusCode }));
    }
  }
}

export default function* onUpdateJobStart() {
  yield takeLatest(updateJobStart, updateJobFlow);
}
