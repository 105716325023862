import { combineReducers } from '@reduxjs/toolkit';
import departmentsReducer from 'features/organization/departments/slice';
import templatesReducer from 'features/organization/pipeline-templates/slice';
import organizationReducer from 'features/organization/slice';
import sectionsReducer from 'features/organization/site-editor/sections.slice';
import sectionReducer from 'features/organization/site-editor/section.slice';
import candidatesReducer from './candidates/slice';

export default combineReducers({
  organizationReducer,
  departments: departmentsReducer,
  templates: templatesReducer,
  sections: sectionsReducer,
  currentSection: sectionReducer,
  candidates: candidatesReducer,
});
