import * as React from 'react';

interface ISvgInbox {
  active?: boolean;
}

function SvgInboxBlue(props: ISvgInbox) {
  const { active } = props;
  return (
    <svg width={24} height={16} {...props}>
      <path
        d="M23.664 7.5l-4.4-6.606A2 2 0 0017.6 0H6.4a2 2 0 00-1.664.891L.336 7.5A2 2 0 000 8.606V14a2 2 0 002 2h20a2 2 0 002-2V8.606a2 2 0 00-.336-1.106zM6.76 2.667h10.48L20.8 8h-5.133l-1.333 2.667H9.667L8.333 8H3.2l3.56-5.333z"
        fill={active ? '#006fd9' : 'rgba(250, 250, 250, 0.7'}
      />
    </svg>
  );
}

export default SvgInboxBlue;
