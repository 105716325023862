import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserInfo } from 'redux/types';
import { RootState } from 'redux/root-reducer';

import { getInitials } from 'utils';

type UserState = { info: UserInfo };

const initialState: UserState = {
  info: {
    id: null,
    first_name: '',
    last_name: '',
    initials: '',
    email: '',
    avatar: '',
    organizations: [],
  },
};

const userSlice = createSlice({
  name: 'USER',
  initialState,
  reducers: {
    setUserInfo: (state, action: PayloadAction<UserInfo>) => {
      state.info = action.payload;
      state.info.initials = getInitials(
        action.payload.first_name,
        action.payload.last_name,
      );
    },
    resetUserInfo: (state) => {
      state.info = initialState.info;
    },
  },
});

// REDUCER
export default userSlice.reducer;

// ACTIONS
export const { setUserInfo, resetUserInfo } = userSlice.actions;

// SELECTORS
export const selectUser = (state: RootState) => state.user.info;
export const selectUserOrganizations = (state: RootState) =>
  state.user.info.organizations;
