import { SearchApplicationsRes } from '../types';
import { takeLatest, call, put } from 'redux-saga/effects';

import { setCandidatesInfo } from '../slice';
import { loading, success, failure } from './slice';
import { listCandidatesStart } from './actions';
import { getCandidates } from './service';
import { history } from 'redux/store';
import { Action } from '@reduxjs/toolkit';

function* listCandidatesFlow(action: Action<unknown>) {
  if (listCandidatesStart.match(action)) {
    try {
      yield put(loading());
      const { data } = yield call(getCandidates, action.payload);
      const { data: candidatesInfo }: { data: SearchApplicationsRes } = data;
      yield put(setCandidatesInfo(candidatesInfo));
      yield put(success());
      yield history.push(history.location);
    } catch (error) {
      const { message }: { message: string } = error?.response?.data || error;
      const statusCode: number = error?.response?.status;
      yield put(failure({ message, statusCode }));
    }
  }
}

export function* onListCandidatesStart() {
  yield takeLatest(listCandidatesStart, listCandidatesFlow);
}
