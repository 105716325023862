import * as React from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { removeStage, editStageName } from '../pipeline.slice';
import { StageInfo } from '../../types';
import { ButtonGroup } from 'react-bootstrap';
import { Draggable } from 'react-beautiful-dnd';

export interface IStageEdittingrops {
  index: number;
  stage: StageInfo;
  setIsEditting: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function StageEditting(props: IStageEdittingrops) {
  const { stage, setIsEditting, index } = props;
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState(stage.name);
  const disabled = !inputValue.trim();
  const onSave = () => {
    setIsEditting(false);
    dispatch(editStageName({ name: inputValue, id: stage.draggableId! }));
  };

  const onCancel = () => {
    if (!stage.name) {
      dispatch(removeStage({ id: stage.draggableId! }));
    }
    setIsEditting(false);
  };

  return (
    <Draggable isDragDisabled draggableId={stage.draggableId!} index={index}>
      {(provided) => (
        <div ref={provided.innerRef} {...provided.draggableProps}>
          <div>
            <div className="stage-editting">
              <input
                autoFocus
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    onSave();
                  }
                }}
                onChange={(e) => setInputValue(e.target.value)}
                value={inputValue}
                className="card-small"
              ></input>
              <ButtonGroup>
                <button onClick={onCancel} className="btn-cancel btn-text btn-reset mr-1">
                  Cancel
                </button>
                <button
                  disabled={disabled}
                  onClick={onSave}
                  className={`btn-save btn-text btn-reset ml-4 ${
                    disabled ? 'disabled' : ''
                  }`}
                >
                  Save
                </button>
              </ButtonGroup>
            </div>
          </div>
        </div>
      )}
    </Draggable>
  );
}
