import { createAction } from '@reduxjs/toolkit';
import { withPayloadType } from 'utils';

export const listStageApplicationsStart = createAction(
  'LIST_STAGE_APPLICATIONS::START',
  withPayloadType<{
    job_id: string;
    stage_id: string;
    organizationId: string;
  }>(),
);
