import * as React from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { logoutStart } from './actions';

export { onLogoutStart } from './saga';
export { default as logoutReducer } from './slice';

export interface ILogoutProps {}

// eslint-disable-next-line no-unused-vars
export default function Logout(props: ILogoutProps) {
  const dispatch = useDispatch();

  return (
    <div>
      <Button onClick={() => dispatch(logoutStart())} type="submit">
        SignOut
      </Button>
    </div>
  );
}
