import * as React from 'react';
import { useState } from 'react';
import { ButtonGroup } from 'react-bootstrap';
import { useRouteMatch } from 'react-router-dom';
import { TemplateInfo } from '../types';
import { history } from 'redux/store';

export interface ITemplateProps {
  template: TemplateInfo;
}

export default function Template(props: ITemplateProps) {
  // state
  const { template } = props;
  const match = useRouteMatch();
  const [showModal, setShowModal] = useState<boolean>(false);
  const handleClose = () => setShowModal(false);

  return (
    <div
      onClick={() =>
        history.push({
          pathname: `${match.url}/${template.templatable_id}`,
          state: { templateName: template.name },
        })
      }
      className="card-small pr-4"
    >
      <label className="form-label form-label--dark">{template?.name}</label>
      <ButtonGroup>
        <button className="btn-edit btn-text btn-reset mr-3">Edit</button>
        {/* <button
              onClick={() => setShowModal(true)}
              className="btn-delete btn-text btn-reset ml-4"
            >
              Delete
            </button>
            {/* <DeleteTemplateModal
              template={template}
              handleClose={handleClose}
              showModal={showModal}
            /> */}
      </ButtonGroup>
    </div>
  );
}
