import * as React from 'react';
import { useContext } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { TQuestionNoOptions } from '../../types';
import { Card, ButtonGroup } from 'react-bootstrap';
import { IconDrag } from 'assets/svg/icons';
import { EdittingStateContext } from './QuestionNoOptions';
import DeleteQuestion from '../DeleteQuestion';
import EditQuestion from '../EditQuestion';

export interface IQuestionNoOptionsSavedProps {
  question: TQuestionNoOptions;
  index: number;
}

export default function QuestionNoOptionsSaved(props: IQuestionNoOptionsSavedProps) {
  const { question, index } = props;
  const { setIsEditting } = useContext(EdittingStateContext);
  return (
    <Draggable draggableId={question.id} index={index}>
      {(provided) => (
        <div
          className="card question-container"
          ref={provided.innerRef}
          {...provided.draggableProps}
        >
          <Card.Body className="question__body">
            <Card.Title className="question__header">
              <div className="drag-handle" {...provided.dragHandleProps}>
                <IconDrag></IconDrag>
              </div>
              {`Question (${question.type.split('_').join(' ').toLowerCase()})`}
            </Card.Title>
            <p className="question__headline">{question.headline}</p>

            <ButtonGroup className="question__btns">
              <EditQuestion setIsEditting={setIsEditting} />
              <DeleteQuestion questionId={question.id} />
            </ButtonGroup>
          </Card.Body>
        </div>
      )}
    </Draggable>
  );
}
