import { put, call, takeLatest } from 'redux-saga/effects';

import { history } from 'redux/store';
import { UserInfo } from 'redux/types';
import {
  setTokenToRequestHeader,
  getTokenFromLocalStorage,
  removeTokenFromLocalStorage,
} from 'utils';
import { setAuthenticated, removeAuthenticated } from 'features/authentication/slice';
import { resetOrganizationInfo } from 'features/organization/slice';
import { setUserInfo, resetUserInfo } from 'features/user/slice';
import { getUserInfo } from 'features/user/show-user/service';
import { loading, success, failure } from './persist-user-session.slice';
import { persistUserSessionStart } from './persist-user-session.actions';
import { Action } from '@reduxjs/toolkit';

// GET USER INFO FLOW
function* persistUserSession(action: Action<unknown>) {
  if (persistUserSessionStart.match(action)) {
    try {
      yield put(loading());
      yield setTokenToRequestHeader(getTokenFromLocalStorage());
      const { data } = yield call(getUserInfo);
      const { data: userInfo }: { data: UserInfo } = data;
      yield put(setAuthenticated());
      yield put(setUserInfo(userInfo));
      yield put(success());
    } catch (error) {
      const { message }: { message: string } = error?.response?.data || error;
      const statusCode: number = error?.response?.status;
      if (statusCode === 401) {
        yield put(failure({ message, statusCode }));
        yield put(removeAuthenticated());
        yield put(resetUserInfo());
        yield put(resetOrganizationInfo());
        yield removeTokenFromLocalStorage();
        yield setTokenToRequestHeader(null);
        yield history.push('/login');
      }
    }
  }
}

export function* onPersistUserSessionStart() {
  yield takeLatest(persistUserSessionStart, persistUserSession);
}
