import { takeLatest, call, put } from 'redux-saga/effects';

import { OrganizationInfo } from 'redux/types';
import { setOrganizationInfo } from '../slice';
import { loading, success, failure } from './slice';
import { getOrganizationStart } from './actions';
import { getOrganization } from './service';
import { Action } from '@reduxjs/toolkit';

function* getOrganizationFlow(action: Action<unknown>) {
  if (getOrganizationStart.match(action)) {
    try {
      yield put(loading());
      const { data } = yield call(getOrganization, action.payload.id!);
      const { data: organizationInfo }: { data: OrganizationInfo } = data;
      yield put(setOrganizationInfo(organizationInfo));
      yield put(success());
    } catch (error) {
      const { message }: { message: string } = error?.response?.data || error;
      const statusCode: number = error?.response?.status;
      yield put(failure({ message, statusCode }));
    }
  }
}

export function* onGetOrganizationStart() {
  yield takeLatest(getOrganizationStart, getOrganizationFlow);
}
