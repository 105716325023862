import * as React from 'react';
import { useParams, Redirect, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import renderErrorElements from 'components/Errors/renderErrorElements';
import { showJobStart } from './actions';
import {
  selectJobDetails,
  resetJobDetails,
} from '../create-job/create-job-stages/job-details/job-details.slice';
import { selectError } from './slice';
import { resetApplicationForm } from '../create-job/create-job-stages/application-form/slice';
import { resetPipeline } from '../create-job/create-job-stages/workflow/pipeline/pipeline.slice';
import Job from './components/Job';
import CreateJob from '../create-job';
import { listDepartmentsStart } from 'features/organization/departments/list-departments/actions';
import { useOrganizationId } from 'hooks';
import { useEffect, useState } from 'react';
import { resetToInitials } from './update-application/slice';
import NotFound from 'pages/error/NotFound';

export { default as showJobReducer } from './slice';
export { default as onShowJobStart } from './saga';

export interface IShowjobProps {}

export default function Showjob() {
  const dispatch = useDispatch();
  const { id }: { id: string } = useParams();
  const jobDetails = useSelector(selectJobDetails);
  const error = useSelector(selectError);
  const match = useRouteMatch();
  const organizationId = useOrganizationId();
  const newJob = id === 'new';
  const [showNotFound, setShowNotFound] = useState<boolean>(false);

  useEffect(() => {
    if (id && id === 'new' && organizationId) {
      dispatch(listDepartmentsStart({ organizationId }));
    }
  }, [organizationId, dispatch, id]);

  useEffect(() => {
    if (id && id !== 'new' && organizationId) {
      dispatch(showJobStart({ id, organizationId }));
      setShowNotFound(false);
    }
    if (!organizationId) {
      setShowNotFound(true);
    }
    return () => {
      dispatch(resetJobDetails());
      dispatch(resetApplicationForm());
      dispatch(resetPipeline());
      dispatch(resetToInitials());
    };
  }, [id, dispatch, organizationId]);
  if (error.message) return <div>{renderErrorElements(error.message)}</div>;
  if (showNotFound) return <NotFound />;

  if (newJob) {
    return (
      <>
        <Redirect to={`${match.url}/job-details`} />
        <CreateJob />
      </>
    );
  }

  return <div>{jobDetails && <Job />}</div>;
}
