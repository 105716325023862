import * as React from 'react';

function SvgShortanswer(props: {}) {
  return (
    <svg width={22.266} height={8.35} {...props}>
      <path
        d="M21.57 5.567H.7a.7.7 0 00-.7.7V7.65a.7.7 0 00.7.7h20.87a.7.7 0 00.7-.7V6.262a.7.7 0 00-.7-.695zm0-5.567H.7a.7.7 0 00-.7.7v1.392a.7.7 0 00.7.7h20.87a.7.7 0 00.7-.7V.696a.7.7 0 00-.7-.696z"
        fill="#b2b2b2"
      />
    </svg>
  );
}

export default SvgShortanswer;
