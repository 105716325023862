import * as React from 'react';
import { useState, createContext } from 'react';
import { useSelector } from 'react-redux';
import { Switch, useRouteMatch, Redirect, Route } from 'react-router-dom';
import ShowJobDetails from './create-job-stages/job-details/show-job-details';
import Workflow from './create-job-stages/workflow';
import Header from './components/CreateJobHeader';
import ApplicationForm from './create-job-stages/application-form';
import { selectJobDetails } from './create-job-stages/job-details/job-details.slice';

export { default as createJobReducer } from './slice';
export { default as onCreateJobStart } from './saga';

export interface ICreateJobProps {}

type TitleContext = [string, React.Dispatch<React.SetStateAction<string>>];
export type ShowJobModalState = [boolean, React.Dispatch<React.SetStateAction<boolean>>];

interface IRedirectContext {
  redirectTo: string;
  setRedirectTo: React.Dispatch<React.SetStateAction<string>>;
}

interface IJobDetailsModal {
  showJobDetailsModal: boolean;
  setShowJobDetailsModal: React.Dispatch<React.SetStateAction<boolean>>;
}

interface IWorkflowModal {
  showWorkflowModal: boolean;
  setShowWorkflowModal: React.Dispatch<React.SetStateAction<boolean>>;
}

interface IApplicationFormModal {
  showApplicationFormModal: boolean;
  setShowApplicationFormModal: React.Dispatch<React.SetStateAction<boolean>>;
}

interface IISDirty {
  isDirty: boolean;
  setDirty: React.Dispatch<React.SetStateAction<boolean>>;
}

export const JobDetailsModalContext = createContext<Partial<IJobDetailsModal>>({});
export const ApplicationFormModalContext = createContext<Partial<IApplicationFormModal>>(
  {},
);
export const WorkflowModalContext = createContext<Partial<IWorkflowModal>>({});
export const JobTitleContext = createContext<Partial<TitleContext>>([]);
export const RedirectToContext = createContext<Partial<IRedirectContext>>({});

export const IsDirtyContext = createContext<Partial<IISDirty>>({});

export default function CreateJob() {
  const match = useRouteMatch();
  const jobDetails = useSelector(selectJobDetails);
  const isJobDraft = jobDetails?.status === 'draft';
  //test
  const isJobPublished = jobDetails?.status === 'published';
  const [jobTitle, setJobTitle] = useState(jobDetails?.title || 'Create a new job');

  const [redirectTo, setRedirectTo] = useState<string>('');
  const [showJobDetailsModal, setShowJobDetailsModal] = useState<boolean>(false);
  const [showWorkflowModal, setShowWorkflowModal] = useState<boolean>(false);
  const [showApplicationFormModal, setShowApplicationFormModal] = useState<boolean>(
    false,
  );
  const [isDirty, setDirty] = useState<boolean>(false);

  return (
    <RedirectToContext.Provider value={{ redirectTo, setRedirectTo }}>
      <IsDirtyContext.Provider value={{ isDirty, setDirty }}>
        <JobTitleContext.Provider value={[jobTitle, setJobTitle]}>
          <JobDetailsModalContext.Provider
            value={{ showJobDetailsModal, setShowJobDetailsModal }}
          >
            <ApplicationFormModalContext.Provider
              value={{ showApplicationFormModal, setShowApplicationFormModal }}
            >
              <WorkflowModalContext.Provider
                value={{ showWorkflowModal, setShowWorkflowModal }}
              >
                <Header />
                <Switch>
                  <Route path={`${match.url}/job-details`}>
                    <ShowJobDetails />
                  </Route>
                  {isJobDraft || isJobPublished ? (
                    <>
                      <Route path={`${match.url}/application-form`}>
                        <ApplicationForm />
                      </Route>
                      <Route path={`${match.url}/workflow`}>
                        <Workflow />
                      </Route>
                    </>
                  ) : (
                    <Redirect to={`${match.url}/job-details`} />
                  )}
                </Switch>
              </WorkflowModalContext.Provider>
            </ApplicationFormModalContext.Provider>
          </JobDetailsModalContext.Provider>
        </JobTitleContext.Provider>
      </IsDirtyContext.Provider>
    </RedirectToContext.Provider>
  );
}
