import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'redux/root-reducer';
import { StageInfo } from 'features/job/create-job/create-job-stages/workflow/types';
import { IStageApplication } from 'features/job/types';

const initialState: { info: IStageApplication[] | null } = {
  info: null,
};

const stageApplicationsSlice = createSlice({
  name: 'STAGE_APPLICATIONS',
  initialState,
  reducers: {
    setStageApplications: (state, action: PayloadAction<IStageApplication[]>) => {
      state.info = action.payload;
    },
    resetStageApplications: (state) => {
      state.info = null;
    },
  },
});

//REDUCER
export default stageApplicationsSlice.reducer;

// ACTIONS
export const {
  setStageApplications,
  resetStageApplications,
} = stageApplicationsSlice.actions;

/// SELECTORS
export const selectStageApplications = (state: RootState) => state.job.stageApplications;
