import * as React from 'react';

function SvgCheckboxes(props: {}) {
  return (
    <svg width={18.832} height={18.832} {...props}>
      <path
        d="M5.126 7.428L3.662 8.893 8.37 13.6 18.832 3.139l-1.465-1.465-9 8.945zM16.74 16.74H2.092V2.092h10.463V0H2.092A2.1 2.1 0 000 2.092V16.74a2.1 2.1 0 002.092 2.092H16.74a2.1 2.1 0 002.092-2.092V8.37H16.74z"
        fill="#b2b2b2"
      />
    </svg>
  );
}

export default SvgCheckboxes;
