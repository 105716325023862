import { call, all } from 'redux-saga/effects';
import { onCreateDepartmentStart } from './create-department';
import { onDeleteDepartmentStart } from './delete-department';

import { onListDepartmentsStart } from './list-departments';
import { onUpdateDepartmentStart } from './update-department';

export default function* onDepartmentsAction() {
  yield all([
    call(onListDepartmentsStart),
    call(onCreateDepartmentStart),
    call(onUpdateDepartmentStart),
    call(onDeleteDepartmentStart),
  ]);
}
