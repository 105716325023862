import * as React from 'react';
import { useEffect } from 'react';

export interface IAvatarPreviewProps {
  image: File;
}

export default function AvatarPreview(props: IAvatarPreviewProps) {
  const { image } = props;
  const imageUrl = URL.createObjectURL(image);

  useEffect(
    () =>
      function cleanup() {
        URL.revokeObjectURL(imageUrl);
      },
    [imageUrl],
  );
  return (
    <div>
      <img alt="avatar" src={imageUrl} width="50px" />
    </div>
  );
}
