import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'redux/root-reducer';
import { Sections } from './types';

const initialState: { info: Sections[] | null } = {
  info: null,
};

const sectionsSlice = createSlice({
  name: 'SECTIONS',
  initialState,
  reducers: {
    setSectionsInfo: (state, action: PayloadAction<Sections[]>) => {
      state.info = action.payload;
    },
    resetSectionsInfo: (state) => {
      state.info = initialState.info;
    },
  },
});

// REDUCER
export default sectionsSlice.reducer;

// ACTIONS
export const { setSectionsInfo, resetSectionsInfo } = sectionsSlice.actions;

// SELECTORS
export const selectSectionsInfo = (state: RootState) => state.organization.sections.info;
