import * as React from 'react';
import { useSelector } from 'react-redux';
import { useRouteMatch, Switch, Route, Redirect } from 'react-router-dom';
import CreateJob from 'features/job/create-job';
import { selectJobDetails } from 'features/job/create-job/create-job-stages/job-details/job-details.slice';
import ListStageApplications from '../list-stage-applicants';
import JobHeader from './JobHeader';

export interface IJobProps {}

export default function Job() {
  const match = useRouteMatch();
  const appliedStageId = useSelector(selectJobDetails)?.pipeline?.stages?.find(
    (stage) => stage.kind === 'applied',
  )?.id;

  return (
    <Switch>
      <Route path={`${match.url}/edit`}>
        <CreateJob />
      </Route>
      <Route path={match.url}>
        <div className="container">
          <JobHeader />
          <Switch>
            <Route
              path={match.url}
              exact
              render={() => <Redirect to={`${match.url}/stage/${appliedStageId}`} />}
            />
            <Route path={`${match.url}/stage/:stageId`}>
              <ListStageApplications />
            </Route>
          </Switch>
        </div>
      </Route>
    </Switch>
  );
}
