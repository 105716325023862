import * as React from 'react';
import { Dropdown } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import CreateJobHeaderNavigation from './CreateJobHeaderNavigation';
import CreateJobHeaderTitle from './CreateJobHeaderTitle';
import { selectJobDetails } from '../create-job-stages/job-details/job-details.slice';

export interface IHeaderProps {}

export default function Header() {
  const jobDetails = useSelector(selectJobDetails);
  const jobTitle = jobDetails?.title;
  const navItemDisabled = !jobDetails?.status;
  const CreateJobHeaderTitleMemo = React.useMemo(
    () => <CreateJobHeaderTitle title={jobTitle} />,
    [jobTitle],
  );
  const CreateJobHeaderNavigationMemo = React.useMemo(
    () => <CreateJobHeaderNavigation navItemDisabled={navItemDisabled} />,
    [navItemDisabled],
  );

  return (
    <div className="header-wrapper">
      <div className="header container" style={{ display: 'flex' }}>
        {CreateJobHeaderTitleMemo}
        {CreateJobHeaderNavigationMemo}
        <div className="header__buttons">
          <Dropdown>
            <Dropdown.Toggle className="big" variant="success" id="publish-dropdown">
              Publish
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item>Publish</Dropdown.Item>
              <Dropdown.Item>Publish Internally</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </div>
  );
}
