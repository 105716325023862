import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ActionCreatorWithoutPayload } from '@reduxjs/toolkit';
import { history } from 'redux/store';
import { removeLastRouteString, toastConfig } from 'utils';
import { selectIsAuthenticated } from '../features/authentication/slice';
import { toast } from 'react-toastify';
import { ErrorInfo } from 'redux/types';
import { RootState } from 'redux/root-reducer';
import { selectUserOrganizations } from 'features/user/slice';
import { useLocation } from 'react-router-dom';

export const useAuth = () => useSelector(selectIsAuthenticated);

export const useCleanup = (
  resetAction: ActionCreatorWithoutPayload,
  error: string,
  success: boolean,
) => {
  const dispatch = useDispatch();
  useEffect(
    () =>
      function cleanup() {
        if (error || success) {
          dispatch(resetAction());
        }
      },
    [error, success, dispatch, resetAction],
  );
};

export const useAdjustButtonPosition = () => {
  const [adjustPosition, setAdjustPosition] = React.useState(false);

  useEffect(() => {
    //  MAKE SAVE CHANGES BUTTON STICKY
    const scrollEvent = () => {
      if (window.pageYOffset >= 61) {
        setAdjustPosition(true);
      } else {
        setAdjustPosition(false);
      }
    };

    window.onscroll = scrollEvent;
    window.onload = scrollEvent;
    scrollEvent();

    return () => {
      window.removeEventListener('scroll', scrollEvent);
      window.removeEventListener('load', scrollEvent);
    };
  });

  return adjustPosition;
};

// WARN USER ABOUT UNSAVED CHANGES
/* only works with close&reload tab */
export const useUnsavedChangesPopup = (isFormDirty: boolean | undefined) => {
  useEffect(() => {
    const dialogBoxWarning = (e: BeforeUnloadEvent) => {
      const confirmationMessage = 'o/';
      (e || !!window.event).returnValue = confirmationMessage;
      return confirmationMessage;
    };

    window.addEventListener('beforeunload', dialogBoxWarning);
    return () => {
      window.removeEventListener('beforeunload', dialogBoxWarning);
    };
  });
};

// CREATE JOB STEPS REDIRECTION BASED ON FORM STATE, i.e., DIRTY OR NOT AND REDIRECTION ROUTE
export const useCreateJobRedirection = (
  redirectTo: string | undefined,
  setRedirectTo: React.Dispatch<React.SetStateAction<string>> | undefined,
  isDirty: boolean | undefined,
  match: any,
) => {
  useEffect(() => {
    if (redirectTo === '/application-form' && !isDirty) {
      const applicationFormRoute = `${removeLastRouteString(match)}${redirectTo}`;
      if (setRedirectTo) setRedirectTo('');
      history.push(applicationFormRoute);
    }

    if (redirectTo === '/workflow' && !isDirty) {
      const workflowRoute = `${removeLastRouteString(match)}${redirectTo}`;
      if (setRedirectTo) setRedirectTo('');
      history.push(workflowRoute);
    }

    if (redirectTo === '/job-details' && !isDirty) {
      const jobDetailsRoute = `${removeLastRouteString(match)}${redirectTo}`;
      if (setRedirectTo) setRedirectTo('');
      history.push(jobDetailsRoute);
    }
  }, [redirectTo, isDirty, match, setRedirectTo]);
};

// Request states (isLoading, success, error) with toasts
export const useRequestStatesToast = (states: {
  isLoading: boolean;
  success: boolean;
  error: ErrorInfo;
}) => {
  const { success, error } = states;
  useEffect(() => {
    if (success) toast.success('Success!', toastConfig);
  }, [success]);

  useEffect(() => {
    if (error.message) {
      toast.error(error.message, toastConfig);
    }
  }, [error]);
};

//toast
export const useMyToast = (
  error: ErrorInfo,
  loading: boolean,
  success?: { success: boolean; message: string },
) => {
  useEffect(() => {
    if ((error.message || error.statusCode === 500) && !loading) {
      toast.error(error.message || 'server error', toastConfig);
    }
  }, [error, loading]);
  useEffect(() => {
    if (success && success.success && !loading) {
      toast.success(success.message, toastConfig);
    }
  }, [success?.success, loading]);
};

export const useRequestStates = (
  selectIsLoading: (state: RootState) => boolean,
  selectSuccess: (state: RootState) => boolean,
  selectError: (state: RootState) => ErrorInfo,
) => {
  const loading = useSelector(selectIsLoading);
  const success = useSelector(selectSuccess);
  const error = useSelector(selectError);

  return { loading, success, error };
};

export const useOrganizationSlug = () => {
  const location = useLocation();
  const organizationSlug = location.pathname.split('/')[2];
  return organizationSlug;
};

export const useCurrentOrganization = () => {
  const organizationSlug = useOrganizationSlug();
  const organizations = useSelector(selectUserOrganizations);
  return organizations?.find((organization) => organization.slug === organizationSlug);
};

export const useOrganizationId = () => {
  const currentOrganization = useCurrentOrganization();
  return currentOrganization?.id;
};

export const useHasOrganization = () => {
  const organizations = useSelector(selectUserOrganizations);
  const hasOrganization = !!(organizations && organizations[0]);
  return hasOrganization;
};
