import * as React from 'react';
import NoMatch from 'assets/svg/images/NoMatch';

export interface INotFoundProps {}

export default function NotFound(props: INotFoundProps) {
  return (
    <div className="dashboard__zero-state">
      <NoMatch />
      <h3>Page not found</h3>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
        incididunt ut labore et dolore magna aliqua.
      </p>
    </div>
  );
}
