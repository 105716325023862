import { combineReducers } from 'redux';
import { showJobReducer } from './show-job';
import { updateJobReducer } from './create-job/create-job-stages/job-details/update-job-details';
import createJobReducer from './create-job/ui.reducer';
import { listStageApplicationsReducer } from './show-job/list-stage-applicants';
import { updateApplicationsReducer } from './show-job/update-application';

export default combineReducers({
  create: createJobReducer,
  show: showJobReducer,
  update: updateJobReducer,
  listStageApplications: listStageApplicationsReducer,
  updateApplication: updateApplicationsReducer,
});
