import { useOrganizationId } from 'hooks';
import * as React from 'react';
import { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { createDepartmentStart } from './actions';

export interface ICreateDepartmentModalProps {
  handleClose: () => void;
  showModal: boolean;
}

export default function CreateDepartmentModal(props: ICreateDepartmentModalProps) {
  const { showModal, handleClose } = props;
  const dispatch = useDispatch();
  const organizationId = useOrganizationId();
  const [departmentName, setDepartmentName] = useState('');

  const addDepartment = () => {
    handleClose();
    if (organizationId) {
      dispatch(createDepartmentStart({ departmentName, organizationId }));
    }
  };
  return (
    <div className="position-absolute">
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add new department</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Label>Department name</Form.Label>
          <Form.Control
            onChange={(e) => setDepartmentName(e.target.value)}
            value={departmentName}
            placeholder="Type name"
          />
        </Modal.Body>
        <Modal.Footer>
          <Button disabled={!departmentName} variant="primary" onClick={addDepartment}>
            Add
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
