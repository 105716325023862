import { combineReducers } from 'redux';
import { createSectionReducer } from './create-section';
import { listSectionsReducer } from './list-sections';
import { showSectionReducer } from './show-section';
import { updateSectionReducer } from './update-section';

export default combineReducers({
  list: listSectionsReducer,
  show: showSectionReducer,
  create: createSectionReducer,
  update: updateSectionReducer,
});
