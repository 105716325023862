import * as Yup from 'yup';
import * as React from 'react';
import { Styles } from 'react-select';
import { Formik, Form } from 'formik';
import { Button, Spinner } from 'react-bootstrap';
import { useEffect, useMemo, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useRouteMatch } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { updateJobStart } from 'features/job/create-job/create-job-stages/job-details/update-job-details/actions';
import { OnSubmitFormik } from 'features/authentication/types';
import {
  useAdjustButtonPosition,
  useUnsavedChangesPopup,
  useCreateJobRedirection,
  useMyToast,
  useOrganizationId,
} from 'hooks';
import {
  RedirectToContext,
  IsDirtyContext,
  JobDetailsModalContext,
} from 'features/job/create-job';
import LeavePage from 'features/job/create-job/components/LeavePage';
import { JobFormValues } from '../types';
import JobTitle from '../components/JobTitle';
import LocationDetails from '../components/LocationDetails';
import EmploymentDetails from '../components/EmploymentDetails';
import JobDescription from '../components/JobDescription';
import SalaryDetails from '../components/SalaryDetails';
import { createJobStart } from '../../../actions';
import { initialValues, populateFields } from './slice';
import { selectJobDetails } from '../job-details.slice';
import { resetToInitials as selectCreateJobReset } from '../../../slice';
import { resetToInitials as selectUpdateJobDetailsReset } from '../update-job-details/slice';

import { ToastContainer } from 'react-toastify';
import useCreateJobRequestStates from 'features/job/create-job/hooks/useCreateJobRequestStates';
import useUpdateJobDetailsRequestStates from 'features/job/create-job/hooks/useUpdateJobDetailsRequestStates';

export interface IShowJobDetailsProps {}

// FORM INPUT VALIDATION
export const validationSchema = Yup.object({
  jobTitle: Yup.string().trim().required('Required'),
  department: Yup.string().trim(),
  location: Yup.object({
    zipCode: Yup.number().integer(),
  }),
  salaryDetails: Yup.object({
    from: Yup.number().integer().positive(),
    to: Yup.number().integer().positive(),
  }),
});

// REACT-SELECT STYLES
const selectStyles: Partial<Styles> = {
  control: (styles: React.CSSProperties) => ({ ...styles, fontSize: '13px' }),
  menu: (styles: React.CSSProperties) => ({ ...styles, zIndex: 100 }),
};

export const StylesContext = React.createContext({});

export default function ShowJobDetails() {
  // state...
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const organizationId = useOrganizationId();
  const jobDetails = useSelector(selectJobDetails);
  const [applicationFields, setApplicationFields] = React.useState(initialValues);
  const { redirectTo, setRedirectTo } = useContext(RedirectToContext);
  const JobTitleMemoized = useMemo(() => <JobTitle />, []);
  const LocationDetailsMemoized = useMemo(() => <LocationDetails />, []);
  const EmploymentDetailsMemoized = useMemo(() => <EmploymentDetails />, []);
  const JobDescriptionMemoized = useMemo(() => <JobDescription />, []);
  const SalaryDetailsMemoized = useMemo(() => <SalaryDetails />, []);
  const { isDirty, setDirty } = useContext(IsDirtyContext);
  const { showJobDetailsModal, setShowJobDetailsModal } = useContext(
    JobDetailsModalContext,
  );
  const {
    createJobLoading,
    createJobSuccess,
    createJobError,
  } = useCreateJobRequestStates();
  const {
    updateJobDetailsLoading,
    updateJobDetailsSuccess,
    updateJobDetailsError,
  } = useUpdateJobDetailsRequestStates();

  // hooks
  useCreateJobRedirection(redirectTo, setRedirectTo, isDirty, match);
  useUnsavedChangesPopup(isDirty);
  useMyToast(createJobError, createJobLoading, {
    success: createJobSuccess,
    message: 'Job created successfuly!',
  });
  useMyToast(updateJobDetailsError, updateJobDetailsLoading, {
    success: updateJobDetailsSuccess,
    message: 'Job updated successfuly!',
  });

  const adjustPosition = useAdjustButtonPosition();

  // effects
  useEffect(() => {
    if (jobDetails) {
      setApplicationFields(() => populateFields(jobDetails));
    }

    return () => {
      if (setShowJobDetailsModal) setShowJobDetailsModal(false);
    };
  }, [jobDetails, setShowJobDetailsModal]);

  useEffect(() => {
    return function () {
      dispatch(selectCreateJobReset());
      dispatch(selectUpdateJobDetailsReset());
    };
  }, [dispatch]);

  // methods
  const onSubmit: OnSubmitFormik<JobFormValues> = (values) => {
    if (jobDetails?.id) {
      if (organizationId) {
        dispatch(
          updateJobStart({ jobDetails: values, jobId: jobDetails.id, organizationId }),
        );
      }
    } else {
      if (organizationId) {
        dispatch(createJobStart({ jobInfo: values, organizationId }));
      }
    }
  };

  const onCancel = () => {
    if (setShowJobDetailsModal) setShowJobDetailsModal(false);
  };

  const onSave = async (submitForm: (() => Promise<void>) & (() => Promise<any>)) => {
    try {
      await submitForm();
      if (setDirty) setDirty(false);
    } catch (error) {
      alert(error.message);
    }
  };

  return (
    <StylesContext.Provider value={selectStyles}>
      <div className="container">
        <Formik
          enableReinitialize
          initialValues={applicationFields}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ isSubmitting, dirty, submitForm }) => (
            <Form
              onMouseOut={() => {
                if (setDirty) setDirty(dirty);
              }}
              onKeyDown={(e) => (e.keyCode === 13 ? e.preventDefault() : null)}
            >
              {isDirty && showJobDetailsModal && (
                <LeavePage
                  handleCancel={onCancel}
                  handleSave={() => onSave(submitForm)}
                  show
                />
              )}
              {JobTitleMemoized}
              {LocationDetailsMemoized}
              {EmploymentDetailsMemoized}
              {JobDescriptionMemoized}
              {SalaryDetailsMemoized}
              <Button
                style={{ paddingLeft: '20.2px' }}
                type="submit"
                className={`btn-light btn-job-primary ${
                  adjustPosition ? 'adjusted' : ''
                }`}
                disabled={createJobLoading || updateJobDetailsLoading || !isDirty}
              >
                Save Changes
              </Button>
            </Form>
          )}
        </Formik>
        <div className="btns-space-between">
          <div aria-disabled={!jobDetails?.status} className="ml-auto">
            <Link
              className="btn-next"
              to={`${match.url.replace('job-details', 'application-form')}`}
            >
              <span>Application Form</span>
              <FontAwesomeIcon size="lg" icon="chevron-circle-right" color="" />
            </Link>
          </div>
        </div>
      </div>

      {(createJobLoading || updateJobDetailsLoading) && <Spinner animation="border" />}
      {(createJobError.message ||
        createJobError.statusCode === 500 ||
        createJobSuccess) && <ToastContainer />}
      {(updateJobDetailsError.message ||
        updateJobDetailsError.statusCode === 500 ||
        updateJobDetailsSuccess) && <ToastContainer />}
    </StylesContext.Provider>
  );
}
