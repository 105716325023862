import { takeLatest, put, call } from 'redux-saga/effects';
import { showPipelineStart } from './show-pipeline.actions';
import { loading, success, failure } from './show-pipeline.slice';
import { showPipeline } from './show-pipeline.service';
import { PipelineInfo } from '../../types';
import { setPipeline } from '../pipeline.slice';
import { Action } from '@reduxjs/toolkit';

function* showPipelineFlow(action: Action<unknown>) {
  if (showPipelineStart.match(action)) {
    try {
      yield put(loading());
      const { data } = yield call(showPipeline, action.payload);
      const { data: pipeline }: { data: PipelineInfo } = data;
      yield put(setPipeline(pipeline));
      yield put(success());
    } catch (error) {
      const { message }: { message: string } = error?.response?.data || error;
      const statusCode: number = error?.response?.status;
      yield put(failure({ message, statusCode }));
    }
  }
}

export function* onShowPipelineStart() {
  yield takeLatest(showPipelineStart, showPipelineFlow);
}
