import * as React from 'react';
import { useSelector } from 'react-redux';
import { Col, Card } from 'react-bootstrap';
import { Redirect, useParams, useRouteMatch } from 'react-router-dom';
import Applicant from './Applicant';
import ContactDetails from './ContactDetails';
import QuestionsAnswers from './QuestionsAnswers';
import ApplicationControls from './ApplicationControls';
import { selectStageApplications } from '../stage-applications.slice';

export interface IApplicationProps {
  stageId: string;
  stageApplicationId: number | null;
}

export default function Application(props: IApplicationProps) {
  const { stageId, stageApplicationId } = props;
  const match = useRouteMatch();
  const { applicationId } = useParams<{ applicationId: string }>();
  const validApplicationId = applicationId || stageApplicationId;
  let stageApplication = useSelector(selectStageApplications).info?.find(
    (application) => application.id.toString() === validApplicationId?.toString(),
  );

  React.useEffect(() => () => {
    stageApplication = undefined;
  });

  if (!applicationId && stageApplicationId)
    return <Redirect to={`${match.url}/${stageApplicationId}`} />;

  return (
    <>
      <Col lg={9}>
        <Card>
          <Card.Body>
            {stageApplication && (
              <div className="application">
                <div className="application__header">
                  <Applicant stageApplication={stageApplication} />
                  <ApplicationControls
                    stageApplication={stageApplication}
                    stageId={stageId}
                  />
                </div>
                <ul className="applicants__nav">
                  <li>
                    <a href="#">Overview</a>
                  </li>
                  <li>
                    <a>Email</a>
                  </li>
                  <li>
                    <a>Activity</a>
                  </li>
                </ul>
                <ContactDetails
                  email={stageApplication.applicant.email}
                  phone={stageApplication.phone_number}
                />
                <QuestionsAnswers questions={stageApplication.application_answers} />
              </div>
            )}
          </Card.Body>
        </Card>
      </Col>
    </>
  );
}
