import * as React from 'react';
import { StageInfo } from '../../types';
import { useState } from 'react';
import StageEditting from './StageEditting';
import StageSaved from './StageSaved';

export interface IStageProps {
  stage: StageInfo;
  index: number;
  newStage?: boolean;
}

export default function Stage(props: IStageProps) {
  const { index, stage, newStage } = props;
  const [isEditting, setIsEditting] = useState<boolean>(false);

  return isEditting || newStage ? (
    <StageEditting index={index} stage={stage} setIsEditting={setIsEditting} />
  ) : (
    <StageSaved index={index} stage={stage} setIsEditting={setIsEditting} />
  );
}
