import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'redux/root-reducer';
import { OrganizationInfo } from 'redux/types';

const initialState: { info: OrganizationInfo | null } = {
  info: null,
};

const organizationSlice = createSlice({
  name: 'ORGANIZATION',
  initialState,
  reducers: {
    setOrganizationInfo: (state, action: PayloadAction<OrganizationInfo>) => {
      state.info = action.payload;
    },
    resetOrganizationInfo: (state) => {
      state.info = initialState.info;
    },
  },
});

// REDUCER
export default organizationSlice.reducer;

// ACTIONS
export const { setOrganizationInfo, resetOrganizationInfo } = organizationSlice.actions;

// SELECTORS
export const selectOrganizationInfo = (state: RootState) =>
  state.organization.organizationReducer.info;
