import { takeLatest, put, call } from 'redux-saga/effects';
import { createPipelineTemplateStart } from './create-pipeline-template.actions';
import { loading, success, failure } from './create-pipeline-template.slice';
import { createPipelineTemplate } from './create-pipeline-template.service';
import { Action } from '@reduxjs/toolkit';

function* createPipelineTemplateFlow(action: Action<unknown>) {
  if (createPipelineTemplateStart.match(action)) {
    try {
      yield put(loading());
      yield call(createPipelineTemplate, action.payload);
      yield put(success());
    } catch (error) {
      const { message }: { message: string } = error?.response?.data || error;
      const statusCode: number = error?.response?.status;
      yield put(failure({ message, statusCode }));
    }
  }
}

export function* onCreatePipelineTemplateStart() {
  yield takeLatest(createPipelineTemplateStart, createPipelineTemplateFlow);
}
