import {
  faSearch,
  faChevronCircleLeft,
  faChevronCircleRight,
  faPen,
  faTrashAlt,
  faBars,
  faTimes,
  faPhone,
  faEnvelope,
  faBan,
  faBookmark,
  faArrowRight,
  faArrowLeft,
  faThumbtack,
  faEllipsisV,
  faChevronDown,
} from '@fortawesome/free-solid-svg-icons';

import { library } from '@fortawesome/fontawesome-svg-core';

library.add(
  faSearch,
  faChevronCircleLeft,
  faChevronCircleRight,
  faPen,
  faTrashAlt,
  faBars,
  faTimes,
  faEnvelope,
  faPhone,
  faBan,
  faBookmark,
  faArrowRight,
  faArrowLeft,
  faThumbtack,
  faEllipsisV,
  faChevronDown,
);
