import { call, put, takeLatest, select } from 'redux-saga/effects';

import { UserInfo } from 'redux/types';
import { getUploadTokensAndUploadAvatar } from 'features/UploadAvatar/upload-avatar.saga';
import { selectTokens } from 'features/UploadAvatar/get-upload-tokens.slice';
import { setUserInfo } from '../slice';
import { loading, success, failure } from './slice';
import { updateProfileInfoStart } from './actions';
import { updateProfileInfo } from './service';
import { Action } from '@reduxjs/toolkit';

function* updateProfileInfoFlow(action: Action<unknown>) {
  if (updateProfileInfoStart.match(action)) {
    const { values, avatar } = action.payload;
    try {
      yield put(loading());
      let info;
      if (avatar !== undefined) {
        yield call(getUploadTokensAndUploadAvatar, avatar);
        const { file_url } = selectTokens(yield select());
        const { data } = yield call(updateProfileInfo, {
          ...values,
          avatar: file_url,
        });
        info = data;
      } else {
        const { data } = yield call(updateProfileInfo, values);
        info = data;
      }
      const { data: userInfo }: { data: UserInfo } = info;
      yield put(setUserInfo(userInfo));
      yield put(success());
    } catch (error) {
      const { message }: { message: string } = error?.response?.data || error;
      const statusCode: number = error?.response?.status;
      yield put(failure({ message, statusCode }));
    }
  }
}

export function* onGetUploadTokensAndUpdateProfileInfoStart() {
  yield takeLatest(updateProfileInfoStart, updateProfileInfoFlow);
}
