import { takeLatest, call, put } from 'redux-saga/effects';
import { updateApplicationFormStart } from './actions';
import { loading, success, failure } from './slice';
import { setApplicationForm } from '../slice';
import { updateApplicationForm } from './service';

function* updateApplicationFormFlow(action: any) {
  if (updateApplicationFormStart.match(action)) {
    try {
      yield put(loading());
      const { data } = yield call(updateApplicationForm, action.payload);
      const { data: applicationForm } = data;
      yield put(setApplicationForm(applicationForm));
      yield put(success());
    } catch (error) {
      const { message }: { message: string } = error?.response?.data || error;
      const statusCode: number = error?.response?.status;
      yield put(failure({ message, statusCode }));
    }
  }
}

export default function* onUpdateApplicationFormStart() {
  yield takeLatest(updateApplicationFormStart, updateApplicationFormFlow);
}
