import { all, call } from 'redux-saga/effects';
import onAuthentication from '../features/authentication/saga';
import onUserAction from '../features/user/saga';
import onOrganizationAction from '../features/organization/saga';
import onInvitationsAction from 'features/invitations/saga';
import onJobAction from 'features/job/saga';
import onJobsAction from 'features/jobs/sags';

export default function* rootSaga() {
  yield all([
    call(onAuthentication),
    call(onUserAction),
    call(onOrganizationAction),
    call(onInvitationsAction),
    call(onJobAction),
    call(onJobsAction),
  ]);
}
