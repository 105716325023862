import api from 'services/api';

export async function getInvitation({
  id,
  organizationId,
}: {
  id: string;
  organizationId: string;
}) {
  return api.get(`/api/dashboard/organizations/${organizationId}/invitations/${id}`);
}
