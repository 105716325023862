import { takeLatest, put, call } from 'redux-saga/effects';

import { history } from 'redux/store';
import { UserInfo } from 'redux/types';
import { setUserInfo } from 'features/user/slice';
import { loading, success, failure } from './slice';
import { signupEmployeeStart } from './actions';
import { signupEmployee } from './service';
import { Action } from '@reduxjs/toolkit';

function* signupEmployeeFlow(action: Action<unknown>) {
  if (signupEmployeeStart.match(action)) {
    try {
      yield put(loading());
      const { data } = yield call(signupEmployee, action.payload);
      const { data: userInfo }: { data: UserInfo } = data;
      yield put(success());
      yield put(setUserInfo(userInfo));
      yield history.push('/login');
    } catch (error) {
      const { message }: { message: string } = error?.response?.data || error;
      const statusCode: number = error?.response?.status;
      yield put(failure({ message, statusCode }));
    }
  }
}

export function* onSignupEmployeeStart() {
  yield takeLatest(signupEmployeeStart, signupEmployeeFlow);
}
