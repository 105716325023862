import * as React from 'react';
import { useSelector } from 'react-redux';
import { Button, Dropdown } from 'react-bootstrap';

import Logout from 'features/authentication/logout';
import { selectUser, selectUserOrganizations } from 'features/user/slice';
import { Link } from 'react-router-dom';

export interface IProfileDropdownProps {}

export default function ProfileDropdown(props: IProfileDropdownProps) {
  const userInfo = useSelector(selectUser);
  const organizations = useSelector(selectUserOrganizations);
  return (
    <Dropdown>
      <Dropdown.Toggle className="profile-dropdown" id="dropdown-basic">
        <span className="profile-dropdown__text">{userInfo.initials}</span>
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {organizations?.map((organization) => (
          <Link
            key={organization.id}
            component={Dropdown.Item}
            to={`/dashboard/${organization.slug}/jobs`}
          >
            {organization.name}
          </Link>
        ))}

        <Dropdown.Item>
          <Logout />
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}
