import * as React from 'react';
import { useContext } from 'react';
import { Form } from 'react-bootstrap';
import { SelectedFiltersContext } from '../list-candidates';
import { Filter } from '../types';

export interface IFilterRadioProps {
  filter: Filter;
}

export default function FilterRadio(props: IFilterRadioProps) {
  const { filter } = props;
  const { field: filterName } = filter.metadata;
  const { selectedFilters, setSelectedFilters } = useContext(SelectedFiltersContext);

  const onFilterSelection = (e: any) => {
    if (setSelectedFilters) {
      setSelectedFilters({
        ...selectedFilters,
        [filterName]: e.target.value,
      });
    }
  };

  return (
    <div className="candidates-filter">
      <p className="candidates-filter__label">{filter.metadata.group_label}:</p>

      <Form.Group
        className="candidates-filter__options"
        onChange={onFilterSelection}
        controlId={filter.metadata.field}
      >
        {filter.options.map((option) => (
          <Form.Group controlId={option.label}>
            <Form.Check
              name={filter.metadata.field}
              type="radio"
              value={option.value}
              label={option.label}
            />
          </Form.Group>
        ))}
      </Form.Group>
    </div>
  );
}
