import { put, takeLatest, call } from 'redux-saga/effects';
import { listStageApplicationsStart } from './actions';
import { loading, success, failure } from './slice';
import { getStageApplications } from './service';
import { Action } from '@reduxjs/toolkit';
import { setStageApplications } from './stage-applications.slice';
import { IStageApplication } from 'features/job/types';

function* listStageApplicationsFlow(action: Action<unknown>) {
  if (listStageApplicationsStart.match(action)) {
    try {
      yield put(loading());
      const { data } = yield call(getStageApplications, action.payload);
      const { data: stageApplications }: { data: IStageApplication[] } = data;
      yield put(setStageApplications(stageApplications));
      yield put(success());
    } catch (error) {
      const { message }: { message: string } = error?.response?.data || error;
      const statusCode: number = error?.response?.status;
      yield put(failure({ message, statusCode }));
    }
  }
}

export default function* onListStageApplicationsStart() {
  yield takeLatest(listStageApplicationsStart, listStageApplicationsFlow);
}
