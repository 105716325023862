import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import { updatePipelineStart } from './update-pipeline.actions';
import { selectPipeline } from '../pipeline.slice';
import { createPipelineTemplateStart } from '../create-pipeline-template/create-pipeline-template.actions';
import { useAdjustButtonPosition, useOrganizationId } from 'hooks';
import { DeletedStagesContex } from '../show-pipeline';

export { default as updatePipelineReducer } from './update-pipeline.slice';

export interface IUpdatePipelineProps {
  saveDefault: boolean;
}

export default function UpdatePipeline(props: IUpdatePipelineProps) {
  const { saveDefault } = props;
  const { deletedStages } = React.useContext(DeletedStagesContex);
  const dispatch = useDispatch();
  const pipeline = useSelector(selectPipeline);
  const [disabled, setDisabled] = React.useState(false);
  const adjustPosition = useAdjustButtonPosition();
  const organizationId = useOrganizationId();

  return (
    <Button
      className={`btn-light btn-job-primary btn-abs ${adjustPosition ? 'adjusted' : ''}`}
      disabled={disabled}
      onClick={() => {
        setDisabled(true);
        setTimeout(() => {
          if (saveDefault && organizationId) {
            dispatch(
              createPipelineTemplateStart({
                organizationId,
                pipelineInfo: {
                  save_as_default: 'true',
                  stages_attributes: [
                    ...pipeline.info!.stages.map((stage) => ({
                      ...stage,
                      id: undefined,
                    })),
                  ],
                },
              }),
            );
          }
          if (organizationId) {
            dispatch(
              updatePipelineStart({
                organizationId,
                pipelineInfo: {
                  pipelineId: pipeline.info!.id,
                  stages_attributes: [
                    ...pipeline.info!.stages,
                    ...deletedStages?.map((stage) => ({ ...stage, _destroy: 1 })),
                  ],
                },
              }),
            );
          }
          setDisabled(false);
        }, 500);
      }}
    >
      Save Changes
    </Button>
  );
}
