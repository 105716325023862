import { call, takeLatest, put } from 'redux-saga/effects';
import { loading, success, failure } from './slice';
import { resetPasswordStart } from './actions';
import { resetPassword } from './service';
import { Action } from '@reduxjs/toolkit';

function* resetUserPasswordFlow(action: Action<unknown>) {
  if (resetPasswordStart.match(action)) {
    try {
      yield put(loading());
      // API CALL
      yield call(resetPassword, action.payload);
      yield put(success());
    } catch (error) {
      const { message }: { message: string } = error?.response?.data || error;
      const statusCode: number = error?.response?.status;
      yield put(failure({ message, statusCode }));
    }
  }
}

export function* onResetPasswordStart() {
  yield takeLatest(resetPasswordStart, resetUserPasswordFlow);
}
