import * as React from 'react';

function SvgDropdown(props: {}) {
  return (
    <svg width={22} height={22} {...props}>
      <defs>
        <style>{'.Dropdown_svg__a{fill:#b2b2b2}'}</style>
      </defs>
      <path
        className="Dropdown_svg__a"
        d="M11 0a11 11 0 1011 11A11.032 11.032 0 0011 0zm0 19.8a8.8 8.8 0 118.8-8.8 8.826 8.826 0 01-8.8 8.8z"
      />
      <path className="Dropdown_svg__a" d="M6 8.954l4.809 4.809 4.808-4.809z" />
    </svg>
  );
}

export default SvgDropdown;
