import * as React from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

export interface ICreateTemplateModalProps {
  handleClose: () => void;
  onSave: () => void;
  showModal: boolean;
  templateNameState: {
    templateName: string;
    setTemplateName: React.Dispatch<React.SetStateAction<string>>;
  };
}

export default function CreateTemplateModal(props: ICreateTemplateModalProps) {
  const {
    showModal,
    handleClose,
    onSave,
    templateNameState: { templateName, setTemplateName },
  } = props;

  return (
    <Modal show={showModal} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Add new template</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Label>Template name</Form.Label>
        <Form.Control
          onChange={(e) => setTemplateName(e.target.value)}
          value={templateName}
          placeholder="Type name"
        />
      </Modal.Body>
      <Modal.Footer>
        <Button disabled={!templateName} variant="primary" onClick={onSave}>
          Add
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
