import * as React from 'react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';

import { selectIsLoading, selectSuccess, selectError } from './slice';
import { selectOrganizationInfo } from '../slice';
import { Spinner } from 'react-bootstrap';

export { onGetOrganizationStart } from './saga';
export { default as showOrganizationReducer } from './slice';

export interface IShowOrganizationProps {}

// eslint-disable-next-line no-unused-vars
export default function ShowOrganization(props: IShowOrganizationProps) {
  const dispatch = useDispatch();
  const { id }: { id: string } = useParams();
  const isLoading = useSelector(selectIsLoading);
  const success = useSelector(selectSuccess);
  const error = useSelector(selectError);
  const organizationInfo = useSelector(selectOrganizationInfo);

  useEffect(() => {
    if (id && organizationInfo?.id === undefined) {
      // dispatch(getOrganizationStart({ id }));
    }
  }, [id, dispatch, organizationInfo]);

  if (isLoading) return <Spinner animation="border" />;
  if (error.message) {
    toast.error(error.message, {
      position: 'top-right',
      autoClose: false,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
    });
  }

  return (
    <div>
      {success && (
        <div>
          <h2> show organization </h2>
          <p>{`Organization Name: ${organizationInfo?.name}`}</p>
          <ul>
            <li>{`Organization Summary: ${organizationInfo?.summary}`}</li>
            <li>{`Organiztion Slug: ${organizationInfo?.slug}`}</li>
            <li>
              <p>Logo</p>
              <img src={organizationInfo?.logo} alt="logo" />
            </li>
            <div>
              <p>Memebers: </p>
              {organizationInfo?.members?.map((member) => (
                <div key={member.email}>
                  <div>{member.first_name}</div>
                  <div>{member.last_name}</div>
                  <img src={member.avatar} alt="avatar" width="100px" />
                </div>
              ))}
            </div>
          </ul>
        </div>
      )}
      {error.message && <ToastContainer />}
    </div>
  );
}
