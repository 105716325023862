import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form as FormikForm, Field, ErrorMessage, FieldProps } from 'formik';
import { Button, Form } from 'react-bootstrap';
import { ToastContainer } from 'react-toastify';
import * as Yup from 'yup';

import { useCleanup, useOrganizationId, useRequestStatesToast } from 'hooks';
import { OnUpdateOrganizationSubmit } from 'features/authentication/types';
import { selectError, selectIsLoading, selectSuccess, resetToInitials } from './slice';
import { updateOrganizationStart } from './actions';
import { Card, FormGroup, Spinner } from 'react-bootstrap';
import { selectOrganizationInfo } from '../slice';
import { useDropzone } from 'react-dropzone';
import AvatarPreview from 'components/AvatarPreview';

export { default as updateOrganizationReducer } from './slice';
export { onGetUploadTokensAndupdateOrganizationStart } from './saga';

const initialValues = {
  name: '',
  website: '',
  slug: '',
  phone_number: '',
  logo: '',
};

const populateFields = (values: {
  name: string;
  logo: string;
  website: string;
  slug: string;
  phone_number: string;
}) => ({
  name: values.name,
  website: values.website,
  slug: values.slug,
  phone_number: values.phone_number,
  logo: values.logo,
});

// FORM VALIDATIONshowOrganization: showOrganization:
const validationSchema = Yup.object({
  name: Yup.string(),
  slug: Yup.string()
    .min(3, 'Slug is too short')
    .matches(/^[a-zA-Z0-9]+[a-zA-Z0-9\-_]*[a-zA-Z0-9]+$/, 'slug is invalid'),
});

export interface IUpdateOrganizationProps {}

// eslint-disable-next-line no-unused-vars
export default function UpdateOrganization(props: IUpdateOrganizationProps) {
  const dispatch = useDispatch();
  const organizationId = useOrganizationId();
  const organizationInfo = useSelector(selectOrganizationInfo);
  const isLoading = useSelector(selectIsLoading);
  const success = useSelector(selectSuccess);
  const error = useSelector(selectError);
  const [image, setImage] = useState<File>();
  const [fileName, setFileName] = useState();
  const [formFields, setFormFields] = useState(initialValues);

  useCleanup(resetToInitials, error.message, success);
  useRequestStatesToast({ isLoading, success, error });

  const onSubmit: OnUpdateOrganizationSubmit = (values, { resetForm, setSubmitting }) => {
    if (organizationId) {
      if (image) {
        setSubmitting(false);
        resetForm();
        const valuesAndImage = {
          id: organizationId,
          values: { ...values },
          image: { file: image, expires_in: 120 },
        };
        dispatch(updateOrganizationStart(valuesAndImage));
      } else {
        dispatch(updateOrganizationStart({ id: organizationId, values }));
      }
    }
  };

  useEffect(() => {
    if (organizationInfo) {
      setFormFields(() =>
        populateFields({
          name: organizationInfo.name,
          logo: organizationInfo.name,
          phone_number: organizationInfo.phone_number || '',
          website: organizationInfo.website || '',
          slug: organizationInfo.slug,
        }),
      );
    }
  }, [organizationInfo]);

  const onDrop = useCallback((acceptedFiles) => {
    // Do something with the files
    setFileName(acceptedFiles[0].name);
    setImage(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <>
      <Card.Body>
        <Card.Title>Profile company</Card.Title>
        <Formik
          enableReinitialize
          validationSchema={validationSchema}
          initialValues={formFields}
          onSubmit={onSubmit}
        >
          <FormikForm>
            <div className="inputs-flex">
              <FormGroup className="input-element" controlId="companyName">
                <Form.Label>Company name</Form.Label>
                <Field
                  name="name"
                  type="text"
                  placeholder="Type company name"
                  as={Form.Control}
                />
                <ErrorMessage className="error" name="name" component="div" />
              </FormGroup>
              <FormGroup className="input-element" controlId="website">
                <Form.Label>Website</Form.Label>
                <Field
                  name="website"
                  type="text"
                  placeholder="Type Website"
                  as={Form.Control}
                />
                <ErrorMessage className="error" name="website" component="div" />
              </FormGroup>
            </div>
            <div className="inputs-flex mb-4">
              <FormGroup className="input-element" controlId="slug">
                <Form.Label>Subdomain</Form.Label>
                <Field
                  name="slug"
                  type="text"
                  placeholder="Type slug"
                  as={Form.Control}
                />
                <ErrorMessage className="error" name="slug" component="div" />
              </FormGroup>
              <FormGroup className="input-element" controlId="phoneNumber">
                <Form.Label>Phone number</Form.Label>
                <Field
                  name="phone_number"
                  type="text"
                  placeholder="Type phone number"
                  as={Form.Control}
                />
                <ErrorMessage className="error" name="phone_number" component="div" />
              </FormGroup>
            </div>
            <FormGroup className="input-element mr-0" controlId="logo">
              <Card.Title className="mb-1">Company logo</Card.Title>
              <small style={{ color: '#B5B5B5' }} className="d-block mb-2">
                Image is visible when shared on social media
              </small>
              <Field name="logo">
                {({ field, form }: FieldProps<any, any>) => (
                  <div {...getRootProps()}>
                    <input
                      {...getInputProps()}
                      onChange={(e) => {
                        if (e.currentTarget.files) {
                          form.setFieldValue('logo', e.currentTarget.files[0]?.name);
                          setImage(e.currentTarget.files[0]);
                        } else {
                          return undefined;
                        }
                      }}
                    />

                    <p className="dropzone">
                      {image ? (
                        <AvatarPreview image={image} />
                      ) : organizationInfo?.logo ? (
                        <img
                          alt="organization logo"
                          width="100px"
                          src={organizationInfo?.logo}
                        />
                      ) : (
                        <>
                          <p className=" ">{fileName || ''}</p>
                          <span>+</span>Add image
                        </>
                      )}
                    </p>
                  </div>
                )}
              </Field>
              <ErrorMessage className="error" name="slug" component="div" />
            </FormGroup>
            <Button
              disabled={isLoading}
              type="submit"
              className="float-right mt-3"
              variant="primary"
            >
              Save
            </Button>
          </FormikForm>
        </Formik>
      </Card.Body>
      <ToastContainer />
      {isLoading && <Spinner animation="border" />}
    </>
  );
}
