import { takeLatest, put, call } from 'redux-saga/effects';
import { listJobsStart } from './actions';
import { loading, success, failure } from './slice';
import { getJobs } from './service';
import { setJobs } from '../slice';
import { TJob } from '../../job/types';
import { Action } from '@reduxjs/toolkit';

function* listJobsFlow(action: Action<unknown>) {
  if (listJobsStart.match(action)) {
    try {
      yield put(loading());
      const { data } = yield call(getJobs, action.payload.organizationId);
      const { data: jobs }: { data: TJob[] } = data;
      yield put(setJobs(jobs));
      yield put(success());
    } catch (error) {
      const { message }: { message: string } = error?.response?.data || error;
      const statusCode: number = error?.response?.status;
      yield put(failure({ message, statusCode }));
    }
  }
}

export function* onListJobsStart() {
  yield takeLatest(listJobsStart, listJobsFlow);
}
