import api from 'services/api';

export async function updateOrganization(values: any) {
  const response = await api.put(`/api/dashboard/organizations/${values.id}`, {
    organization: {
      ...values,
    },
  });

  return response;
}
