import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { toast, ToastContainer } from 'react-toastify';
import { FormGroup, Form as FormStrap, Button, Card, Spinner } from 'react-bootstrap';

import * as Yup from 'yup';

import HomeHeader from 'pages/home/HomeHeader';
import { useCleanup } from 'hooks';
import { OnSignupOrgSubmit } from '../../types';
import { selectError, selectIsLoading, selectSuccess, resetToInitials } from './slice';
import { signupOrgStart } from './actions';
import { toastConfig } from 'utils';

export { onSignupOrgStart } from './saga';
export { default as signupOrgReducer } from './slice';

export interface ISignupOrgProps {}

// FORM VALIDATION
const validationSchema = Yup.object({
  organization_name: Yup.string()
    .min(3, 'Name is too short')
    .max(15, 'Must be 15 characters or less')
    .required('Required'),
  organization_size: Yup.string().required('Required'),
  country: Yup.string().required('Required'),
  company_name: Yup.string().required('Required'),
});

// eslint-disable-next-line no-unused-vars
export default function SignupOrg() {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectIsLoading);
  const success = useSelector(selectSuccess);
  const error = useSelector(selectError);

  useCleanup(resetToInitials, error.message, success);

  const onSubmit: OnSignupOrgSubmit = (values, { setSubmitting, resetForm }) => {
    setSubmitting(false);
    // resetForm();
    dispatch(signupOrgStart(values));
  };

  if (isLoading) return <Spinner animation="border" />;
  if (success) return <div>success</div>;
  if (error.message) {
    toast.error(error.message, toastConfig);
  }

  return (
    <div className="home">
      <HomeHeader />
      <div className="hero">
        <Card className="hero__content auth">
          <Card.Header>Sign Up</Card.Header>
          <Card.Body>
            <Formik
              initialValues={{
                organization_name: '',
                organization_size: '',
                country: '',
                company_domain: '',
              }}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              <Form>
                <FormGroup>
                  <FormStrap.Label htmlFor="organization_name">
                    Organization name
                  </FormStrap.Label>
                  <Field
                    placeholder="Type your organization name"
                    as={FormStrap.Control}
                    name="organization_name"
                    type="text"
                  />
                  <ErrorMessage
                    component="div"
                    className="auth__error"
                    name="organization_name"
                  />
                </FormGroup>
                <FormGroup>
                  <FormStrap.Label htmlFor="organization_size">
                    Organization size
                  </FormStrap.Label>
                  <Field
                    placeholder="Type your organization size"
                    as={FormStrap.Control}
                    name="organization_size"
                    type="text"
                  />
                  <ErrorMessage
                    component="div"
                    className="auth__error"
                    name="organization_size"
                  />
                </FormGroup>
                <FormGroup>
                  <FormStrap.Label htmlFor="country">Country</FormStrap.Label>
                  <Field
                    placeholder="Select country"
                    as={FormStrap.Control}
                    name="country"
                    type="country"
                  />
                  <ErrorMessage component="div" className="auth__error" name="country" />
                </FormGroup>
                <FormGroup>
                  <FormStrap.Label htmlFor="company_name">Company name</FormStrap.Label>
                  <Field
                    placeholder="Enter your company name"
                    as={FormStrap.Control}
                    name="company_name"
                    type="company_name"
                  />
                  <ErrorMessage
                    component="div"
                    className="auth__error"
                    name="company_name"
                  />
                </FormGroup>
                <Button className="auth__btn" variant="primary" type="submit">
                  Submit
                </Button>
              </Form>
            </Formik>
          </Card.Body>
        </Card>
        <div className="hero__bg2 hero__bg" />
      </div>
      {error.message && <ToastContainer />}
    </div>
  );
}
