import api from 'services/api';
import { ISelectedFilters } from '../types';
type FilterName = 'jobs' | 'created_after' | 'disqualified';

export async function getCandidates({
  selectedFilters,
  organizationId,
}: {
  selectedFilters: ISelectedFilters;
  organizationId: string;
}) {
  let query = [];

  if (selectedFilters.disqualified != null) {
    query.push(`disqualified=${selectedFilters.disqualified}`);
  }
  if (selectedFilters.created_after != null) {
    query.push(`created_after=${selectedFilters.created_after}`);
  }

  if (selectedFilters.jobs != null) {
    const jobsIdsQuery = [];
    for (let id of selectedFilters.jobs) {
      jobsIdsQuery.push(`jobs[]=${id}`);
    }
    query.push(jobsIdsQuery.join('&'));
  }

  const response = await api.get(
    `api/dashboard/organizations/${organizationId}/applications/search?${query.join(
      '&',
    )}`,
  );
  return response;
}
