import { combineReducers } from '@reduxjs/toolkit';
import uiReducer from './ui-state/root-ui-reducer';
import authenticationReducer from '../features/authentication/slice';
import userReducer from '../features/user/slice';
import invitationReducer from 'features/invitations/invitation.slice';
import invitationsReucer from 'features/invitations/slice';
import { uploadTokensReducer } from '../features/UploadAvatar';
import jobRootReducer from 'features/job/root.reducer';
import { jobsReducer } from 'features/jobs';
import organizationRootReducer from 'features/organization/root.reducer';

const rootReducer = combineReducers({
  ui: uiReducer,
  authentication: authenticationReducer,
  organization: organizationRootReducer,
  user: userReducer,
  job: jobRootReducer,
  jobs: jobsReducer,
  invitation: invitationReducer,
  invitations: invitationsReucer,
  uploadTokens: uploadTokensReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
