import * as React from 'react';
import { useEffect, useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { selectJobs } from '../slice';
import { TJob } from '../../job/types';
import JobCard from './JobCard';
import { listJobsStart } from './actions';
import useUpdateJobDetailsRequestStates from 'features/job/create-job/hooks/useUpdateJobDetailsRequestStates';
import { resetToInitials as updateResetToInitials } from 'features/job/create-job/create-job-stages/job-details/update-job-details/slice';
import { useMyToast, useOrganizationId } from 'hooks';
import { ToastContainer } from 'react-toastify';
import { Spinner } from 'react-bootstrap';
import { resetJobDetails } from 'features/job/create-job/create-job-stages/job-details/job-details.slice';
import NotFound from 'pages/error/NotFound';
export { default as listJobsReducer } from './slice';

export interface IJobsProps {}

export default function Jobs() {
  const dispatch = useDispatch();
  const jobs = useSelector(selectJobs);
  const match: { url: string; params: { organizationSlug: string } } = useRouteMatch();
  const organizationId = useOrganizationId();
  const [showNotFound, setShowNotFound] = useState<boolean>(false);

  const {
    updateJobDetailsLoading,
    updateJobDetailsError,
    updateJobDetailsSuccess,
  } = useUpdateJobDetailsRequestStates();

  // JOBS LISTING
  useEffect(() => {
    if (organizationId) {
      dispatch(listJobsStart({ organizationId }));
      setShowNotFound(false);
    } else {
      setShowNotFound(true);
    }
    dispatch(resetJobDetails());
  }, [dispatch, updateJobDetailsSuccess, updateJobDetailsLoading, organizationId, match]);

  useEffect(() => {
    return function () {
      dispatch(updateResetToInitials());
    };
  }, [dispatch]);

  useMyToast(updateJobDetailsError, updateJobDetailsLoading, {
    success: updateJobDetailsSuccess,
    message: 'Success!',
  });

  if (showNotFound) return <NotFound />;

  return (
    <>
      <div className="container">
        <div className="listJobs__header">
          <h2>Current Jobs</h2>
          <div className="listJobs__controls">
            {/* <p className="mr-2">( 2 ) drafts included</p> */}
            <Link className="btn btn-primary" to={`${match.url}/new`}>
              create Job
            </Link>
          </div>
        </div>
        {jobs.length > 0 ? (
          jobs.map((job: TJob) => <JobCard key={job.id} job={job} />)
        ) : (
          <div className="listJobs__zeroState">
            <h3>You don't have any jobs yet</h3>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
              tempor incididunt ut labore et dolore magna aliqua.
            </p>
          </div>
        )}
        {(updateJobDetailsError || updateJobDetailsSuccess) && <ToastContainer />}
        {updateJobDetailsLoading && <Spinner animation="border" />}
      </div>
    </>
  );
}
