import api from 'services/api';

export async function getStageApplications({
  job_id,
  stage_id,
  organizationId,
}: {
  job_id: string;
  stage_id: string;
  organizationId: string;
}) {
  return api.get(
    `api/dashboard/organizations/${organizationId}/jobs/${job_id}/stages/${stage_id}/applications`,
  );
}
