import { Option } from '../types';

export enum Employment {
  FullTime = 'Full-time',
  PartTime = 'Part-time',
  Contract = 'Contract',
  PaidInternship = 'Paid-internship',
  Temporary = 'Temporary',
  Other = 'Other',
}

export const employmentOptions: Option<Employment>[] = [
  { value: Employment.FullTime, label: Employment.FullTime },
  { value: Employment.PartTime, label: Employment.PartTime },
  { value: Employment.Contract, label: Employment.Contract },
  { value: Employment.PaidInternship, label: Employment.PaidInternship },
  { value: Employment.Temporary, label: Employment.Temporary },
  { value: Employment.Other, label: Employment.Other },
];

export enum Experience {
  NonApplicaple = 'Non-applicaple',
  Internship = 'Internship',
  EntryLevel = 'Entry Level',
  Associate = 'associate',
  MidSeniorLevel = 'Mid-Senior Level',
  SeniorLevel = 'Senior Level',
  Director = 'Director',
  Executive = 'Executive',
}

export const experienceOptions: Option<Experience>[] = [
  { value: Experience.NonApplicaple, label: Experience.NonApplicaple },
  { value: Experience.Internship, label: Experience.Internship },
  { value: Experience.EntryLevel, label: Experience.EntryLevel },
  { value: Experience.Associate, label: Experience.Associate },
  { value: Experience.MidSeniorLevel, label: Experience.MidSeniorLevel },
  { value: Experience.SeniorLevel, label: Experience.SeniorLevel },
  { value: Experience.Director, label: Experience.Director },
  { value: Experience.Executive, label: Experience.Executive },
];

export enum Education {
  Unspecified = 'Unspecified',
  HighSchool = 'High school or equivalent',
  Bachelor = 'Bachelor’s Degree',
  Master = 'Master’s Degree',
  Doctorate = 'Doctorate',
}

export const educationOptions: Option<Education>[] = [
  { value: Education.Unspecified, label: Education.Unspecified },
  { value: Education.HighSchool, label: Education.HighSchool },
  { value: Education.Bachelor, label: Education.Bachelor },
  { value: Education.Master, label: Education.Master },
  { value: Education.Doctorate, label: Education.Doctorate },
];
