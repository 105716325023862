import { takeLatest, put, call, select } from 'redux-saga/effects';

import { OrganizationInfo } from 'redux/types';
import { selectTokens } from 'features/UploadAvatar/get-upload-tokens.slice';
import { getUploadTokensAndUploadAvatar } from 'features/UploadAvatar/upload-avatar.saga';
import { setOrganizationInfo } from '../slice';
import { loading, success, failure } from './slice';
import { createOrganizationStart } from './actions';
import { createOrganization } from './service';
import { Action } from '@reduxjs/toolkit';
import { showUserStart } from 'features/user/show-user/actions';

function* createOrganizationFlow(action: Action<unknown>) {
  if (createOrganizationStart.match(action)) {
    const { values, image } = action.payload;
    try {
      yield put(loading());
      yield call(getUploadTokensAndUploadAvatar, image);
      const { file_url } = selectTokens(yield select());
      const { data } = yield call(createOrganization, { ...values, logo: file_url });
      const { data: organizationInfo }: { data: OrganizationInfo } = data;
      yield put(success());
      yield put(setOrganizationInfo(organizationInfo));
      yield put(showUserStart());
    } catch (error) {
      const { message }: { message: string } = error?.response?.data || error;
      const statusCode: number = error?.response?.status;
      yield put(failure({ message, statusCode }));
    }
  }
}

export function* onGetUploadTokensAndCreateOrganizationStart() {
  yield takeLatest(createOrganizationStart, createOrganizationFlow);
}
