import * as React from 'react';
import { Card, Col } from 'react-bootstrap';
import { Candidate } from '../types';

export interface ICandidateCardProps {
  candidate: Candidate;
}

export default function CandidateCard(props: ICandidateCardProps) {
  const { candidate } = props;
  return (
    <Col lg={4}>
      <Card className="candidate-card">
        <div className="candidate-card__body">
          <div className="candidate-card__profilePic"></div>
          <div className="candidate-card__name">{candidate.applicant.name}</div>
          <div className="candidate-card__info">
            <p>{candidate.phone_number}</p> <p>at X company</p>
          </div>
          <div className="candidate-card__stage">
            <span>{candidate.applicant.email}</span>
          </div>
        </div>
      </Card>
    </Col>
  );
}
