import * as React from 'react';
import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from 'react-bootstrap';
import LeavePage from 'features/job/create-job/components/LeavePage';
import { ApplicationFormModalContext, IsDirtyContext } from 'features/job/create-job';
import { useAdjustButtonPosition, useOrganizationId } from 'hooks';
import { TQuestion } from '../questions/types';
import { updateApplicationFormStart } from './actions';
import { TApplicationForm } from '../types';
import { normalizedQuestions, normalizeQuestions } from '../questions';
import { createApplicationFormTemplateStart } from '../create-application-form-template';

export { default as updateApplicationFormReducer } from './slice';
export { default as onUpdateApplicationFormStart } from './saga';
export { updateApplicationFormStart } from './actions';

export interface IUpdateApplicationFormProps {
  disabled: boolean;
  values: TApplicationForm;
  questions: TQuestion[];
  setSubmitting: any;
  saveDefault: boolean;
}

export default function UpdateApplicationForm(props: IUpdateApplicationFormProps) {
  const { disabled, values, questions, setSubmitting, saveDefault } = props;
  const organizationId = useOrganizationId();
  const adjustPosition = useAdjustButtonPosition();
  const dispatch = useDispatch();
  const { isDirty, setDirty } = useContext(IsDirtyContext);

  const { showApplicationFormModal, setShowApplicationFormModal } = useContext(
    ApplicationFormModalContext,
  );

  const onCancel = () => {
    if (setShowApplicationFormModal) setShowApplicationFormModal(false);
  };

  // const onSave = async (submitForm: (() => Promise<void>) & (() => Promise<any>)) => {
  //   await submitForm();
  //   if (setDirty) setDirty(false);
  // };

  const onUpdateApplicationForm = () => {
    setSubmitting(true);

    setTimeout(() => {
      if (saveDefault && organizationId) {
        dispatch(
          createApplicationFormTemplateStart({
            applicationFormInfo: {
              save_as_default: 'true',
              ...values,
              application_form_details: normalizeQuestions(
                questions,
                values.application_form_details,
              ).map((detail) => ({ ...detail, id: undefined })),
            },
            organizationId,
          }),
        );
      }
      if (organizationId) {
        dispatch(
          updateApplicationFormStart({
            applicationFormInfo: {
              ...values,
              application_form_details: normalizedQuestions(
                questions,
                values.application_form_details,
              ),
            },
            organizationId,
          }),
        );
      }
      if (setDirty) setDirty(false);
      setSubmitting(false);
    }, 500);
  };

  return (
    <>
      {isDirty && showApplicationFormModal && (
        <LeavePage
          handleCancel={onCancel}
          handleSave={() => onUpdateApplicationForm()}
          show={true}
        />
      )}
      <Button
        className={`btn-light btn-job-primary btn-abs ${
          adjustPosition ? 'adjusted' : ''
        }`}
        type="button"
        disabled={disabled}
        onClick={() => onUpdateApplicationForm()}
      >
        Save Changes
      </Button>
    </>
  );
}
