import { call, all } from 'redux-saga/effects';

import { onShowUserSatrt } from './show-user';
import { onPersistUserSessionStart } from 'services/session/persist-user-session.saga';
import { onGetUploadTokensAndUpdateProfileInfoStart } from './update-profile-info';

// PROFILE SAGAS
export default function* onUserAction() {
  yield all([
    call(onShowUserSatrt),
    call(onGetUploadTokensAndUpdateProfileInfoStart),
    call(onPersistUserSessionStart),
  ]);
}
