import * as React from 'react';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { nanoid } from 'nanoid';
import {
  TQuestion,
  TQuestionWithOptions,
  TQuestionNoOptions,
  QuestionsAction,
} from './types';
import { setQuestions } from './actions';
import QuestionsList from './components/QuestionsList';
import { QuestionsDispatch } from '../show-application-form';
import { TApplicationFormDetail } from '../types';

export function normalizedQuestions(
  questions: TQuestion[],
  applicationFormDetails: TApplicationFormDetail[],
): TApplicationFormDetail[] {
  return addDeletedQuestions(questions, applicationFormDetails);
}

function addDeletedQuestions(
  questions: TQuestion[],
  applicationFormDetails: TApplicationFormDetail[],
): TApplicationFormDetail[] {
  const deletedQuestions = applicationFormDetails.filter(
    (detail) => !questions.find((question) => detail.id.toString() === question.id),
  );

  const normalizedQuestions = normalizeQuestions(questions, applicationFormDetails);
  return normalizedQuestions.concat(
    deletedQuestions.map((deletedQuestion) => ({ ...deletedQuestion, _destroy: 1 })),
  );
}

function isNewQuestionFunction(applicationFormDetails: TApplicationFormDetail[]) {
  return (question: TQuestion): boolean => {
    return !applicationFormDetails.find(
      (detail) => question.id.toString() === detail.id.toString(),
    );
  };
}

// NORMALIZE QUESTIONS TO BE OF TYPE APPLICATION_FORM_DETAILS
export const normalizeQuestions = (
  questions: TQuestion[],
  applicationFormDetails: TApplicationFormDetail[],
): TApplicationFormDetail[] => {
  const isNewQuestion = isNewQuestionFunction(applicationFormDetails);

  return (questions.map((question, index) => {
    if ('options' in question) {
      return {
        id: isNewQuestion(question) ? null : Number(question.id),
        position: index,
        title: question.headline,
        required: false,
        schema: {
          type: question.type,
          answers: question.options.map((option) => option.value),
        },
      };
    }
    return {
      id: isNewQuestion(question) ? null : Number(question.id),
      position: index,
      title: question.headline,
      required: false,
      schema: {
        type: question.type,
      },
    };
  }) as any[]) as TApplicationFormDetail[];
};

// NORMAIZE APPLICATION_FORM_DETAILS TO BE OF TYPE QUESTIONS
export const normalizeToQuestions = (
  applicationFormDetails: TApplicationFormDetail[],
): TQuestion[] | [] => {
  return applicationFormDetails.map((applicationFormDetail) => {
    if (applicationFormDetail.schema.answers !== undefined) {
      return {
        id: applicationFormDetail.id.toString(),
        headline: applicationFormDetail.title,
        type: applicationFormDetail.schema.type,
        options: applicationFormDetail.schema.answers.map((answer) => ({
          id: nanoid(),
          value: answer,
        })),
      } as TQuestionWithOptions;
    } else {
      return {
        id: applicationFormDetail.id.toString(),
        headline: applicationFormDetail.title,
        type: applicationFormDetail.schema.type,
      } as TQuestionNoOptions;
    }
  });
};

const onDragEnd = (
  result: DropResult,
  questions: TQuestion[],
  dispatch: React.Dispatch<QuestionsAction>,
) => {
  const { destination, source } = result;
  if (!destination) return;

  if (
    destination.droppableId === source.droppableId &&
    destination.index === source.index
  )
    return;

  const newQuestions = [...questions];
  const [removed] = newQuestions.splice(source.index, 1);
  newQuestions.splice(destination.index, 0, removed);
  dispatch(setQuestions(newQuestions));
};

export interface IQuestionsProps {
  questions: TQuestion[];
}

export default function Questions(props: IQuestionsProps) {
  const { questions } = props;
  const dispatch = React.useContext(QuestionsDispatch);
  return (
    <DragDropContext
      onDragEnd={(result) =>
        onDragEnd(result, questions, dispatch as React.Dispatch<QuestionsAction>)
      }
    >
      <QuestionsList questions={questions} />
    </DragDropContext>
  );
}
