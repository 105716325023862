import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Field, Form, ErrorMessage, FieldProps } from 'formik';
import { toast, ToastContainer } from 'react-toastify';
import * as Yup from 'yup';
import { FormGroup, Form as FormStrap, Button, Card, Spinner } from 'react-bootstrap';

import { useCleanup, useHasOrganization } from 'hooks';
import { OnCreateOrganizationSubmit } from 'features/authentication/types';
import UploadAvatar from 'features/UploadAvatar';
import { selectError, selectIsLoading, selectSuccess, resetToInitials } from './slice';
import { createOrganizationStart } from './actions';
import { toastConfig } from 'utils';
import NoOrg from 'assets/svg/images/NoOrg';
import TopNavigation from 'components/Navigation/TopNavigation';

export { default as createOrganizationReducer } from './slice';
export { onGetUploadTokensAndCreateOrganizationStart } from './saga';

export interface ICreateOrganizationProps {}

// FORM VALIDATION
const validationSchema = Yup.object({
  name: Yup.string()
    .min(3, 'Name is too short')
    .max(15, 'Must be 15 characters or less')
    .required("Name can't be blank"),
  summary: Yup.string()
    .min(3, 'Summary is too short')
    .max(1000, 'Must be less than 1000 chars')
    .required("Summary can't be blank"),
  slug: Yup.string()
    .min(3, 'Slug is too short')
    .matches(/^[a-zA-Z0-9]+[a-zA-Z0-9\-_]*[a-zA-Z0-9]+$/, 'slug is invalid')
    .required("Can't be blank"),
});

// eslint-disable-next-line no-unused-vars
export default function CreateOrganization(props: ICreateOrganizationProps) {
  const [image, setImage] = useState<File>();
  const [inputError, setInputError] = useState<string>('');
  const dispatch = useDispatch();
  const isLoading = useSelector(selectIsLoading);
  const success = useSelector(selectSuccess);
  const error = useSelector(selectError);
  const [showCreateOrgForm, setShowCreateOrgForm] = useState<boolean>(false);
  const hasOrganization = useHasOrganization();

  useCleanup(resetToInitials, error.message, success);

  const onSubmit: OnCreateOrganizationSubmit = (values, { resetForm, setSubmitting }) => {
    if (image) {
      setSubmitting(false);
      resetForm();
      const valuesAndImage = {
        values: { ...values },
        image: { file: image, expires_in: 120 },
      };
      dispatch(createOrganizationStart(valuesAndImage));
    } else {
      setInputError('please Insert a photo');
    }
  };
  useEffect(() => {
    if (error.message) {
      toast.error(error.message, toastConfig);
    }
  }, [error]);
  if (isLoading) return <Spinner animation="border" />;

  return (
    <div>
      <TopNavigation />
      <div className="dashboard__main-content">
        {!showCreateOrgForm && !hasOrganization ? (
          <div className="dashboard__zero-state">
            <NoOrg />
            <h3>Oops! You don't have an organization yet</h3>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
              tempor incididunt ut labore et dolore magna aliqua.
            </p>
            <Button
              style={{ lineHeight: '2' }}
              className="btn-lg hero__btn"
              onClick={() => setShowCreateOrgForm(true)}
            >
              Create Now
            </Button>
          </div>
        ) : (
          <div
            style={{ transform: 'translate(50%, 20%)' }}
            className="dashboard__zero-state"
          >
            <Card className="hero__content auth">
              <Card.Header>Create Organization</Card.Header>
              <Card.Body>
                <Formik
                  initialValues={{
                    name: '',
                    summary: '',
                    slug: '',
                  }}
                  validationSchema={validationSchema}
                  onSubmit={onSubmit}
                >
                  <Form style={{ textAlign: 'initial' }}>
                    <FormGroup>
                      <FormStrap.Label htmlFor="name">Name</FormStrap.Label>
                      <Field as={FormStrap.Control} name="name" type="text" />
                      <ErrorMessage component="div" className="auth__error" name="name" />
                    </FormGroup>

                    <FormGroup>
                      <FormStrap.Label htmlFor="summary">summary</FormStrap.Label>
                      <Field
                        name="summary"
                        placeholder="Summary"
                        component={({ field }: FieldProps) => (
                          <FormStrap.Control {...field} as="textarea" />
                        )}
                      />
                      <ErrorMessage
                        component="div"
                        className="auth__error"
                        name="summary"
                      />
                    </FormGroup>

                    <FormGroup>
                      <FormStrap.Label htmlFor="slug">slug</FormStrap.Label>
                      <Field as={FormStrap.Control} name="slug" type="text" />
                      <ErrorMessage component="div" className="auth__error" name="slug" />
                    </FormGroup>
                    <FormGroup>
                      <FormStrap.Label htmlFor="logo">Logo</FormStrap.Label>
                      <UploadAvatar imageState={{ image, setImage }} />
                      <div>{inputError || null}</div>
                    </FormGroup>
                    <Button className="auth__btn" variant="primary" type="submit">
                      Submit
                    </Button>
                  </Form>
                </Formik>
              </Card.Body>
            </Card>
            {error.message && <ToastContainer />}
          </div>
        )}
      </div>
    </div>
  );
}
